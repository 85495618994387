import React, { KeyboardEvent, useRef, useState } from "react";

import moment from "moment";
import { useQuery } from "react-query";

import {
  FieldDateRange,
  FieldString,
  FilterDrawer,
  Label,
} from "@alpacahq/alpaca-component-library";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { getCorporateActions, ListCorporateActionsParams } from "../../api/api";
import Tab from "../../components/general/Tab";
import Header from "../../components/layout/Header";
import { EventType, getAmplitude } from "../../globals/amplitude";
import { formatDateRange, getDateOrUndefined } from "../../globals/utils";
import { CORPORATE_ACTION_TYPES } from "./constants";
import { consolidateData } from "./helper";
import CorporateActionTable from "./table";
import { CorporateAction } from "./types";

export const DEFAULT_DATE_RANGE_IN_DAYS = 7;

export const DEFAULT_FILTERS: ListCorporateActionsParams = {
  process_date_until: moment().format("YYYY-MM-DD"),
  process_date_since: moment()
    .subtract(DEFAULT_DATE_RANGE_IN_DAYS, "days")
    .format("YYYY-MM-DD"),
  symbols: [],
};

const CorporateActions = (): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedTab, setSelectedTab] = useState<string>("stockDividend");
  const [filters, setFilters] = useState<ListCorporateActionsParams>(
    DEFAULT_FILTERS
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useQuery(["corporate-actions", filters], () =>
    getCorporateActions(filters)
  );

  const onSearchBarKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchValue: string =
        event?.currentTarget?.value?.toUpperCase() ?? "";

      setFilters((filters) => ({
        ...filters,
        symbols: searchValue !== "" ? [searchValue] : [],
      }));

      searchValue &&
        getAmplitude().track({
          event_type: EventType.CA_SYMBOL_SEARCH,
          event_properties: {
            symbol: searchValue,
            ca_type: selectedTab,
          },
        });
    }
  };

  const onApplyFilter = (values: Record<string, string | undefined>) => {
    onClose();
    setFilters((filters) => ({
      ...filters,
      process_date_since: values.process_date_since,
      process_date_until: values.process_date_until,
      symbols: values.symbols?.split(",").map((symbol) => symbol.trim()),
    }));

    if (values.process_date_since || values.process_date_until) {
      getAmplitude().track({
        event_type: EventType.CA_DATE_FILTER,
        event_properties: {
          process_date_since: values.process_date_since,
          process_date_until: values.process_date_until,
        },
      });
    }
  };

  const onClearFilter = () => {
    onClose();
    setFilters(DEFAULT_FILTERS);
  };

  const onTabSelect = (index: number) => {
    setSelectedTab(CORPORATE_ACTION_TYPES[index].type);

    getAmplitude().track({
      event_type: EventType.CA_TYPE_CLICKED,
      event_properties: {
        ca_type: CORPORATE_ACTION_TYPES[index].type,
      },
    });
  };

  const formatTabCount = (tabData?: CorporateAction[]) => {
    return `(${tabData?.length || 0})`;
  };

  const entries = consolidateData(data?.cas || []);

  return (
    <>
      <Header title="Corporate Actions" />
      <Text marginBottom="2rem">
        Learn more about Symbol and Venue Changes{" "}
        <Link
          isExternal
          href="https://www.otcmarkets.com/market-activity/corporate-actions"
        >
          here
        </Link>
        .
      </Text>
      <Flex>
        <InputGroup maxWidth="250px" mr="0.5rem">
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            ref={inputRef}
            onKeyPress={onSearchBarKeyPress}
            placeholder="Search by symbol"
            type="tel"
            variant="filled"
          />
        </InputGroup>
        <Button
          onClick={onOpen}
          rightIcon={<ChevronDownIcon />}
          variant="filled"
        >
          {formatDateRange(
            filters.process_date_since,
            filters.process_date_until
          )}
        </Button>
      </Flex>

      <FilterDrawer
        isOpen={isOpen}
        onApply={onApplyFilter}
        onClear={onClearFilter}
        onClose={onClose}
      >
        <Label value="Symbol" />
        <FieldString
          name="symbols"
          defaultValue={filters.symbols}
          placeholder="SPY"
        />

        <Label value="Process Date" mt="1rem" />
        <FieldDateRange
          dateFormat="yyyy-MM-dd"
          defaultValue={[
            getDateOrUndefined(filters.process_date_since),
            getDateOrUndefined(filters.process_date_until),
          ]}
          name={["process_date_since", "process_date_until"]}
          placeholder="YYYY-MM-DD"
        />
      </FilterDrawer>

      <div className="overflow-x-auto">
        <Tabs onChange={onTabSelect} mt="3rem">
          <TabList>
            {CORPORATE_ACTION_TYPES.map((item) => (
              <Tab
                key={item.type}
                text={`${item.label} ${formatTabCount(entries[item.type])}`}
                isSelected={item.type === selectedTab}
              />
            ))}
          </TabList>

          <TabPanels>
            {CORPORATE_ACTION_TYPES.map(({ type }) => (
              <TabPanel key={type}>
                <CorporateActionTable
                  data={entries[selectedTab] ?? []}
                  isLoading={isLoading}
                  type={type}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </>
  );
};

export default CorporateActions;
