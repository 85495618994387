import React from "react";
import styled from "styled-components";

import { NavLink, useLocation } from "react-router-dom";
import { EventType, getAmplitude } from "../../globals/amplitude";

import {
  Box,
  Text,
  Icon,
  Tooltip,
  useColorModeValue,
  Collapse,
} from "@chakra-ui/react";
import colors from "../theme/colors";

const Container = styled(Box)`
  padding: 5px;
  display: flex;
  margin: 8px 0px;
  padding: 8px;
  font-size: 16px;
  border-radius: 8px;
  opacity: ${(props) => (props.$active ? 100 : 70)}%;
  font-weight: ${(props) => (props.$active ? 600 : 400)};

  ${() => {
    const color = useColorModeValue("black", "white");
    return `stroke: ${color}; fill: ${color};`;
  }}

  &:hover {
    cursor: pointer;
    background-color: ${colors.yellow100};
  }
`;

interface SubmenItem {
  name: string;
  path: string;
}

export interface MenuItem {
  name: string;
  path: string;
  icon: React.ElementType;
  submenu?: SubmenItem[];
  redirectPath?: string;
  fontSize?: string;
}

interface Props extends MenuItem {
  expanded: boolean;
}

const MenuElement = (props: Props): React.ReactElement => {
  const location = useLocation();
  const p = location.pathname;

  // wrap in tooltip if menu collapsed
  const wrapper = (children: React.ReactElement) => {
    if (props.expanded) {
      return children;
    }

    return (
      <Tooltip label={props.name} placement="right">
        {children}
      </Tooltip>
    );
  };

  const handleClick = (path: string) => {
    switch (path) {
      case "/dev/quick-start":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_PAGE_VISITED,
        });
        break;
    }
  };

  const submenu = (
    <Collapse in={p.includes(props.path) && props.expanded}>
      {props.submenu?.map((item) => (
        <NavLink
          key={item.name}
          to={item.path}
          onClick={() => handleClick(item.path)}
        >
          <Container $active={p === item.path}>
            <Box flex="1" />
            <Text flex="4">{item.name}</Text>
          </Container>
        </NavLink>
      ))}
    </Collapse>
  );

  const menu = wrapper(
    <NavLink to={props.redirectPath || props.path}>
      <Container $active={p === props.path}>
        <Icon
          as={props.icon}
          fontSize={props?.fontSize ? props.fontSize : "2xl"}
          flex="1"
        />
        {props.expanded && <Text flex="4">{props.name}</Text>}
      </Container>
    </NavLink>
  );

  return (
    <>
      {menu}
      {props.submenu && submenu}
    </>
  );
};

export default MenuElement;
