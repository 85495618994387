import moment from "moment";
import Tab from "../components/general/Tab";
import UUID from "../components/general/UUID";
import Header from "../components/layout/Header";
import AddUser from "../components/user/AddUser";
import ExportToCsv from "../components/tables/ExportButton";

import React, { useEffect, useMemo, useState } from "react";

import {
  AccountTableRow,
  UseQueryOptionsTradingAccount,
  UseQueryOptionsUnknown,
} from "../globals/types";

import {
  capitalize,
  fmtLCTMoney,
  mapAccountsToRowsInPlace,
  getAccountStatusColor,
} from "../globals/utils";

import {
  FilterDrawer,
  FilterDateSelect,
  FilterSelect,
  FilterString,
  FilterButton,
  RangeType,
  dateRangeToPill,
} from "../components/filter";

import {
  Tabs,
  TabList,
  TabPanels,
  Flex,
  Spacer,
  useToast,
  Input,
  Text,
  Box,
  Link,
  Tooltip,
} from "@chakra-ui/react";

import { Column, Cell } from "react-table";
import { useNavigate } from "react-router-dom";
import { useQueries, useQuery } from "react-query";
import { Account, AccountsParams } from "../api/types";
import { getAccounts, getTradingAccount } from "../api/api";
import { Table } from "@alpacahq/alpaca-component-library";
import { EventType, getAmplitude } from "../globals/amplitude";

const inactiveAccounts = [
  "SUBMITTED",
  "ACTION_REQUIRED",
  "APPROVAL_PENDING",
  "APPROVED",
  "DISABLED",
  "ACCOUNT_CLOSED",
  "REJECTED",
];

const statusOptions = ["ALL", "ACTIVE", "ACCOUNT_UPDATED", ...inactiveAccounts];

const Users = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [addUserOpen, setAddUserOpen] = useState(false);

  // Filters
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [accountIDFilter, setAccountIDFilter] = useState("");
  const [accountNameFilter, setAccountNameFilter] = useState("");
  const [accountNumberFilter, setAccountNumberFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [appliedFilters, setAppliedFilters] = useState<AccountsParams>({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);

  let rows: Record<string, AccountTableRow> = {};

  // Fetch brokerage accounts
  const brokerAccountsResponse = useQuery<Account[], unknown>(
    ["accounts", appliedFilters],
    () => getAccounts(appliedFilters),
    {
      // We use select to repopulate the rows in-case the component has re-rendered
      select: (data) => {
        // If rows have changed
        if (Object.keys(rows).length !== data.length) {
          mapAccountsToRowsInPlace(data, rows);
        }

        return data;
      },
      onSuccess: (data) => {
        if (!Object.keys(appliedFilters).length) {
          setAllAccounts(data);
        }
      },
    }
  );

  // return a filtered list of accounts
  const brokerageAccounts = useMemo(() => {
    const data = brokerAccountsResponse.data ?? [];

    // filter by status if applicable
    switch (selectedTab) {
      case 1:
        return data.filter((account) => account.status === "ACTIVE");
      case 2:
        return data.filter((account) =>
          inactiveAccounts.includes(account.status)
        );
    }

    return data;
  }, [selectedTab, brokerAccountsResponse.data]);

  // Fetch trading accounts
  // NOTE: Providing type args doesn't seem to work with useQueries
  const tradingAccountsResponse = useQueries(
    brokerageAccounts
      ?.filter(
        (_, index) =>
          // If index is greater than or equal to page start index
          index >= (page - 1) * pageSize &&
          // If index is less than page end index
          index < page * pageSize
      )
      .map<UseQueryOptionsTradingAccount>((account) => ({
        queryKey: ["trading-account", account.id],
        queryFn: () => getTradingAccount(account.id),
        // We use select to reapply the equities in-case the component has re-rendered
        select: (data) => {
          // Update equity value for account
          if (data.id in rows) {
            rows[data.id].equity = fmtLCTMoney(data.equity, data.currency);
          }

          return data;
        },
      })) as UseQueryOptionsUnknown
  );

  const tradingAccountResponseErrored = tradingAccountsResponse.some(
    (r) => r.isError
  );

  useEffect(() => {
    if (!applyFilter) {
      return;
    }

    const params: AccountsParams = {
      status: statusFilter,
      id: accountIDFilter,
      account_number: accountNumberFilter,
      query: [emailFilter, accountNameFilter]
        .filter((q) => q.trim() !== "")
        .join(","),
    };

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      params.created_after = selectedDates[0].startOf("day").toISOString();
      params.created_before = selectedDates[1].endOf("day").toISOString();
    }

    setAppliedFilters(params);

    // Purge rows now that filters have been applied
    rows = {};
  }, [
    accountIDFilter,
    accountNameFilter,
    accountNumberFilter,
    applyFilter,
    emailFilter,
    selectedDates,
    statusFilter,
  ]);

  useEffect(() => {
    if (brokerAccountsResponse.isError) {
      toast({
        title: "An error occurred fetching brokerage accounts",
        description: (brokerAccountsResponse.error as Error).message,
        status: "error",
      });
    }

    if (tradingAccountResponseErrored) {
      toast({
        title: "An error occurred fetching trading accounts",
        description: (tradingAccountsResponse.find((r) => r.isError)
          ?.error as Error).message,
        status: "error",
      });
    }
  }, [brokerAccountsResponse.isError, tradingAccountResponseErrored]);

  useEffect(() => {
    getAmplitude().track({
      event_type: EventType.ACCOUNTS_PAGE_VISITED,
    });
  }, []);

  const columns: Column<Account>[] = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    {
      Header: "Account ID",
      accessor: ({ id }) => (
        <UUID onClick={() => navigate(`/accounts/${id}`)} value={id} />
      ),
    },
    {
      Header: "Equity",
      accessor: ({ last_equity, currency }) =>
        fmtLCTMoney(last_equity, currency),
    },
    {
      Header: "Address",
      Cell: ({ row: { original: account } }: Cell<Account>) => {
        const address = [
          ...(account?.contact?.street_address || []),
          account?.contact?.state,
          account?.contact?.postal_code,
          account?.identity?.country_of_tax_residence,
        ]
          .filter((field) => !!field)
          .join(", ");

        return (
          <Tooltip label={address} placement="top">
            <Text isTruncated maxWidth="300px">
              {address}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      Header: "Status",
      accessor: ({ status }) => (
        <Text color={getAccountStatusColor(status)}>{capitalize(status)}</Text>
      ),
    },
    {
      Header: "Crypto Status",
      accessor: ({ crypto_status }) => (
        <Text color={getAccountStatusColor(crypto_status)}>
          {capitalize(crypto_status || "-")}
        </Text>
      ),
    },
    {
      Header: "Cash Interest Status",
      accessor: ({ cash_interest }) => (
        <Text color={getAccountStatusColor(cash_interest?.USD?.status)}>
          {capitalize(cash_interest?.USD?.status || "-")}
        </Text>
      ),
    },
    {
      Header: "APR Tier",
      accessor: ({ cash_interest }) => (
        <Text>{capitalize(cash_interest?.USD?.apr_tier || "-")}</Text>
      ),
    },
    {
      Header: "Created At",
      accessor: ({ created_at }) => {
        const date = moment(created_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("HH:mm a")}</Text>
          </Flex>
        );
      },
    },
  ];

  const filterPills = {
    status: capitalize(statusFilter),
    dates: dateRangeToPill(selectedDates),
    accountID: accountIDFilter,
    accountName: accountNameFilter,
    accountNumber: accountNumberFilter,
    email: emailFilter,
  };

  const removeFilter = (filterID: string) => {
    if (filterID === "status") {
      setStatusFilter("All");
    }
    if (filterID === "dates") {
      setSelectedDates([null, null]);
    }
    if (filterID === "accountID") {
      setAccountIDFilter("");
    }
    if (filterID === "accountName") {
      setAccountNameFilter("");
    }
    if (filterID === "accountNumber") {
      setAccountNumberFilter("");
    }
    if (filterID === "email") {
      setEmailFilter("");
    }
  };

  // todo: expose props for callbacks in the table component
  const onFetchData = ({ pageIndex, pageSize }) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
  };

  const tabSwitchAmplitudeEvent = (idx: number) => {
    if (idx > 0) {
      getAmplitude().track({
        event_type:
          idx === 1
            ? EventType.ACCOUNTS_PAGE_ACTIVE_USERS
            : EventType.ACCOUNTS_PAGE_INACTIVE_USERS,
      });
    }
  };

  const filterAccountsAmplitudeEvent = () => {
    if (statusFilter !== "All") {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_STATUS_FILTER,
      });
    }

    if (selectedDates !== [null, null]) {
      getAmplitude().track({
        event_type: EventType.ACCOUNTS_PAGE_CREATED_AT_FILTER,
      });
    }
  };

  return (
    <Box>
      <Header title="Accounts" />
      <Text>
        Learn more about our account opening process{" "}
        <Link
          href="https://alpaca.markets/docs/broker/integration/account-opening/"
          isExternal
        >
          here
        </Link>
      </Text>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => {
          setFilterOpen(false);
          getAmplitude().track({
            event_type: EventType.ACCOUNTS_PAGE_CANCEL_FILTER,
          });
        }}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
          filterAccountsAmplitudeEvent();
        }}
      >
        <FilterSelect
          header="Status"
          options={statusOptions}
          onSelect={setStatusFilter}
          selected={statusFilter}
        />
        <FilterDateSelect
          header="Created At"
          onDateSelect={(st, ed) => setSelectedDates([st, ed])}
        />
        <FilterString header="Account ID" onChange={setAccountIDFilter} />
        <FilterString
          header="Account Name"
          onChange={(value) => setAccountNameFilter(value.trim())}
        />
        <FilterString
          header="Account Number"
          onChange={setAccountNumberFilter}
        />
        <FilterString header="Email Address" onChange={setEmailFilter} />
      </FilterDrawer>
      <AddUser isOpen={addUserOpen} onClose={() => setAddUserOpen(false)}>
        <Input variant="filled" type="email" placeholder="Email" />
      </AddUser>
      <Tabs
        onChange={(idx) => {
          setSelectedTab(idx);
          tabSwitchAmplitudeEvent(idx);
        }}
        mt="3rem"
      >
        <Flex>
          <TabList>
            <Tab text="All Users" isSelected={selectedTab === 0} />
            <Tab text="Active Users" isSelected={selectedTab === 1} />
            <Tab text="Inactive Users" isSelected={selectedTab === 2} />
          </TabList>
          <Spacer />
          <ExportToCsv
            data={brokerageAccounts}
            allData={allAccounts}
            amplitudeEvent={EventType.ACCOUNTS_PAGE_EXPORT}
          />
          <FilterButton
            filterPills={applyFilter ? filterPills : {}}
            openFilter={() => {
              setFilterOpen(true);
              setApplyFilter(false);
              getAmplitude().track({
                event_type: EventType.ACCOUNTS_PAGE_FILTER,
              });
            }}
            removeFilter={(filterID) => removeFilter(filterID)}
          />
        </Flex>
        <TabPanels>
          <Table
            columns={columns}
            data={brokerageAccounts}
            onFetchData={onFetchData}
            defaultPerPage={pageSize}
            pagination
            paginationPosition="top"
          />
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Users;
