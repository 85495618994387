import LeftMenu from "./LeftMenu";
import moment from "moment";
import styled from "styled-components";
import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Flex, useColorModeValue } from "@chakra-ui/react";

import { AppContext } from "../../globals/appcontext";
import { Scrollbox } from "../general/ScrollBox";
import { Loader } from "../general/Loader";
import { setAuthHeader } from "../../api/api";
import { locationUpdate } from "../../globals/analytics";

const Content = styled(Scrollbox)`
  padding: 1rem;
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
  position: relative;
`;

interface LayoutProps {
  children: React.ReactElement;
}

const Layout = (props: LayoutProps): React.ReactElement => {
  const appContext = useContext(AppContext);
  const colorMode = useColorModeValue("light", "dark");
  const location = useLocation();

  // note:
  // as of 2022-08-15 launch guide now also contains onboarding and users must complete the full form
  // this date check is to allow legacy users to navigate away
  const onboardingV1 = moment().set({ year: 2022, month: 7, date: 15 });
  const redirectLaunchGuide =
    moment.utc(appContext.self?.created_at || moment()).isAfter(onboardingV1) &&
    !appContext.correspondent.Name &&
    location.pathname !== "/launch-guide" &&
    location.pathname !== "/go-live";

  useEffect(() => {
    // Load the current user on mount to ensure they are authenticated.
    setAuthHeader().then(() => {
      appContext.setIsAuthenticated(true);
    });
  }, []);

  useEffect(() => locationUpdate(location), [location]);

  if (appContext.isLoading) {
    return <Loader />;
  }

  if (!appContext.isLoading && !appContext.authenticated) {
    return <Navigate to="/login" />;
  }

  if (redirectLaunchGuide) {
    return <Navigate to="/launch-guide" />;
  }

  return (
    <Flex>
      <LeftMenu />
      <Content colormode={colorMode}>{props.children}</Content>
    </Flex>
  );
};

export default Layout;
