import React from "react";
import {
  useColorModeValue,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Spacer,
  Button,
} from "@chakra-ui/react";
import numeral from "numeral";
import { VscListFilter } from "react-icons/vsc";
import { Generic } from "../../api/types";
import { RangeType, NumRange } from ".";

interface FilterButtonProps {
  filterPills: Generic;
  openFilter: () => void;
  removeFilter: (filter: string) => void;
}

const FilterButton = (props: FilterButtonProps): React.ReactElement => {
  const textColor = useColorModeValue("black", "white");

  return (
    <Flex m="2rem 1rem">
      {Object.entries(props.filterPills)
        .filter((f) => f[1] && f[1].toLowerCase() !== "all")
        .map((filter) => (
          <Tag key={filter[0]} borderRadius="full" m="4px 0.5rem">
            <TagLabel>{filter[1]}</TagLabel>
            <TagCloseButton onClick={() => props.removeFilter(filter[0])} />
          </Tag>
        ))}
      <Spacer />
      <Button
        leftIcon={<VscListFilter />}
        variant="ghost"
        color={textColor}
        onClick={() => props.openFilter()}
      >
        Filter
      </Button>
    </Flex>
  );
};

export const rangeToPill = (
  field: string,
  range: NumRange,
  money = false
): string => {
  const format = money ? "$0,0" : "0";
  const left = numeral(range[0]).format(format);
  const right = numeral(range[1]).format(format);

  if (range[0] && range[1]) {
    return `${field} ${left} to ${right}`;
  } else if (range[0]) {
    return `${field} > ${left}`;
  } else if (range[1]) {
    return `${field} < ${right}`;
  }

  return "";
};

export const dateRangeToPill = (dates: RangeType): string => {
  if (!dates[0] || !dates[1]) return "";
  const fmt = (d: moment.Moment) => d.format("YYYY-MM-DD");
  return fmt(dates[0]) + " to " + fmt(dates[1]);
};

export default FilterButton;
