import Header from "../../components/layout/Header";

import Tab from "../../components/general/Tab";
import Members from "./members";
import Overview from "./Overview";
import SandboxConfigurations from "./sandboxConfig";

import React, { useContext, useMemo, useState } from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  Box,
} from "@chakra-ui/react";

import { AppContext } from "../../globals/appcontext";
import IPAllowlist from "./ipAllowlist";

const TeamSettingsPage = (): React.ReactElement => {
  const appContext = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Overview",
      content: <Overview />,
    },
    {
      label: "Team",
      content: <Members />,
    },
    {
      label: "Sandbox Configurations",
      content: <SandboxConfigurations />,
      sandboxOnly: true,
    },
    {
      label: "IP Allowlist",
      content: (
        <div>
          <IPAllowlist ingress="brokerapi" title="Broker API" />
          <IPAllowlist ingress="brokerdash" title="Broker Dashboard" />
        </div>
      ),
    },
  ];

  const filteredTabs = useMemo(
    () =>
      tabs.filter(
        (tab) =>
          !tab.sandboxOnly ||
          (appContext.correspondent.Env === "sandbox" && tab.sandboxOnly)
      ),
    [appContext.correspondent]
  );

  return (
    <Box>
      <Header title="Team Settings" />
      <Tabs onChange={(index) => setActiveTab(index)}>
        <Flex>
          <TabList>
            {filteredTabs.map(({ label }, index) => (
              <Tab key={label} text={label} isSelected={activeTab === index} />
            ))}
          </TabList>
          <Spacer />
        </Flex>
        <TabPanels>
          {filteredTabs.map(({ content, label }) => (
            <TabPanel key={label}>{content}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TeamSettingsPage;
