import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { BusinessType } from "../../api/types";
import { GenerateAPIkeys } from "../../pages/Devs";

export type TaskEntry = {
  title: string | React.ReactElement;
  subTitle?: string;
};

export const ExternalLink = styled.a`
  color: #f6d000;
  text-decoration: underline;
  &:hover {
    color: #ffdf33;
  }
`;

export const SANDBOX_TASKS: TaskEntry[] = [
  {
    title: (
      <>
        <Text>
          This key is important for authentication and security purposes.
          You&apos;ll need API keys to operate with the system.
        </Text>
        <GenerateAPIkeys />
      </>
    ),
  },
  {
    title: (
      <Text>
        We highly recommend that you decide early on whether you or Alpaca will
        be responsible for conducting KYC for your customers.{" "}
        <ExternalLink
          href="https://s3.amazonaws.com/files.alpaca.markets/disclosures/KYC+info.pdf"
          target="_blank"
        >
          Read more
        </ExternalLink>{" "}
        about our KYC guidelines.
      </Text>
    ),
  },
  {
    title: (
      <Text>
        <Text>
          For building your customer onboarding flow please be sure to look at
          the requirements that must be followed for{" "}
          <ExternalLink
            href="https://s3.amazonaws.com/files.alpaca.markets/disclosures/International+Onboarding+Flow+Requirements+03.01.2022+-+International.pdf"
            target="_blank"
          >
            international users
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink
            href="https://s3.amazonaws.com/files.alpaca.markets/disclosures/US+Onboarding+Flow+Requirements+03.01.2022+(Original)++-+US+onboarding.pdf"
            target="_blank"
          >
            domestic users
          </ExternalLink>
          .
        </Text>
      </Text>
    ),
  },
  {
    title: (
      <Text>
        To test our APIs, get started{" "}
        <Link to="/dev/quick-start">
          <Text d="inline" color="brand.500" textDecoration="underline">
            here
          </Text>
        </Link>
        .
      </Text>
    ),
  },
];

// Limited Live Section Tasks

export const LimitedLiveTasks: TaskEntry[] = [
  {
    title: (
      <Text>
        Complete onboarding requirements and Limited Live{" "}
        <ExternalLink
          href="https://s3.amazonaws.com/files.alpaca.markets/disclosures/Limited+Live+Tech+Requirements.pdf"
          target="_blank"
        >
          tech requirements
        </ExternalLink>
      </Text>
    ),
  },
  {
    title: (
      <Text>
        Complete this{" "}
        <Link to="/go-live">
          <Text d="inline" color="brand.500" textDecoration="underline">
            form
          </Text>
        </Link>{" "}
        with information on your legal entity and the primary control person
      </Text>
    ),
  },
  { title: "Build a compliant onboarding flow for your customers" },
  {
    title:
      "Create a pre-funded Sandbox account for Alpaca's tech consultants to test",
  },
  {
    title: (
      <Text>
        Email{" "}
        <ExternalLink href="mailto:broker-review@alpaca.markets">
          broker-review@alpaca.markets
        </ExternalLink>{" "}
        with the following:
        <UnorderedList>
          <ListItem>
            Your compliant onboarding flow (pdf of wireframes or screen
            recording in English)
          </ListItem>
          <ListItem>
            1 or 2 account IDs from your sandbox account that have all required
            data fields that are listed in the onboarding flow requirements
          </ListItem>
        </UnorderedList>
      </Text>
    ),
  },
];

// Live Sections Tasks

const BaseTasks: TaskEntry[] = [
  {
    title: "W-9 tax form completed and signed",
    subTitle:
      "This and all following documents should be sent in for the entity that signed the carrying agreement with Alpaca.",
  },
  { title: "Articles of incorporation / Certificate of formation" },
  {
    title: "Entity registration documents",
    subTitle:
      "Proof that your company is actively registered in your jurisdiction and that you are in good standing.",
  },
  {
    title: "Bylaws / Operating Agreement / Constitution",
    subTitle:
      "This should contain the governing rules by which your company operates. It may outline the business' financial and functional decisions including rules, regulations and provisions. It may also be called a constitution.",
  },
  {
    title: "Client Access Form - completed and signed",
    subTitle:
      "Any individuals on your team that will have access to the system should be listed on this form",
  },
  {
    title: "Ultimate Beneficial Owner (UBO) Form - completed and signed",
    subTitle:
      "Any individual with a 10% or greater stake in your company should be listed on this form.",
  },
  {
    title: "Visual representation of the ownership structure",
    subTitle:
      "This should display any parent companies, subsidiaries, and funds that are owned by or own the legal entity that signed the Carrying Agreement. All associated companies of a group of companies should be displayed.",
  },
  {
    title: "For each individual listed on the UBO Form and Client Access Form",
    subTitle:
      "You will need to submit a completed and signed CIP Form, front and back images of a government-issued photo ID and proof of address if the individual resides outside of the USA",
  },
  { title: "Privacy Policy" },
  { title: "Information Security Policy" },
  { title: "Risk Management Policy / Procedures" },
  { title: "Code of Ethics" },
  { title: "Form CRS" },
  { title: "Advisory service agreement / client agreement" },
  { title: "Form ADV and ADV Brochure" },
  { title: "AML Program (if you own KYC)" },
  { title: "Audit of your AML Program by a third party (if you own KYC)" },
  { title: "Written Supervisory Procedures" },
  { title: "Business Continuity Plan" },
];

export const CreateTaskList = (partnerType: string): TaskEntry[] => {
  const baseCopy: TaskEntry[] = JSON.parse(JSON.stringify(BaseTasks));
  const filterTasks = (toRemove: string[]) =>
    baseCopy.filter((t) => !toRemove.includes(t.title));

  switch (partnerType) {
    case "RIA (U.S)":
      return baseCopy;
    case "Investment Advisor (non - U.S)":
      const InvestmentAdvisorTasks = filterTasks([
        "Form ADV and ADV Brochure",
        "Form CRS",
      ]);
      InvestmentAdvisorTasks[0].title =
        "W-8 BEN E tax form completed and signed";
      return InvestmentAdvisorTasks;
    case "Broker-dealer":
      return baseCopy;
    case "Technology Partner":
      const TechnologyPartnerTasks = filterTasks([
        "Code of Ethics",
        "Form CRS",
        "Advisory service agreement / client agreement",
        "Form ADV and ADV Brochure",
        "AML Program (if you own KYC)",
        "Audit of your AML Program by a third party (if you own KYC)",
        "Written Supervisory Procedures",
      ]);
      TechnologyPartnerTasks[0].title =
        "W9 or W-8 BEN E tax form completed and signed";
      return TechnologyPartnerTasks;
    default:
      return [];
  }
};

export const businessTypes = [
  {
    value: BusinessType.BrokerDealer,
    label: "Broker-Dealer",
    description:
      "We are a registered broker-dealer looking to offer our customers access to the US equities market.",
  },
  {
    value: BusinessType.NeoBank,
    label: "Challenger Bank/Neobank",
    description:
      "We are a challenger bank/neobank that is adding a trading/investing component in the US equities market.",
  },
  {
    value: BusinessType.EstablishedFintech,
    label: "Established Fintech",
    description:
      "We are an established fintech company providing financial services to your customers, such as payroll or payments, and are looking to build a trading/investing component to augment our existing product/service.",
  },
  {
    value: BusinessType.RegisteredInvestmentAdvisor,
    label: "Registered Investment Advisor",
    description:
      "We are a registered investment advisor looking to build a mobile or web-based offering for our customers.  We have a fiduciary responsibility to your customers and provide investment advice to our customers and/or manage our customers’ investment portfolios.",
  },
  {
    value: BusinessType.SaaS,
    label: "SaaS",
    description:
      "We are not a fintech company but we are looking to bring in investment services to our users. We aren’t registered with any financial regulatory body like the SEC, SIPC, or FINRA (at least not yet).",
  },
  {
    value: BusinessType.Other,
    label: "I'm not sure/for fun",
    description:
      "Select this option if none of the above options best describes you, or if you are not sure.",
  },
];
