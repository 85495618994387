import imgKYC from "../../assets/images/imgKYC.svg";
import FileUpload from "../../components/general/FileUpload";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { GoLiveFormLabel, GoLiveFormError } from "./GoLive";
import {
  primaryControlPersonCopy,
  formHalfWidth,
  formThirdWidth,
  dateRegex,
  emailRegex,
  phoneNumberRegex,
  controlPersonIDCopy,
  getBase64,
} from "./constants";
import {
  CreateControlPersonRequest,
  GetEntityAndControlPersonResponse,
} from "../../api/types";
import { GoLiveSession } from "./goLiveSession";
import { alpacaAuthorizeCopy } from "./constants";

import {
  Box,
  Text,
  HStack,
  VStack,
  Image,
  FormControl,
  Input,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";

interface ControlPersonCheckProps {
  onSubmit: (values: CreateControlPersonRequest) => void;
  session: GoLiveSession;
  isReadOnly: boolean;
  entityDetails?: GetEntityAndControlPersonResponse;
}

export interface FileList {
  name: string;
  base64: string;
}

const ControlPersonCheck = ({
  session,
  onSubmit,
  isReadOnly,
  entityDetails,
}: ControlPersonCheckProps): React.ReactElement => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileList[]>([]);
  const onDrop = (acceptedFiles: File[]) =>
    getBase64(acceptedFiles[0])
      .then((res) => {
        setFileList((prev) => [
          ...prev,
          { name: acceptedFiles[0].name, base64: String(res) },
        ]);
        // TODO: handle upload photos
        // props.handlePhotoID(fileList);
      })
      .catch((e) => console.error(e));

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateControlPersonRequest>({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entityDetails?.control_person });
  }, [entityDetails]);

  return (
    <Box w="100%" mb="44px">
      <HStack>
        <VStack alignItems="start" w="453px">
          <Text fontSize="20px">Primary Control Person Verification Check</Text>
          <Text fontSize="14px" color="grey">
            {primaryControlPersonCopy}
          </Text>
        </VStack>
        <Box ml="34px !important">
          <Image src={imgKYC} alt="empty table contents" />
        </Box>
      </HStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack justifyContent="space-between" mt="44px">
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Legal First Name</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="Legal First Name"
              disabled={isReadOnly}
              {...register("first_name", { required: true })}
            />
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Legal Middle Name</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="Legal Middle Name"
              disabled={isReadOnly}
              {...register("middle_name", { required: true })}
            />
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Legal Last Name</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="Legal Last Name"
              disabled={isReadOnly}
              {...register("last_name", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack mt="1.5rem">
          <FormControl>
            <GoLiveFormLabel>Street Address</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="P.O. Boxes are not accepted forms of address"
              disabled={isReadOnly}
              {...register("street_address", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Apartment or Unit #</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="Address Line 2"
              disabled={isReadOnly}
              {...register("apartment_unit")}
            />
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>City</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: San Jose"
              disabled={isReadOnly}
              {...register("city", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>State/Province (opt.)</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="State/Province"
              disabled={isReadOnly}
              {...register("state", { required: true })}
            />
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Postal Code</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: 90210"
              disabled={isReadOnly}
              {...register("postal_code", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Country</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: United States"
              disabled={isReadOnly}
              {...register("country", { required: true })}
            />
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Phone Number</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="+1 123 4567"
              disabled={isReadOnly}
              {...register("phone_number", {
                required: true,
                pattern: {
                  value: phoneNumberRegex,
                  message: "Invalid phone number format",
                },
              })}
            />
            {errors.phone_number && (
              <GoLiveFormError>{errors.phone_number.message}</GoLiveFormError>
            )}
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Email Address</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: your@mail.com"
              disabled={isReadOnly}
              {...register("email", {
                required: true,
                pattern: {
                  value: emailRegex,
                  message: "Invalid email format",
                },
              })}
            />
            {errors.email && (
              <GoLiveFormError>{errors.email.message}</GoLiveFormError>
            )}
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Date of birth</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: 1992-05-20"
              disabled={isReadOnly}
              {...register("dob", {
                pattern: {
                  value: dateRegex,
                  message: "Date must be in YYYY-MM-DD format",
                },
              })}
            />
            {errors.dob && (
              <GoLiveFormError>{errors.dob.message}</GoLiveFormError>
            )}
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>SSN/Tax ID</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: AAA-GG-SSSS"
              disabled={isReadOnly}
              {...register("tax_id", { required: true })}
            />
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Tax ID Type</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: USA - SSN, Australia - TFN..."
              disabled={isReadOnly}
              {...register("tax_id_type", { required: true })}
            />
          </FormControl>
        </HStack>
        <Text fontSize="16px" mt="1.5rem">
          Upload photo ID of Control Person
        </Text>
        <Text fontSize="14px" mb="1.5rem" mt="8px" color="grey">
          {controlPersonIDCopy}
        </Text>
        <Box>
          <FileUpload onDrop={onDrop} isReadOnly={isReadOnly} />
          <Box display="flex" flexDirection="column">
            {fileList.map((entry, idx) => (
              <Box
                key={entry.name}
                mt="1rem"
                display="flex"
                alignItems="center"
              >
                <Box _hover={{ cursor: "pointer" }}>
                  <CloseIcon
                    mr="1rem"
                    onClick={() => {
                      if (isReadOnly) return;
                      fileList.splice(idx, 1);
                      setFileList([...fileList]);
                      // TODO: handle upload photos
                      // props.handlePhotoID([...fileList]);
                    }}
                  />
                </Box>
                <Text fontSize="1rem">{entry.name}</Text>
              </Box>
            ))}
          </Box>
        </Box>
        <VStack mt="1.5rem">
          <HStack>
            <Checkbox
              isChecked={acceptTerms || isReadOnly}
              isDisabled={isReadOnly}
              onChange={() => {
                setAcceptTerms(!acceptTerms);
              }}
            >
              Accept Terms and Conditions
            </Checkbox>
          </HStack>
          <HStack w="100%" mt="4rem" justifyContent="space-between">
            <Button w="98px" h="36px" onClick={() => session.prev()}>
              Back
            </Button>
            {isReadOnly ? (
              <Button w="98px" h="36px" onClick={() => session.next()}>
                Next
              </Button>
            ) : (
              <Button
                w="191px"
                h="36px"
                mb="48px !important"
                mt="22px !important"
                disabled={!isValid || !acceptTerms}
                rightIcon={<CheckIcon />}
                type="submit"
              >
                Submit Documents
              </Button>
            )}
          </HStack>
          <Text fontSize="14px" color="grey">
            DISCLOSURE AND AUTHORIZATION
          </Text>
          <Text fontSize="14px" color="grey">
            {alpacaAuthorizeCopy}
          </Text>
        </VStack>
      </form>
    </Box>
  );
};

export default ControlPersonCheck;
