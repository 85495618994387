import { Auth } from "aws-amplify";
import { CognitoUser as cognitoUser } from "amazon-cognito-identity-js";
import * as Sentry from "@sentry/browser";
import { setAuthHeader, claimInvite, createAccount } from "../api/api";
import {
  MFAStatus,
  ClaimInviteRequest,
  RegistrationRequest,
} from "../api/types";

interface challengeParam {
  requiredAttributes: string[];
}

export interface CognitoUser {
  username: string;
  challengeName?: string;
  challengeParam?: challengeParam;
}

const USER_UNAUTHENTICATED = "The user is not authenticated";

export class AuthService {
  static getCurrentUser = async (): Promise<cognitoUser | void> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setAuthHeader();
      return user;
    } catch (e) {
      if (e !== USER_UNAUTHENTICATED) {
        Sentry.captureException(new Error(e));
      }
      return;
    }
  };

  static isLoggedIn = async (): Promise<boolean> => {
    try {
      const user = await AuthService.getCurrentUser();
      if (!user) {
        return false;
      }

      // get account email
      user.getUserAttributes((_, results) => {
        const emailAttribute = results?.find(
          (attribute) => attribute.Name === "email"
        );
        if (emailAttribute) Sentry.setUser({ email: emailAttribute.Value });
      });

      setAuthHeader();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  static register = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signUp({
      username: email,
      password,
    });

    console.info("Registered", user);
  };

  static resendConfirmationCode = async (email: string): Promise<void> => {
    await Auth.resendSignUp(email);
    console.info("code resent successfully");
  };

  static getPreferredMFA = async (): Promise<string> => {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.getPreferredMFA(user, { bypassCache: true });
  };

  // bypassCache: https://github.com/aws-amplify/amplify-js/issues/2627
  // TODO: Disabling MFA can only be done in the cognito panel for now
  static enableMFA = async (): Promise<MFAStatus> => {
    const user = await Auth.currentAuthenticatedUser();
    const preferredMFA = await Auth.getPreferredMFA(user, {
      bypassCache: true,
    });
    if (preferredMFA === "SMS_MFA") return "ENABLED";

    if (user.attributes.phone_number) {
      if (user.attributes.phone_number_verified) {
        const resp = await Auth.setPreferredMFA(user, "SMS");
        if (resp !== "SUCCESS") throw new Error("unable to enable SMS MFA");

        const p = await Auth.getPreferredMFA(user, { bypassCache: true });
        if (p !== "SMS_MFA") throw new Error("failed to set MFA preference");

        return "ENABLED";
      } else {
        await Auth.verifyUserAttribute(user, "phone_number");
        return "VERIFY_PHONE";
      }
    }

    return "PHONE_REQUIRED";
  };

  static addPhone = async (phone: string): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    const resp = await Auth.updateUserAttributes(user, {
      phone_number: phone,
    });
    if (resp !== "SUCCESS") throw new Error("failed to update phone number");
    await Auth.verifyUserAttribute(user, "phone_number");
  };

  static verifyPhone = async (code: string): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    const resp = await Auth.verifyUserAttributeSubmit(
      user,
      "phone_number",
      code
    );
    if (resp !== "SUCCESS") throw new Error("unable to verify phone number");
    const mfaResp = await Auth.setPreferredMFA(user, "SMS");
    if (mfaResp !== "SUCCESS") throw new Error("unable to enable SMS MFA");
  };

  static login = async (
    email: string,
    password: string
  ): Promise<CognitoUser> => {
    const user = await Auth.signIn(email.toLowerCase(), password);
    Sentry.setUser({ email });
    if (
      user.challengeName === "NEW_PASSWORD_REQUIRED" ||
      user.challengeName === "SMS_MFA"
    )
      return user;
    setAuthHeader();
    return user;
  };

  static confirmSMSLogin = async (
    user: CognitoUser,
    code: string
  ): Promise<CognitoUser> => {
    return Auth.confirmSignIn(user, code, "SMS_MFA");
  };

  static confirmSignUp = async (
    email: string,
    code: string,
    name: string,
    password: string
  ): Promise<void> => {
    await Auth.confirmSignUp(email, code, {
      forceAliasCreation: true,
    });

    if (password) {
      const authUser = await AuthService.login(email, password);

      const req: RegistrationRequest = {
        source: "brokerdash",
        cognito_id: authUser.username,
        email: email,
      };
      if (name !== "") {
        req.name = name;
      }

      await setAuthHeader();
      const newUser = await createAccount(req);
      console.info("Registration completed: ", newUser);
    }
  };

  static signOut = async (): Promise<void> => {
    await Auth.signOut();
  };

  static changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const user = await AuthService.getCurrentUser();
    if (!user) {
      throw new Error(USER_UNAUTHENTICATED);
    }
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  // setNewPassword doesn't need old password as it was a generated one-time-use access code.
  static setNewPassword = async (
    user: CognitoUser,
    newPassword: string,
    name: string
  ): Promise<void> => {
    await Auth.completeNewPassword(user, newPassword);

    await setAuthHeader();
    const req: ClaimInviteRequest = { name };
    await claimInvite(req);
  };

  static forgotPassword = async (email: string): Promise<void> => {
    const data = await Auth.forgotPassword(email);
    console.info("Password reset: " + data);
  };

  static forgotPasswordSetNew = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
    console.info("Changed password: " + data);
  };
}
