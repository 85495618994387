import React from "react";

import { Session } from "../session";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Playground } from "../../../components/general/Playground";
import { Link as ReactRouterLink } from "react-router-dom";
import { Alert, AlertIcon, Box, Button, Link, Text } from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../../globals/amplitude";

const MilestoneTwo = ({
  data,
  next,
  setLocalData,
}: Session): React.ReactElement => {
  const request = JSON.stringify(data.account, null, 2);

  const onResponse = (response: { id: string }) => {
    if (response.id && data.account) {
      data.account.id = response.id;
      setLocalData({
        ...data,
        response: JSON.stringify(response),
        canMoveOn: true,
      });
    }
  };

  return (
    <Box maxW="1000px">
      <Text fontSize="xl" fontWeight="medium">
        Create an Account
      </Text>
      <Text mt=".5rem">
        One of the first things you would need to do using Broker API is to
        create an account for your end user. Depending on the type of setup you
        have with Alpaca (<Link>Fully-Disclosed</Link>,{" "}
        <Link>Non-Disclosed</Link>, <Link>Omnibus</Link> or <Link>RIA</Link>)
        the requirements might differ.
      </Text>
      <Text mt=".5rem" mb="2rem">
        Below is a sample request to create an account for a{" "}
        <i>Fully-Disclosed</i> setup:
      </Text>
      {data.canMoveOn && (
        <Alert status="warning" variant="left-accent" my="2rem">
          <AlertIcon as={CheckCircleIcon} />
          You successfully ran the request! Click&nbsp;
          <Link
            as={ReactRouterLink}
            to={`/accounts/${data.account?.id}`}
            target="_blank"
            onClick={() =>
              getAmplitude().track({
                event_type: EventType.QUICKSTART_ACCOUNTPAGE,
              })
            }
          >
            here
          </Link>
          &nbsp;to see the account.
        </Alert>
      )}
      <Playground
        key="milestone-two"
        method="POST"
        endpoint="/v1/accounts"
        instant={true}
        readOnly={true}
        request={request}
        onResponse={onResponse}
        response={data.response}
        disabled={data.canMoveOn}
        step="step-2"
      />
      <Box textAlign="right" my="2rem">
        <Button
          disabled={!data.canMoveOn}
          onClick={() => {
            next();
            getAmplitude().track({
              event_type: EventType.QUICKSTART_STEP3,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MilestoneTwo;
