import React, { useState, useEffect } from "react";
import { VStack, Box, useColorModeValue, Text } from "@chakra-ui/react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
} from "recharts";
import styled from "styled-components";
import Card from "./Card";
import NoContent from "./NoContent";
import colors from "../theme/colors";
import { BrokerAPIDocSrc } from "../../globals/consts";

export interface LineValue {
  name: string;
  value: number;
}

interface LineCardProps {
  title: string | React.ReactElement;
  values: LineValue[];
  height?: string;

  // Values for no content display
  showEmpty?: boolean;
  noContentTitle?: string;
  noContentLinkText?: string;
  noContentLinkDestination?: string;
}

const TooltipBox = styled(Box)`
  background: ${(props) => props.bgColor};
  border-radius: 4px;
  padding: 1rem;
`;

const isEmpty = (vals: LineValue[]): boolean => {
  for (const v of vals) {
    if (v.value > 0) return false;
  }
  return true;
};

const LineCard = (props: LineCardProps): React.ReactElement => {
  const [empty, setEmpty] = useState(isEmpty(props.values));
  const color = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );
  const tooltipColor = useColorModeValue(
    colors.cardBackgroundLight,
    colors.bgDark
  );
  const h = props.height;

  useEffect(() => {
    if (props.showEmpty) return;
    setEmpty(isEmpty(props.values));
  }, [props.values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltipContent = (tooltipProps: any) => {
    let value, name;
    if (tooltipProps.payload?.length > 0) {
      value = tooltipProps.payload[0]?.value;
      name = tooltipProps.payload[0]?.payload?.name;
    }
    return (
      <TooltipBox bgColor={tooltipColor} boxShadow="md">
        <Text>{props.title}</Text>
        <Text>{name}</Text>
        <Text>Accounts: {value}</Text>
      </TooltipBox>
    );
  };

  return (
    <Card height={h}>
      <VStack align="center" justify="center">
        <Box fontSize="xl" alignSelf="baseline">
          {props.title}
        </Box>
        <Box w="100%" h={`calc(${h} - 5rem)`}>
          {empty && !props.showEmpty ? (
            <Box mt="1rem">
              <NoContent
                title={props.noContentTitle || "No data available"}
                image="chart"
                linkText={
                  props.noContentLinkText || "Learn more about this data"
                }
                linkDestination={
                  props.noContentLinkDestination || BrokerAPIDocSrc
                }
              />
            </Box>
          ) : (
            <ResponsiveContainer>
              <AreaChart data={props.values}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#006CCB" stopOpacity={0.3} />
                    <stop offset="75%" stopColor={color} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip content={tooltipContent} />
                <CartesianGrid vertical={false} stroke="#DDD" />
                <Area
                  type="monotone"
                  dataKey="value"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Box>
      </VStack>
    </Card>
  );
};

export default LineCard;
