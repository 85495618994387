import BusinessDetails from "./BusinessDetails";
import ProductDetails from "./ProductDetails";
import ControlPersonCheck from "./ControlPersonCheck";
import Summary from "./Summary";
import styled from "@emotion/styled";
import colors from "../../components/theme/colors";

import React, { useContext, useState } from "react";

import { useGoLiveSession } from "./goLiveSession";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import {
  createEntityDetails,
  createControlPerson,
  setCorrespondentHeader,
  getSelf,
  getEntityDetails,
} from "../../api/api";
import {
  CreateControlPersonRequest,
  CreateEntityDetailsRequest,
} from "../../api/types";
import { AppContext } from "../../globals/appcontext";
import { StepStatus } from "../../globals/types";
import { AlpacaSVG, AlpacaDarkSVG } from "../../icons/Login";

import {
  Text,
  CloseButton,
  HStack,
  VStack,
  Button,
  Box,
  FormLabel,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

export const GoLiveFormLabel = styled(FormLabel)`
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 4px;
  color: #ffffff90;
`;

export const GoLiveFormError = styled(FormLabel)`
  font-size: 12px;
  margin-left: 12px;
  margin-top: 8px;
  color: #fc8181;
`;

export const GoLivePill = styled(Button)`
  height: 36px;
  width: 137px;
  border-radius: 8px;
  background-color: ${(props) => (props.active ? "#F6D00012" : "#35383d")};
  color: ${(props) => (props.active ? "#F6D000" : "#ffffff95")};
  border-color: ${(props) => (props.active ? "#F6D000" : "")};
  border-width: ${(props) => (props.active ? "1px" : "")};
  :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  :hover {
    color: black;
  }
`;

const GoLiveCircleColors: Record<StepStatus, string> = {
  [StepStatus.Started]: `background-color: ${colors.gold}; color: black;`,
  [StepStatus.Completed]: `background-color: transparent; color: ${colors.gold}; border: ${colors.gold} 1px solid;`,
  [StepStatus.Incomplete]:
    "background-color: transparent; color: gray; border: gray 1px solid;",
};

const GoLiveCircle = styled(Box)`
  && {
    height: 28px;
    min-width: 28px;
    line-height: 26px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    ${(props) => GoLiveCircleColors[props.$status as StepStatus]}
  }
`;

const AlpacaIconBox = styled(Box)`
  @media (max-width: 1525px) {
    display: none;
  }
`;

const AlpacaLeft = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox>
      <Icon
        as={svg}
        fontSize="280px"
        position="absolute"
        right="25rem"
        top="40.25rem"
      />
    </AlpacaIconBox>
  );
};

const AlpacaRight = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox>
      <Icon
        as={svg}
        fontSize="500px"
        position="absolute"
        right="6.5rem"
        top="26.5rem"
        transform="scaleX(-1)"
      />
    </AlpacaIconBox>
  );
};

const GoLiveProgressStep = (
  step: number,
  title: string,
  status: StepStatus
) => {
  const isCurrent = status === StepStatus.Started;
  return (
    <HStack>
      <GoLiveCircle $status={status}>
        <Text fontSize="14px" fontWeight="500">
          {step + 1}
        </Text>
      </GoLiveCircle>
      <Text fontSize="16px" color={isCurrent ? "white" : "gray"}>
        {title}
      </Text>
    </HStack>
  );
};

const GoLiveEllipse = () => {
  return (
    <VStack ml="11px !important">
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
    </VStack>
  );
};

const GoLive = (): React.ReactElement => {
  const session = useGoLiveSession();
  const step = session.data.step;
  const [readOnly, setReadOnly] = useState(false);

  const navigate = useNavigate();

  const appContext = useContext(AppContext);
  const sandboxCode = appContext.correspondent.ID;

  const { data: authUser } = useQuery("self", () => getSelf());

  const { data: entityDetails, refetch: refetchEntityDetails } = useQuery(
    ["entityDetails"],
    () => getEntityDetails(),
    {
      onSuccess: (data) => {
        if (data.control_person.first_name) {
          setReadOnly(true);
        }
      },
    }
  );

  const entityId = entityDetails?.entity_details?.id || "";
  interface GoLiveStep {
    title: string;
    view: React.ReactElement;
  }

  const GoLiveProgress = (currentStep: number) => {
    return (
      <VStack alignItems="baseline" ml="1em" mt="84px">
        {steps.map((val, idx) => {
          // use staus to render different states of the steps
          let status = StepStatus.Incomplete;
          if (currentStep === idx) {
            status = StepStatus.Started;
          } else if (currentStep > idx) {
            status = StepStatus.Completed;
          }
          return (
            <>
              {GoLiveProgressStep(idx, val.title, status)}
              {idx < 3 && GoLiveEllipse()}
            </>
          );
        })}
      </VStack>
    );
  };

  // 1. Creates an entry in the Entity Details table in GoBroker
  // 2. Uses the generated entity_id to create a Control Person entry linked to the entity
  // 3. Finally link current correspondent to the given entity and submit request to go live
  const submitEntityRequest = (values: CreateEntityDetailsRequest) => {
    const linksArray = values.social_media_links?.length
      ? String(values.social_media_links).split(/[, ]+/)
      : [];

    const dbaDate = values.dba_incoporation_date?.length
      ? values.dba_incoporation_date
      : undefined;
    const fspDate = values.fsp_registration_effective_date?.length
      ? values.fsp_registration_effective_date
      : undefined;

    const entityReq = {
      id: entityId,
      entity_legal_name: values.entity_legal_name,
      entity_type: values.entity_type,
      incorporation_date: values.incorporation_date,
      country_of_registration: values.country_of_registration,
      tax_id: values.tax_id,
      tax_id_type: values.tax_id_type,
      address: values.address,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      phone_number: values.phone_number,
      dba_entity_type: values.dba_entity_type,
      dba_legal_entity_name: values.dba_legal_entity_name,
      dba_incoporation_date: dbaDate,
      dba_country_of_registration: values.dba_country_of_registration,
      dba_address: values.dba_address,
      dba_relationship: values.dba_relationship,
      dba_additional_info: values.dba_additional_info,
      fsp_registration_type: values.fsp_registration_type,
      fsp_country_of_registration: values.fsp_country_of_registration,
      fsp_registration_effective_date: fspDate,
      fsp_registration_number: values.fsp_registration_number,
      fsp_additional_info: values.fsp_additional_info,
      kyb_status: "SUBMITTED",
      email: authUser?.email,
      social_media_links: linksArray,
      correspondent: sandboxCode,
      // TODO: accept files on BE
      // files: ,
      bd_data: {
        url: values?.bd_data?.url,
        user_countries: values?.bd_data?.user_countries,
        number_of_users: values?.bd_data?.number_of_users,
        app_type: values?.bd_data?.app_type,
        integration: values?.bd_data?.integration,
        funding_plan: values?.bd_data?.funding_plan,
        funding_flow: values?.bd_data?.funding_flow,
        launch_guide_partner_type: values?.bd_data?.launch_guide_partner_type,
        info_integration_plan: values?.bd_data?.info_integration_plan,
        info_customer_types: values?.bd_data?.info_customer_types,
        info_geographic_markets: values?.bd_data?.info_customer_types,
      },
    };
    createEntityDetails(entityReq).then(() => {
      session.next();
      refetchEntityDetails();
    });
  };

  const submitControlPersonRequest = (values: CreateControlPersonRequest) => {
    const controlPersonReq = {
      entity_id: entityId,
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      street_address: values.street_address,
      apartment_unit: values.apartment_unit,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      country: values.country,
      phone_number: values.phone_number,
      email: values.email,
      dob: values.dob,
      tax_id: values.tax_id,
      tax_id_type: values.tax_id_type,
      kyc_status: "SUBMITTED",
      // TODO: accept files on BE
      // files:,
    };
    createControlPerson(controlPersonReq).then(() => {
      setCorrespondentHeader(sandboxCode as string, "sandbox");
      setReadOnly(true);
      session.next();
      refetchEntityDetails();
    });
  };

  const steps: GoLiveStep[] = [
    {
      title: "Business Details",
      view: (
        <BusinessDetails
          key={"Business Details"}
          onSubmit={submitEntityRequest}
          entityDetails={entityDetails}
          isReadOnly={readOnly}
        />
      ),
    },
    {
      title: "Product Details",
      view: (
        <ProductDetails
          key={"Product Details"}
          onSubmit={submitEntityRequest}
          session={session}
          entityDetails={entityDetails}
          isReadOnly={readOnly}
        />
      ),
    },
    {
      title: "Control Person Check",
      view: (
        <ControlPersonCheck
          key={"Control Person Check"}
          onSubmit={submitControlPersonRequest}
          session={session}
          entityDetails={entityDetails}
          isReadOnly={readOnly}
        />
      ),
    },
    { title: "Summary", view: <Summary key={"Summary"} session={session} /> },
  ];

  return (
    <HStack>
      <Box w="224px" alignSelf="baseline">
        {GoLiveProgress(step)}
      </Box>
      <Box pt="1.5em" pl="1.5em" pr="2.5em">
        <HStack>
          <Text fontSize="22px" fontWeight="medium" mr="auto">
            {steps[step].title}
          </Text>
          <HStack width="max-content">
            <Text fontSize="16px" fontWeight="medium">
              Close
            </Text>
            <CloseButton onClick={() => navigate("/dashboard")} />
          </HStack>
        </HStack>
        <HStack mt="4rem">
          <Box w="608px">{steps[step].view}</Box>
          <Box>
            <AlpacaLeft />
            <AlpacaRight />
          </Box>
        </HStack>
      </Box>
    </HStack>
  );
};

export default GoLive;
