import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import styled from "styled-components";

import colors from "../theme/colors";
import EmptyListImage from "../images/EmptyList";
import EmptyChartImage from "../images/EmptyChart";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.1rem;
`;

const LinkOut = styled.a`
  color: ${colors.yellow};
  margin: 0px 5px;
  &:hover {
    text-decoration: none;
  }
  &:after {
    display: block;
    content: "";
    border-bottom: 1px solid ${colors.yellow};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`;

interface NoContentProps {
  image: "table" | "chart";
  title: string;
  linkText?: string;
  linkDestination?: string;
  halfCard?: boolean;
}

const NoContent = (props: NoContentProps): React.ReactElement => {
  return (
    <Container>
      <VStack spacing={6}>
        <Text>{props.title}</Text>
        {!props.halfCard &&
          (props.image === "table" ? <EmptyListImage /> : <EmptyChartImage />)}
        {props.linkText && props.linkDestination && (
          <>
            <LinkOut href={props.linkDestination} target="_blank">
              {props.linkText} <ChevronRightIcon />
            </LinkOut>
          </>
        )}
      </VStack>
    </Container>
  );
};

export default NoContent;
