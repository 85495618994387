import FileUpload from "../../components/general/FileUpload";
import styled from "styled-components";

import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { GoLiveSession } from "./goLiveSession";
import {
  CreateEntityDetailsRequest,
  GetEntityAndControlPersonResponse,
} from "../../api/types";

import {
  Box,
  Text,
  HStack,
  VStack,
  FormControl,
  Input,
  RadioGroup,
  Radio,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface ProductDetailsProps {
  onSubmit: (values: CreateEntityDetailsRequest) => void;
  session: GoLiveSession;
  isReadOnly: boolean;
  entityDetails?: GetEntityAndControlPersonResponse;
}

export const GoLiveRadioGroup = styled(RadioGroup)`
  .chakra-radio {
    .chakra-radio__control {
      display: none;
    }
    .chakra-radio__label {
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 137px;
      border-radius: 8px;
      background-color: #35383d;
      color: #ffffff95;
      transition: 0.1s ease-in;
      :focus {
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
      }
      :hover {
        background-color: #f6d000;
        color: black;
      }
    }
  }
  [data-checked] {
    .chakra-radio__control {
      display: none;
    }
    .chakra-radio__label {
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 137px;
      border-radius: 8px;
      background-color: #f6d00012;
      color: #f6d000;
      border-width: 1px;
      border-color: #f6d000;
      :hover {
        background-color: #f6d000;
        color: black;
      }
    }
  }
`;

const ProductDetails = ({
  onSubmit,
  session,
  isReadOnly,
  entityDetails,
}: ProductDetailsProps): React.ReactElement => {
  const [fileList, setFileList] = useState<File[]>([]);
  const onDrop = (acceptedFiles: File[]) => {
    setFileList(acceptedFiles);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<CreateEntityDetailsRequest>({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entityDetails?.entity_details });
  }, [entityDetails]);

  return (
    <Box w="100%" mb="44px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="16px" mb="12px">
          How many users do you currently have?
        </Text>
        <Controller
          control={control}
          name="bd_data.number_of_users"
          render={({ field: { onChange, value } }) => (
            <GoLiveRadioGroup
              value={value}
              onChange={(value: string) => onChange(value)}
            >
              <HStack w="100%">
                <Radio value="< 1000" isDisabled={isReadOnly}>
                  <Text fontWeight="medium" fontSize="15px">
                    {"< 1,000"}
                  </Text>
                </Radio>
                <Radio
                  value="1,001 - 5,000"
                  ml="20px !important"
                  isDisabled={isReadOnly}
                >
                  <Text fontWeight="medium" fontSize="15px">
                    {"1,001 - 5,000"}
                  </Text>
                </Radio>
                <Radio
                  value="5,001 - 25,000"
                  ml="20px !important"
                  isDisabled={isReadOnly}
                >
                  <Text fontWeight="medium" fontSize="15px">
                    {"5,001 - 25,000"}
                  </Text>
                </Radio>
                <Radio
                  value="> 25,000"
                  ml="20px !important"
                  isDisabled={isReadOnly}
                >
                  <Text fontWeight="medium" fontSize="15px">
                    {"> 25,000"}
                  </Text>
                </Radio>
              </HStack>
            </GoLiveRadioGroup>
          )}
        />
        <VStack mt="40px" alignItems="start">
          <Text fontSize="16px" mb="12px !important">
            Will trading services provided by Alpaca be the focus of the app or
            a simple add-on?
          </Text>
          <Controller
            control={control}
            name="bd_data.app_type"
            render={({ field: { onChange, value } }) => (
              <GoLiveRadioGroup
                value={value}
                onChange={(value: string) => onChange(value)}
              >
                <HStack w="100%">
                  <Radio value="focus_of_app" isDisabled={isReadOnly}>
                    <Text fontWeight="medium" fontSize="15px">
                      Focus of App
                    </Text>
                  </Radio>
                  <Radio
                    value="add_on"
                    ml="20px !important"
                    isDisabled={isReadOnly}
                  >
                    <Text fontWeight="medium" fontSize="15px">
                      Add-on
                    </Text>
                  </Radio>
                </HStack>
              </GoLiveRadioGroup>
            )}
          />
        </VStack>
        <VStack mt="40px" alignItems="start">
          <Text fontSize="16px" mb="8px">
            Are you replacing another platform/product with Alpaca Broker API?
          </Text>
          <Controller
            control={control}
            name="bd_data.integration"
            render={({ field: { onChange, value } }) => (
              <GoLiveRadioGroup
                value={value}
                onChange={(value: string) => onChange(value)}
              >
                <HStack w="100%">
                  <Radio value="is_replacement" isDisabled={isReadOnly}>
                    <Text fontWeight="medium" fontSize="15px">
                      Yes
                    </Text>
                  </Radio>
                  <Radio
                    value="not_replacement"
                    ml="20px !important"
                    isDisabled={isReadOnly}
                  >
                    <Text fontWeight="medium" fontSize="15px">
                      No
                    </Text>
                  </Radio>
                </HStack>
              </GoLiveRadioGroup>
            )}
          />
        </VStack>
        <Text fontSize="16px" mb="12px" mt="40px">
          How will you integrate with Alpaca / use Alpaca services?
        </Text>
        <FormControl w="100%">
          <Input
            variant="filled"
            placeholder="Provide details"
            disabled={isReadOnly}
            {...register("bd_data.info_integration_plan")}
          />
        </FormControl>
        <Text fontSize="16px" mb="12px" mt="40px">
          What types of customers do you expect will use your services?”
        </Text>
        <FormControl w="100%">
          <Input
            variant="filled"
            placeholder="eg: High-net-worth individuals, legal entities, etc."
            disabled={isReadOnly}
            {...register("bd_data.info_customer_types")}
          />
        </FormControl>
        <Text fontSize="16px" mb="12px" mt="40px">
          What are the geographic markets where you conduct business?
        </Text>
        <FormControl w="100%">
          <Textarea
            variant="filled"
            placeholder="List of countries based on current/expected user population, along with percentage of current/expected business. ie Singapore - 50%, India 25%, etc."
            disabled={isReadOnly}
            {...register("bd_data.info_geographic_markets")}
          />
        </FormControl>
        <VStack mt="40px" alignItems="start">
          <Text fontSize="16px" mb="8px">
            How do you plan on funding your users&apos; accounts?
          </Text>
          <Controller
            control={control}
            name="bd_data.funding_plan"
            render={({ field: { onChange, value } }) => (
              <GoLiveRadioGroup
                value={value}
                onChange={(value: string) => onChange(value)}
              >
                <HStack w="100%">
                  <Radio value="wire" isDisabled={isReadOnly}>
                    <Text fontWeight="medium" fontSize="15px">
                      Wire
                    </Text>
                  </Radio>
                  <Radio
                    value="ach"
                    ml="20px !important"
                    isDisabled={isReadOnly}
                  >
                    <Text fontWeight="medium" fontSize="15px">
                      ACH (US Only)
                    </Text>
                  </Radio>
                  <Radio
                    value="other"
                    ml="20px !important"
                    isDisabled={isReadOnly}
                  >
                    <Text fontWeight="medium" fontSize="15px">
                      Other
                    </Text>
                  </Radio>
                </HStack>
              </GoLiveRadioGroup>
            )}
          />
        </VStack>
        <FormControl w="100%" mt="40px">
          <Textarea
            variant="filled"
            placeholder="Please describe your finding plan"
            disabled={isReadOnly}
            {...register("bd_data.funding_flow")}
          />
        </FormControl>
        <Text fontSize="16px" mb="24px" mt="46px">
          Upload flow of funds diagram (optional)
        </Text>
        <Box>
          <FileUpload onDrop={onDrop} isReadOnly={isReadOnly} />
          <Box display="flex" flexDirection="column">
            {fileList.map((entry, idx) => (
              <Box
                key={entry.name}
                mt="1rem"
                display="flex"
                alignItems="center"
              >
                <Box _hover={{ cursor: "pointer" }}>
                  <CloseIcon
                    mr="1rem"
                    onClick={() => {
                      if (isReadOnly) return;
                      fileList.splice(idx, 1);
                      setFileList([...fileList]);
                    }}
                  />
                </Box>
                <Text fontSize="1rem">{entry.name}</Text>
              </Box>
            ))}
          </Box>
        </Box>
        <HStack w="100%" mt="4rem" justifyContent="space-between">
          <Button w="98px" h="36px" onClick={() => session.prev()}>
            Back
          </Button>
          <Button w="98px" h="36px" ml="auto" type="submit" disabled={!isValid}>
            Next
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default ProductDetails;
