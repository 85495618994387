import React from "react";
import moment from "moment";

import { Session } from "../session";
import { getTransfers } from "../../../api/api";
import { Playground } from "../../../components/general/Playground";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { EventType, getAmplitude } from "../../../globals/amplitude";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";

const MilestoneFour = ({
  data,
  next,
  polling,
  setLocalData,
  setPolling,
}: Session): React.ReactElement => {
  const request = JSON.stringify({
    transfer_type: "ach",
    relationship_id: data.relationshipId,
    amount: "1234.56",
    direction: "INCOMING",
  });

  const isTransferLikeResponse =
    "direction" in JSON.parse(data.response ?? "{}");

  // if we have a timeout, clear it
  const onPollDidComplete = () => data.timeout && clearInterval(data.timeout);

  // fetch the transfer and update the state if completed
  const onPoll = async () => {
    const delay = moment.duration(2, "seconds").asMilliseconds();

    if (polling) {
      return;
    }

    setPolling(true);

    try {
      // get the most recent (and only) transfer
      const transfers = await getTransfers({ limit: 1 }, data.account?.id);

      // if the transfer was successful
      if (transfers.every(({ status }) => status === "COMPLETE")) {
        setLocalData({ ...data, canMoveOn: true });
        onPollDidComplete();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // after delay, allow polling again
      new Promise((r) => setTimeout(r, delay)).then(() => setPolling(false));
    }
  };

  const onResponse = (response: { id: string }) => {
    // schedule a job to check the status of the transfer
    const timeout = setInterval(
      onPoll,
      moment.duration(1, "minute").asMilliseconds()
    );

    // if we are already polling, clear the previous interval
    onPollDidComplete();

    // save the timeout and response
    setLocalData({ ...data, timeout, response: JSON.stringify(response) });
  };

  return (
    <Box maxW="1000px">
      <Text fontSize="xl" fontWeight="medium">
        Making a Virtual ACH Transfer
      </Text>
      <Text mt=".5rem" mb="2rem">
        Now that you have an existing ACH relationship between the account and
        their bank, you can fund the account via ACH using the following
        endpoint <code>POST /v1/accounts/&#123;account_id&#125;/transfers</code>{" "}
        using the <code>relationship_id</code> we got in the response of the
        previous section.
      </Text>
      {!data.canMoveOn && isTransferLikeResponse && (
        <Alert status="warning" variant="left-accent" my="2rem">
          <AlertIcon
            as={data.canMoveOn ? CheckCircleIcon : Spinner}
            w="20px"
            h="20px"
          />
          You successfully ran the request! This can take up to&nbsp;
          <Text fontWeight="bold" textDecor="underline">
            10 minutes
          </Text>
          &nbsp;to process. Transaction details are available&nbsp;
          {/** todo: parse id from response */}
          <Link
            as={ReactRouterLink}
            to={`/accounts/${data.account?.id}`}
            target="_blank"
            onClick={() =>
              getAmplitude().track({
                event_type: EventType.QUICKSTART_TRANSACTION,
              })
            }
          >
            here
          </Link>
          .
          <Button ml="auto" size="sm" disabled={polling} onClick={onPoll}>
            {polling ? "Checking..." : "Re-Check"}
          </Button>
        </Alert>
      )}
      <Playground
        key="milestone-four"
        method="POST"
        instant={true}
        readOnly={true}
        request={request}
        response={data.response}
        onResponse={onResponse}
        disabled={data.canMoveOn || isTransferLikeResponse}
        endpoint={`/v1/accounts/${data.account?.id}/transfers`}
        step="step-4"
      />
      <Box textAlign="right" my="2rem">
        <Button
          disabled={!data.canMoveOn}
          onClick={() => {
            next();
            getAmplitude().track({
              event_type: EventType.QUICKSTART_STEP5,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MilestoneFour;
