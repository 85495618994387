import React from "react";
import Header from "../components/layout/Header";
import OrdersTable from "../components/tables/OrdersTable";

import { useQuery } from "react-query";
import { getCorrespondent } from "../api/api";

const Orders = (): React.ReactElement => {
  const { data: correspondent } = useQuery("correspondent", () =>
    getCorrespondent()
  );

  const tradingCurrency = correspondent?.lct_currency ?? "USD";

  return (
    <>
      <Header title="Transactions > Orders" />
      <OrdersTable currency={tradingCurrency} />
    </>
  );
};

export default Orders;
