import React, { useState } from "react";
import { Box, Collapse, Text } from "@chakra-ui/react";
import colors from "../theme/colors";
import { isMobile } from "react-device-detect";

export interface FooterItem {
  name: string;
  submenu: { name: string; path: string }[];
}

/**
 * @name FooterSubMenu
 * @description Renders a navigation item for the footer area of the LeftNav that opens a submenu on hover and on click
 * @param name - Name displayed for Footer item with Submenu
 * @param submenu - array of navigation items
 */
export const FooterSubMenu: React.FC<FooterItem> = ({ name, submenu }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  // Click to open only on mobile
  const handleHoverOpen = () => !isMobile && setIsOpen(true);
  const handleHoverClose = () => !isMobile && setIsOpen(false);

  return (
    <Box onMouseOver={handleHoverOpen} onMouseLeave={handleHoverClose}>
      <Text
        pt="1rem"
        pl="2rem"
        _hover={{ fontWeight: "600", cursor: "pointer" }}
        fontWeight={isOpen ? "bold" : "normal"}
        onClick={toggleOpen}
      >
        {name}
      </Text>
      <Collapse in={isOpen}>
        {submenu.map((item) => (
          <a key={item.name} href={item.path}>
            <Text
              pl="2rem"
              fontSize="14px"
              _hover={{
                fontWeight: "600",
                cursor: "pointer",
                color: colors.gold,
              }}
            >
              {item.name}
            </Text>
          </a>
        ))}
      </Collapse>
    </Box>
  );
};
