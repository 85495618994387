import { useToast } from "@chakra-ui/react";
import { useQuery, UseQueryResult } from "react-query";
import { getCountryRisk } from "../../api/api";
import { CountryInfo } from "../../api/types";

const useCountryRisk = (): UseQueryResult<CountryInfo, unknown> => {
  const toast = useToast();
  const responds = useQuery("country-infos", () => getCountryRisk(), {
    // Show a toast if there is an error
    onError: (err) =>
      toast({
        title: "An error occurred fetching the country information",
        description: (err as Error).message,
        status: "error",
      }),
  });

  return responds;
};

export default useCountryRisk;
