import { Location } from "history";
import ReactGA from "react-ga";

const trackingID = process.env.REACT_APP_GA_TRACKING_ID;

if (trackingID) {
  console.log("Enabling Google Analytics");
  ReactGA.initialize(trackingID);
}

export const locationUpdate = (location: Location): void => {
  if (trackingID) {
    ReactGA.pageview(location.pathname);
  }
};

export const event = (category: string, action: string): void => {
  if (trackingID) {
    ReactGA.event({
      category,
      action,
    });
  }
};

export const timing = (
  category: string,
  variable: string,
  ms: number
): void => {
  if (trackingID) {
    ReactGA.timing({
      category,
      variable,
      value: ms,
    });
  }
};
