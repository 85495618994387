import axios, { AxiosResponse, Method } from "axios";
import moment from "moment-timezone";
import qs from "qs";

import {
  getPaciamJwt,
  paciamInstance,
  refreshPaciamToken,
} from "../auth/paciam";
import { Correspondent } from "../globals/appcontext";
import { getCookie, formatDate } from "../globals/utils";
import { RawCorporateAction } from "../pages/corporateActions/types";
import {
  Account,
  Activity,
  ApiKey,
  ClaimInviteRequest,
  CorrespondentResponse,
  Document,
  DocumentDownloadParams,
  DocumentsParams,
  EditTeamMemberRequest,
  Journal,
  JournalsParams,
  ListActivitiesParams,
  Order,
  OrdersParams,
  RegistrationRequest,
  Stats,
  TeamMember,
  Transfer,
  TransfersParams,
  Position,
  AddTeamMemberRequest,
  AccountsParams,
  Generic,
  PositionsQuery,
  UpdateCorrespondentRequest,
  BusinessType,
  CreateEntityDetailsRequest,
  EntityDetailsResponse,
  CreateControlPersonRequest,
  CreateControlPersonResponse,
  UploadZendeskTicketAttachmentResponse,
  CreateZendeskTicketRequest,
  CreateZendeskTicketResponse,
  TradingAccount,
  UploadedDocument,
  GetEntityAndControlPersonResponse,
  PatchCorrespondentRequest,
  GetAggregatePositionsResponse,
  AggregatePosition,
  CountryInfo,
  PatchEntityDetailsRequest,
  PatchCorrespondentConfigRequest,
  IntercomUserHashResponse,
  Report,
  IPAllowlist,
} from "./types";

const sandbox = `${process.env.REACT_APP_SANDBOX_API_HOST}/internal/dash`;
const prod = `${process.env.REACT_APP_PROD_API_HOST}/internal/dash`;
const brokerAPI = `${process.env.REACT_APP_PROD_API_HOST}/dash`;
const brokerAPISandbox = `${process.env.REACT_APP_SANDBOX_API_HOST}/dash`;
const paciam = `${process.env.REACT_APP_PACIAM_API_HOST}/api`;

// Leave commented for now, see ENG-9457
// The timeout will be 0, which means the request will never timeout
// axios.defaults.timeout = 10000;Capture 2022-05-24 at 12.35.21.png
axios.defaults.headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const instance = axios.create({ baseURL: sandbox });
const brokerAPIInstance = axios.create({ baseURL: brokerAPISandbox });
const iamInstance = axios.create({ baseURL: paciam });

const zendeskInstance = axios.create({
  baseURL: "https://alpacahelp.zendesk.com/api/v2",
});

export const asProd = async <T>(
  request: Promise<AxiosResponse<T>>
): Promise<AxiosResponse> => {
  instance.defaults.baseURL = prod;
  return await request
    .then((data) => data)
    .finally(() => (instance.defaults.baseURL = sandbox));
};

export const setEnv = (c: Correspondent): void => {
  setCorrespondentHeader(c.ID || "", c.Env || "");
  switch (c.Env) {
    case "live":
      instance.defaults.baseURL = prod;
      brokerAPIInstance.defaults.baseURL = brokerAPI;
      break;
    default:
      instance.defaults.baseURL = sandbox;
      brokerAPIInstance.defaults.baseURL = brokerAPISandbox;
      break;
  }
};

export const getEnv = (): string => {
  switch (instance.defaults.baseURL) {
    case prod:
      return "live";
    default:
      return "sandbox";
  }
};

export const setAuthHeader = async (): Promise<void> => {
  try {
    const accessToken = await getPaciamJwt();
    paciamInstance.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    iamInstance.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    instance.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
    brokerAPIInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  } catch (err) {
    window.location.href = "/login";
  }
};

export const setCorrespondentHeader = (id: string, env: string): void => {
  const isSandbox = env === "sandbox";

  // Not all api require x-correspondent tag in the header
  if (id) {
    iamInstance.defaults.headers["X-Correspondent"] = btoa(
      JSON.stringify({ ID: id, Sandbox: isSandbox })
    );
  }

  instance.defaults.headers["X-Correspondent"] = btoa(
    JSON.stringify({ ID: id, Sandbox: isSandbox })
  );
  brokerAPIInstance.defaults.headers["X-Correspondent"] = btoa(
    JSON.stringify({ ID: id, Sandbox: isSandbox })
  );
  paciamInstance.defaults.headers["X-Correspondent"] = btoa(
    JSON.stringify({ ID: id, Sandbox: isSandbox })
  );
};

export const getAccounts = async (
  params: AccountsParams = { firmAccounts: false }
): Promise<Account[]> => {
  let queryParams = `?firm_accounts=${Boolean(params.firmAccounts)}`;
  queryParams += "&entities=contact,identity";

  for (const key of Object.keys(params)) {
    if (key === "status") {
      if (params.status !== "All") queryParams += `&status=${params.status}`;
    } else if (key === "query") {
      const tokens = (params.query || "").split(",");
      queryParams += `&query=${tokens.join(" OR ")}`;
    } else {
      queryParams += `&${key}=${(params as Generic)[key]}`;
    }
  }

  const res = await instance.get<Account[]>(`/accounts${queryParams}`);

  // convert dates
  return res.data.map((a: Account) => {
    a.created_at = moment(a.created_at);
    a.email = a.contact?.email_address || "";
    a.name = "";
    if (a.identity)
      a.name = `${a.identity?.given_name} ${a.identity?.family_name}`;
    return a;
  });
};

export const getActivities = async (
  params: ListActivitiesParams = {},
  type?: string
): Promise<Activity[]> => {
  const res = await instance.get<Activity[]>(
    `/accounts/activities/${type ?? ""}`,
    { params }
  );

  return res.data.map((a) => {
    if (a.transaction_time) {
      a.transaction_time = moment(a.transaction_time);
    }
    return a;
  });
};

export const getPositions = async (
  params: PositionsQuery,
  accountID: string
): Promise<Position[]> => {
  if (Array.isArray(params.symbols)) {
    params.symbols = params.symbols.join(",");
  }

  const res = await instance.get<Position[]>(
    `trading/accounts/${accountID}/positions`,
    { params }
  );
  return res.data;
};

export const getAggregatePositions = async (): Promise<AggregatePosition[]> => {
  const res = await instance.get<GetAggregatePositionsResponse>(
    `reporting/eod/aggregate_positions?symbolInfo=true`
  );
  const resp = res.data.positions;
  const symbolInfo = res.data.symbol_info;
  // consolidate data
  for (let i = 0; i < res.data.positions.length; i++) {
    const { status, tradable, close_price_date } = symbolInfo[i];
    resp[i] = { ...resp[i], status, tradable, close_price_date };
  }

  return resp;
};

export const getOrders = async (
  params: OrdersParams = {},
  accountID?: string
): Promise<Order[]> => {
  if (Array.isArray(params.symbols)) {
    params.symbols = params.symbols.join(",");
  }

  if (params.side === "all") {
    delete params.side;
  }

  let res;
  if (accountID) {
    res = await instance.get<Order[]>(`trading/accounts/${accountID}/orders`, {
      params,
    });
  } else {
    res = await instance.get<Order[]>("/orders", { params });
  }

  return res.data.map((order) => {
    if (order.submitted_at) order.submitted_at = moment(order.submitted_at);
    if (order.created_at) order.created_at = moment(order.created_at);
    if (order.updated_at) {
      order.updated_at = moment(order.updated_at);
      order.formatted_updated_at = formatDate(
        order.updated_at.toDate(),
        "YYYY-MM-DD hh:mm:ss A z"
      );
    }
    if (order.filled_at) order.filled_at = moment(order.filled_at);
    if (order.expired_at) order.expired_at = moment(order.expired_at);
    if (order.expires_at) order.expires_at = moment(order.expires_at);
    if (order.cancelled_at) order.cancelled_at = moment(order.cancelled_at);
    if (order.failed_at) order.failed_at = moment(order.failed_at);

    order.account_id = order.account_id || "";

    // we only want to display the notional value of the order if the avg qty and price are available
    order.notional = undefined;
    if (order.filled_qty && order.filled_avg_price) {
      order.notional = order.filled_qty * order.filled_avg_price;
    }

    return order;
  });
};

export const getTransfers = async (
  params: TransfersParams = {},
  accountID?: string
): Promise<Transfer[]> => {
  // remove `all` selections
  for (const key of Object.keys(params)) {
    if ((params as Generic)[key] === "all") {
      delete (params as Generic)[key];
    }
  }

  let res;
  if (accountID) {
    res = await instance.get<Transfer[]>(`/accounts/${accountID}/transfers`, {
      params,
    });
  } else {
    res = await instance.get<Transfer[]>("/transfers", { params });
  }

  return res.data.map((transfer) => {
    if (transfer.created_at) transfer.created_at = moment(transfer.created_at);
    if (transfer.updated_at) transfer.updated_at = moment(transfer.updated_at);
    if (transfer.expires_at) transfer.expires_at = moment(transfer.expires_at);
    return transfer;
  });
};

export const getStats = async (): Promise<Stats> => {
  const res = await instance.get<Stats>("/stats");
  Object.entries(res.data.accounts_created_30d).map((entry) => {
    res.data.accounts_created_30d[entry[0]] = parseInt(entry[1] as string);
  });
  return res.data;
};

export const checkSeedEnv = async (): Promise<boolean> => {
  try {
    await instance.get("/check-populate-env");
    return true;
  } catch {
    return false;
  }
};

export const checkResetEnv = async (): Promise<boolean> => {
  try {
    await instance.get("/check-reset-env");
    return true;
  } catch {
    return false;
  }
};

export const seedEnv = async (): Promise<void> => {
  await instance.post("/populate-env");
};

export const getFakeAccount = async (): Promise<Account> => {
  const res = await instance.get("/fake-account");
  return res.data;
};

export const resetEnv = async (): Promise<void> => {
  await instance.post("/reset-env");
};

export const createApiKey = async (): Promise<ApiKey> => {
  const res = await instance.post<ApiKey>("/api-keys");
  res.data.created_at = moment(res.data.created_at);
  return res.data;
};

export const fetchKeys = async (): Promise<ApiKey[]> => {
  const res = await instance.get<ApiKey[]>("/api-keys");
  return res.data.map((key) => {
    key.created_at = moment(key.created_at);
    return key;
  });
};

export const disableKey = async (keyId: string): Promise<void> => {
  await instance.delete(`/api-keys/${keyId}`);
};

export const getAccount = async (accountID: string): Promise<Account> => {
  const res = await instance.get<Account>(`/accounts/${accountID}`);
  return res.data;
};

export const getDocuments = async (
  params: DocumentsParams,
  accountID?: string
): Promise<Document[]> => {
  let res;
  if (accountID) {
    res = await instance.get<Document[]>(`/accounts/${accountID}/documents`, {
      params,
    });
  } else {
    res = await instance.get<Document[]>("/documents", { params });
  }
  return res.data;
};

export const getUploadedDocuments = async (
  accountID: string,
  params: DocumentsParams
): Promise<UploadedDocument[]> => {
  const response = await instance.get<UploadedDocument[]>(
    `/documents/accounts/${accountID}`,
    { params }
  );

  return response.data;
};

export const docDownloadLink = async (
  params: DocumentDownloadParams
): Promise<void> => {
  await instance
    .get(
      `/accounts/${params.account_id}/documents/${params.document_id}/download?redirect=${params.redirect}`,
      {
        headers: { Accept: "application/pdf" },
      }
    )
    .then((res) => {
      window.open(res.data);
    });
};

export const getJournals = async (
  params: JournalsParams
): Promise<Journal[]> => {
  if (params.status === "all") {
    delete params.status;
  }

  const res = await instance.get<Journal[]>("/journals", { params });
  return res.data.map((journal) => {
    if (journal.settle_date) journal.settle_date = moment(journal.settle_date);
    if (journal.system_date) journal.system_date = moment(journal.system_date);
    return journal;
  });
};

export const getSelf = async (): Promise<TeamMember> => {
  const res = await iamInstance.get<TeamMember>(`/users/self`);
  return res.data;
};

export const getIntercomUserHash = async (): Promise<string> => {
  const res = await iamInstance.get<IntercomUserHashResponse>(
    "/users/intercom-id"
  );
  return res.data?.intercom_id ?? "";
};

export const getTeamMembers = async (): Promise<TeamMember[]> => {
  const res = await iamInstance.get<TeamMember[]>(`/users`);
  return res.data;
};

export const getTeamMember = async (userID: string): Promise<TeamMember> => {
  const res = await iamInstance.get<TeamMember>(`/users/${userID}`);
  const member = res.data;
  member.created_at = moment(member.created_at);
  member.updated_at = moment(member.updated_at);
  return member;
};

export const inviteTeamMember = async (
  payload: AddTeamMemberRequest
): Promise<void> => {
  await iamInstance.post<TeamMember>(`/users/invite`, payload);
};

export const claimInvite = async (
  req: ClaimInviteRequest
): Promise<TeamMember> => {
  const res = await iamInstance.patch<TeamMember>(`/users`, req);
  return res.data;
};

export const createAccount = async (
  req: RegistrationRequest
): Promise<TeamMember> => {
  const res = await iamInstance.post<TeamMember>(`/users`, {
    ...req,
    email: req.email.toLowerCase(),
  });
  return res.data;
};

export const closeAccount = async (accountID: string): Promise<void> => {
  try {
    await brokerAPIInstance.post(`/accounts/${accountID}/actions/close`);
  } catch (error) {
    // We want to display detailed error message, not just "Failed" with a status code
    throw new Error(error?.response?.data?.message || "Request failed");
  }
};

export const editTeamMember = async (
  userID: string,
  name: string,
  role: string
): Promise<TeamMember> => {
  const req: EditTeamMemberRequest = { name, role };
  const res = await iamInstance.patch<TeamMember>(`/users/${userID}`, req);
  return res.data;
};

export const removeTeamMember = async (userID: string): Promise<void> => {
  await iamInstance.delete<TeamMember>(`/users/${userID}/permission`);
};

export const createSandboxCorrespondent = async (
  name: string
): Promise<CorrespondentResponse> => {
  const res = await iamInstance.post<CorrespondentResponse>("/correspondents", {
    name,
  });

  // force a refresh of the access token so that future requests in
  // this session include the correspondent code in the claims
  const paciamRefreshToken = getCookie("paciamRefreshToken");
  await refreshPaciamToken(paciamRefreshToken);

  return res.data;
};

export const updateCorrespondent = async (
  corr: Correspondent
): Promise<CorrespondentResponse> => {
  const req: UpdateCorrespondentRequest = {
    business_type: corr.BusinessType as BusinessType,
    name: corr.Name,
    user_countries: corr.UserCountries,
    bd_data: corr.BdData,
  };
  await iamInstance.patch<CorrespondentResponse>("/correspondents", req);
  const res = await instance.patch<CorrespondentResponse>(
    "/correspondent",
    req
  );
  return res.data;
};

export const patchCorrespondent = async (
  payload: PatchCorrespondentRequest
): Promise<void> => {
  await iamInstance.patch("/correspondents", payload);
  await instance.patch("/correspondent", payload);
};

export const getCorrespondent = async (): Promise<CorrespondentResponse> => {
  const res = await instance.get<CorrespondentResponse>("/correspondent");
  return res.data;
};

export const patchCorrespondentConfig = async (
  payload: PatchCorrespondentConfigRequest
): Promise<void> => {
  await instance.patch("/correspondent/configuration", payload);
};

export const brokerAPIRequest = async <T>(
  method: string,
  path: string,
  data?: string
): Promise<T> => {
  let url = path;
  if (path.startsWith("/v1")) {
    url = path.substring(3);
  }
  const m: Method = method as Method;
  const res = await brokerAPIInstance.request<T>({
    method: m,
    url: url,
    data: data,
  });
  return res.data;
};

export const getTradingAccount = async (
  accountID: string
): Promise<TradingAccount> =>
  brokerAPIRequest("GET", `/v1/trading/accounts/${accountID}/account`);

export const getMarginAccount = async (
  accountID: string
): Promise<TradingAccount> => {
  const response = await instance.get<TradingAccount>(
    `/trading/accounts/${accountID}/margin`
  );
  return response.data;
};

// Entity details and control persons should only exist in Production

export const getEntityDetails = async (): Promise<GetEntityAndControlPersonResponse> => {
  const res = await asProd(
    instance.get<GetEntityAndControlPersonResponse>(`/entity_details`)
  );
  return res.data;
};

export const createEntityDetails = async (
  req: CreateEntityDetailsRequest
): Promise<EntityDetailsResponse> => {
  const res = await asProd(
    instance.post<EntityDetailsResponse>(`/entities`, req)
  );
  return res.data;
};

export const patchEntityDetails = async (
  payload: PatchEntityDetailsRequest
): Promise<EntityDetailsResponse> => {
  const res = await asProd(instance.patch(`/entity_details`, payload));
  return res.data;
};

export const createControlPerson = async (
  req: CreateControlPersonRequest
): Promise<CreateControlPersonResponse> => {
  const res = await asProd(
    instance.post<CreateControlPersonResponse>(`/control_persons`, req)
  );
  return res.data;
};

export const createZendeskTicket = async (
  req: CreateZendeskTicketRequest
): Promise<CreateZendeskTicketResponse> => {
  const res = await zendeskInstance.post<CreateZendeskTicketResponse>(
    "/requests",
    req
  );
  return res.data;
};

export const uploadZendeskTicketAttachment = async (
  file: File
): Promise<UploadZendeskTicketAttachmentResponse> => {
  const res = await zendeskInstance.post<UploadZendeskTicketAttachmentResponse>(
    "/uploads.json?filename=" + file.name,
    file,
    {
      headers: {
        "Content-Type": "application/binary",
      },
    }
  );
  return res.data;
};

export const getCountryRisk = async (): Promise<CountryInfo> => {
  const res = await instance.get<CountryInfo>("/country-infos");
  return res.data;
};

export interface ListCorporateActionsParams {
  process_date_since?: string;
  process_date_until?: string;
  symbols?: string[];
  ca_types?: string;
  include_announcement?: boolean;
}

export interface ListCorporateActionsResponse {
  cas: RawCorporateAction[];
}

export const getCorporateActions = async (
  params: ListCorporateActionsParams
): Promise<ListCorporateActionsResponse> => {
  const res = await instance.get<ListCorporateActionsResponse>("/cas/list", {
    params,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });

  return res.data;
};

export interface ListCryptoSpreadParams {
  start?: string;
  end?: string;
}

export const listCryptoSpread = async (
  params: ListCryptoSpreadParams
): Promise<Report[]> => {
  const res = await instance.get("/reports/crypto-spread", { params });
  return res.data;
};

export const downloadCryptoSpread = async (
  params: ListCryptoSpreadParams
): Promise<AxiosResponse<Blob>> => {
  return instance.get("/reports/crypto-spread/download", {
    params,
  });
};

export const getCorrespondentIPAllowlist = async (
  ingress: string
): Promise<IPAllowlist> => {
  const res = await instance.get<IPAllowlist>(
    `/correspondent/allowed-cidrs?ingress=${ingress}`
  );
  return res.data;
};
