/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from "react";

import { Text } from "@chakra-ui/react";
import { Table } from "@alpacahq/alpaca-component-library";

import type { Cell, Column } from "react-table";
import { fmtCellValue, formatDateObject } from "../../globals/utils";
import { CorporateAction } from "./types";

interface CorporateActionTableProps {
  data: CorporateAction[];
  isLoading: boolean;
  type: string;
}

const BASE_ORDER = ["id", "processDate", "entryDate"];

const STOCK_DIVIDEND_ORDER = [
  "processDate",
  "symbol",
  "cusip",
  "rate",
  "exDate",
  "recordDate",
  "payableDate",
];

const CASH_DIVIDEND_ORDER = [
  "processDate",
  "symbol",
  "cusip",
  "rate",
  "exDate",
  "recordDate",
  "payableDate",
  "special",
  "foreign",
  "dueBillOnDate",
  "dueBillOffDate",
];

const STOCK_MERGER_ORDER = [
  "processDate",
  "acquirerSymbol",
  "acquirerCusip",
  "acquirerRate",
  "acquireeSymbol",
  "acquireeCusip",
  "acquireeRate",
  "effectiveDate",
  "payableDate",
];

const CASH_MERGER_ORDER = [
  "processDate",
  "acquirerSymbol",
  "acquirerCusip",
  "acquireeSymbol",
  "acquireeCusip",
  "effectiveDate",
  "payableDate",
  "rate",
];

const STOCK_AND_CASH_MERGER_ORDER = [
  "processDate",
  "acquirerSymbol",
  "acquirerCusip",
  "acquirerRate",
  "acquireeSymbol",
  "acquireeCusip",
  "acquireeRate",
  "effectiveDate",
  "payableDate",
  "cashRate",
];

const FORWARD_SPLIT_ORDER = [
  "processDate",
  "symbol",
  "cusip",
  "newRate",
  "oldRate",
  "exDate",
  "recordDate",
  "payableDate",
  "dueBillRedemptionDate",
];

const REVERSE_SPLIT_ORDER = [
  "processDate",
  "symbol",
  "newCusip",
  "oldCusip",
  "newRate",
  "oldRate",
  "exDate",
  "recordDate",
  "payableDate",
];

const UNIT_SPLIT_ORDER = [
  "processDate",
  "oldSymbol",
  "oldCusip",
  "oldRate",
  "newSymbol",
  "newCusip",
  "newRate",
  "alternateSymbol",
  "alternateCusip",
  "alternateRate",
  "effectiveDate",
  "payableDate",
];

const SPINOFF_ORDER = [
  "processDate",
  "sourceSymbol",
  "sourceCusip",
  "sourceRate",
  "sourcePrice",
  "newSymbol",
  "newCusip",
  "newRate",
  "newPrice",
  "exDate",
  "recordDate",
  "payableDate",
  "dueBillRedemptionDate",
];

const NAME_CHANGE = [
  "processDate",
  "status",
  "oldSymbol",
  "oldCusip",
  "newSymbol",
  "newCusip",
];

const COLUMNS: Column<CorporateAction>[] = [
  {
    id: "processDate",
    Header: "Process Date",
    accessor: "processDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.processDate)}</Text>
    ),
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.description)}</Text>
    ),
  },
  {
    id: "symbol",
    Header: "symbol",
    accessor: "symbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.symbol)}</Text>
    ),
  },
  {
    id: "cusip",
    Header: "cusip",
    accessor: "cusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.cusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "rate",
    Header: "Rate",
    accessor: "rate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.rate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "exDate",
    Header: "Ex Date",
    accessor: "exDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.exDate)}</Text>
    ),
  },
  {
    id: "recordDate",
    Header: "Record Date",
    accessor: "recordDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.recordDate)}</Text>
    ),
  },
  {
    id: "payableDate",
    Header: "Payable Date",
    accessor: "payableDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.payableDate)}</Text>
    ),
  },
  {
    id: "special",
    Header: "Special Dividend",
    accessor: "special",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.special ? "Yes" : "No")}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "foreign",
    Header: "Foreign",
    accessor: "foreign",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.foreign ? "Yes" : "No")}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "dueBillOnDate",
    Header: "Due Bill On Date",
    accessor: "dueBillOnDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.dueBillOnDate)}</Text>
    ),
  },
  {
    id: "dueBillOffDate",
    Header: "Due Bill Off Date",
    accessor: "dueBillOffDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.dueBillOffDate)}</Text>
    ),
  },
  {
    id: "acquirerSymbol",
    Header: "Acquirer Symbol",
    accessor: "acquirerSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquirerSymbol)}</Text>
    ),
  },
  {
    id: "acquirerCusip",
    Header: "Acquirer Cusip",
    accessor: "acquirerCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquirerCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "acquireeSymbol",
    Header: "Acquiree Symbol",
    accessor: "acquireeSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquireeSymbol)}</Text>
    ),
  },
  {
    id: "acquireeCusip",
    Header: "Acquiree Cusip",
    accessor: "acquireeCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquireeCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "effectiveDate",
    Header: "Effective Date",
    accessor: "effectiveDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.effectiveDate)}</Text>
    ),
  },
  {
    id: "acquirerRate",
    Header: "Acquirer Rate",
    accessor: "acquirerRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquirerRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "acquireeRate",
    Header: "Acquiree Rate",
    accessor: "acquireeRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.acquireeRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "cashRate",
    Header: "Cash Rate",
    accessor: "cashRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.cashRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "newRate",
    Header: "New Rate",
    accessor: "newRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.newRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "oldRate",
    Header: "Old Rate",
    accessor: "oldRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.oldRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "dueBillRedemptionDate",
    Header: "Due Bill Redemption Date",
    accessor: "dueBillRedemptionDate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{formatDateObject(row.original.dueBillRedemptionDate)}</Text>
    ),
  },
  {
    id: "newCusip",
    Header: "New Cusip",
    accessor: "newCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.newCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "oldCusip",
    Header: "Old Cusip",
    accessor: "oldCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.oldCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "oldSymbol",
    Header: "Old Symbol",
    accessor: "oldSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.oldSymbol)}</Text>
    ),
  },
  {
    id: "newSymbol",
    Header: "New Symbol",
    accessor: "newSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.newSymbol)}</Text>
    ),
  },
  {
    id: "alternateSymbol",
    Header: "Alternate Symbol",
    accessor: "alternateSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.alternateSymbol)}</Text>
    ),
  },
  {
    id: "alternateCusip",
    Header: "Alternate Cusip",
    accessor: "alternateCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.alternateCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "alternateRate",
    Header: "Alternate Rate",
    accessor: "alternateRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.alternateRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "sourceSymbol",
    Header: "Source Symbol",
    accessor: "sourceSymbol",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.sourceSymbol)}</Text>
    ),
  },
  {
    id: "sourceCusip",
    Header: "Source Cusip",
    accessor: "sourceCusip",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.sourceCusip)}</Text>
    ),
    // @ts-ignore: disableSortBy prop is not typed
    disableSortBy: true,
  },
  {
    id: "sourceRate",
    Header: "Source Rate",
    accessor: "sourceRate",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.sourceRate)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "sourcePrice",
    Header: "Source Price",
    accessor: "sourcePrice",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.sourcePrice)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
  {
    id: "newPrice",
    Header: "New Price",
    accessor: "newPrice",
    Cell: ({ row }: Cell<CorporateAction>) => (
      <Text>{fmtCellValue(row.original.newPrice)}</Text>
    ),
    // @ts-ignore: sortType prop is not typed
    sortType: "number",
  },
];

const SEARCH_FILTER_COLUMNS = [
  "acquireeSymbol",
  "acquireeCusip",
  "acquirerSymbol",
  "acquirerCusip",
  "cusip",
  "newCusip",
  "newSymbol",
  "oldCusip",
  "oldSymbol",
  "symbol",
  "sourceCusip",
  "sourceSymbol",
];

function getColumnOrder(type: string) {
  switch (type) {
    case "stockDividend":
      return STOCK_DIVIDEND_ORDER;
    case "cashDividend":
      return CASH_DIVIDEND_ORDER;
    case "cashMerger":
      return CASH_MERGER_ORDER;
    case "stockMerger":
      return STOCK_MERGER_ORDER;
    case "stockAndCashMerger":
      return STOCK_AND_CASH_MERGER_ORDER;
    case "forwardSplit":
      return FORWARD_SPLIT_ORDER;
    case "reverseSplit":
      return REVERSE_SPLIT_ORDER;
    case "unitSplit":
      return UNIT_SPLIT_ORDER;
    case "spinOff":
      return SPINOFF_ORDER;
    case "nameChange":
      return NAME_CHANGE;
    default:
      return BASE_ORDER;
  }
}

const getColumnsByType = (type: string) => {
  const order = getColumnOrder(type);
  return COLUMNS.filter((column) => order.includes(column.id ?? "")).sort(
    (a, b) => order.indexOf(a.id ?? "") - order.indexOf(b.id ?? "")
  );
};

const CorporateActionTable = ({
  data,
  isLoading,
  type,
}: CorporateActionTableProps): React.ReactElement => {
  const columns = useMemo(() => getColumnsByType(type), [type]);

  return (
    <Table
      className="striped"
      columns={columns}
      data={data}
      defaultPerPage={25}
      filterGlobalColumns={SEARCH_FILTER_COLUMNS}
      isLoading={isLoading}
      pagination={true}
      paginationPosition="top"
      selectable={false}
    />
  );
};

export default CorporateActionTable;
