import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import CookieConsent from "./components/layout/CookieConsent";

ReactDOM.render(
  <>
    <ColorModeScript />
    <CookieConsent />
    <App />
  </>,
  document.getElementById("root")
);
