export const CORPORATE_ACTION_TYPES = [
  { type: "stockDividend", label: "Stock Dividend" },
  { type: "cashDividend", label: "Cash Dividend" },
  { type: "stockMerger", label: "Stock Merger" },
  { type: "cashMerger", label: "Cash Merger" },
  { type: "stockAndCashMerger", label: "Stock and Cash Merger" },
  { type: "forwardSplit", label: "Forward Split" },
  { type: "reverseSplit", label: "Reverse Split" },
  { type: "unitSplit", label: "Unit Split" },
  { type: "spinOff", label: "Spin-off" },
  { type: "nameChange", label: "Name Change" },
];
