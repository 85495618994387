import { useState, useEffect, Dispatch, SetStateAction } from "react";

const getValue = <T,>(key: string, defaultValue: T) => {
  try {
    return JSON.parse(localStorage.getItem(key) || String(defaultValue));
  } catch (error) {
    return defaultValue;
  }
};

const useLocalStorage = <T,>(
  key: string,
  defaultValue?: T,
  isLoading?: boolean
): [T, Dispatch<SetStateAction<T>>] => {
  const [isValueRetrieved, setIsValueRetrieved] = useState(false);
  const [value, setValue] = useState<T>(() => {
    // if something is preventing initial setting e.g. key is retrieved async
    // set default value for now
    if (isLoading) {
      return defaultValue;
    }

    setIsValueRetrieved(true);
    return getValue(key, defaultValue);
  });

  useEffect(() => {
    if (isLoading === undefined) {
      return;
    }

    if (!isLoading) {
      setIsValueRetrieved(true);
      setValue(getValue(key, defaultValue));
    }
  }, [isLoading]);

  useEffect(() => {
    // We have at least made an initial attempt to retrieve the local storage value and thus
    // safe to overwrite
    if (isValueRetrieved) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, key, isValueRetrieved]);

  return [value, setValue];
};

export default useLocalStorage;
