import joi from "joi";
import React, { useEffect, useContext } from "react";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";

import { AxiosError } from "axios";
import { joiResolver } from "@hookform/resolvers/joi";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

import { AppContext } from "../../globals/appcontext";
import { PatchCorrespondentConfigRequest } from "../../api/types";
import { patchCorrespondentConfig, getCorrespondent } from "../../api/api";
import { EventType, getAmplitude } from "../../globals/amplitude";

const FORM_WIDTH = "350px";

const INITIAL_FORM_VALUES = {
  crypto_enabled: false,
  jnlc_daily_limit: "10000",
  jnlc_transaction_limit: "50",
  firm_acct_daily_transfer_limit: "50000",
};

const JNLC_DAILY_LIMITS = [
  { label: "$10,000", value: "10000" },
  { label: "$50,000", value: "50000" },
  { label: "$250,000", value: "250000" },
  { label: "$500,000", value: "500000" },
  { label: "$1,000,000", value: "1000000" },
];

const JNLC_TRANSACTION_LIMITS = [
  { label: "$50", value: "50" },
  { label: "$5,000", value: "5000" },
  { label: "$10,000", value: "10000" },
  { label: "$50,000", value: "50000" },
  { label: "$100,000", value: "100000" },
];

const FIRM_ACCOUNT_DAILY_LIMITS = [
  { label: "$50,000", value: "50000" },
  { label: "$250,000", value: "250000" },
  { label: "$500,000", value: "500000" },
  { label: "$750,000", value: "750000" },
  { label: "$1,000,000", value: "1000000" },
];

const schema = joi.object({
  crypto_enabled: joi.boolean(),
  jnlc_daily_limit: joi.string().required(),
  jnlc_transaction_limit: joi.string().required(),
  firm_acct_daily_transfer_limit: joi.string().required(),
});

const SandboxConfigurations = (): JSX.Element => {
  const appContext = useContext(AppContext);

  const {
    data: correspondent,
    refetch: refetchCorrespondent,
  } = useQuery("correspondent", () => getCorrespondent());

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: joiResolver(schema),
    defaultValues: INITIAL_FORM_VALUES,
  });

  const toast = useToast();
  const mutation = useMutation<
    void,
    AxiosError,
    PatchCorrespondentConfigRequest
  >((payload) => patchCorrespondentConfig(payload), {
    onSuccess: () => {
      refetchCorrespondent();
      toast({
        title: "Sandbox configurations updated",
        status: "success",
      });
      getAmplitude().track({ event_type: EventType.TEAM_SETTINGS_UPDATED });
    },
    onError: () => {
      refetchCorrespondent();
      toast({
        title: "Unable to update sandbox configurations",
        status: "error",
      });
    },
  });

  const isFormDisabled = appContext.correspondent.Env !== "sandbox";

  const onSubmit = (formData: PatchCorrespondentConfigRequest) => {
    mutation.mutate(formData);
  };

  useEffect(() => {
    if (correspondent) {
      reset({
        crypto_enabled: correspondent.crypto_enabled,
        jnlc_daily_limit: correspondent.jnlc_daily_limit,
        jnlc_transaction_limit: correspondent.jnlc_transaction_limit,
        firm_acct_daily_transfer_limit:
          correspondent.firm_acct_daily_transfer_limit,
      });
    }
  }, [correspondent]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex width={FORM_WIDTH} flexDirection="column" gap={8}>
        <FormControl display="flex">
          <FormLabel marginBottom="0">Allow Crypto Trading</FormLabel>
          <Controller
            name="crypto_enabled"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                isChecked={field.value}
                disabled={isFormDisabled}
                onChange={(evt) => {
                  field.onChange(evt);
                  getAmplitude().track({
                    event_type: EventType.CRYPTO_ENABLED_CLICKED,
                    event_properties: { is_enabled: evt.target.checked },
                  });
                }}
              />
            )}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>JNLC Daily Transfer Limit</FormLabel>
          <Select
            {...register("jnlc_daily_limit")}
            variant="filled"
            disabled={isFormDisabled}
            onChange={(evt) => {
              register("jnlc_daily_limit").onChange(evt);
              getAmplitude().track({
                event_type: EventType.JNLC_DAILY_LIMIT_CLICKED,
                event_properties: { limit: evt.target.value },
              });
            }}
          >
            {JNLC_DAILY_LIMITS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.jnlc_daily_limit?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>JNLC Transaction Limit</FormLabel>
          <Select
            {...register("jnlc_transaction_limit")}
            variant="filled"
            disabled={isFormDisabled}
            onChange={(evt) => {
              register("jnlc_transaction_limit").onChange(evt);
              getAmplitude().track({
                event_type: EventType.JNLC_TRANSACTION_LIMIT_CLICKED,
                event_properties: { limit: evt.target.value },
              });
            }}
          >
            {JNLC_TRANSACTION_LIMITS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.jnlc_transaction_limit?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Firm Account Daily Transfer Limit</FormLabel>
          <Select
            {...register("firm_acct_daily_transfer_limit")}
            variant="filled"
            disabled={isFormDisabled}
            onChange={(evt) => {
              register("firm_acct_daily_transfer_limit").onChange(evt);
              getAmplitude().track({
                event_type: EventType.FIRM_TRANSFER_LIMIT_CLICKED,
                event_properties: { limit: evt.target.value },
              });
            }}
          >
            {FIRM_ACCOUNT_DAILY_LIMITS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.firm_acct_daily_transfer_limit?.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          mr="auto"
          type="submit"
          isLoading={mutation.isLoading}
          disabled={isFormDisabled || !isValid}
        >
          Update
        </Button>
      </Flex>
    </form>
  );
};

export default SandboxConfigurations;
