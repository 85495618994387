import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import colors from "../theme/colors";

export interface DisclosureModalProps {
  isOpen: boolean;
  closeModal: () => void;
  minWidth: string;
}

const LinkDisclosure = styled(NavLink)`
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.yellow};
  font-weight: "bold";
  margin-left: 2px;
`;

const DisclosureText = styled(Text)`
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 300;
  line-height: "1.83";
`;

const DisclosureModal = (props: DisclosureModalProps): React.ReactElement => {
  const { isOpen, closeModal, minWidth } = props;
  const AlpacaSecurities = `Alpaca Securities LLC ("Alpaca")`;
  const currentYear = new Date().getFullYear();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent fontFamily="Carnas" minWidth={minWidth}>
        <ModalHeader fontSize="30px" size="lg">
          Disclosures
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DisclosureText fontSize="sm">
            <DisclosureText>
              Brokerage services are provided by {AlpacaSecurities}, member
              <LinkDisclosure to="https://www.finra.org/">FINRA</LinkDisclosure>
              /<LinkDisclosure to="https://www.sipc.org/">SIPC</LinkDisclosure>,
              a wholly-owned subsidiary of AlpacaDB, Inc.
            </DisclosureText>
            <DisclosureText>
              Technology and services are offered by AlpacaDB, Inc.
            </DisclosureText>
            <DisclosureText>
              Brokerage services are provided to customers who can write
              automated investment code and self direct their own investments.
              Alpaca brokerage services are only provided to customers who agree
              to electronically sign agreements and agree to receive messages,
              confirmations, and statements electronically.
              <LinkDisclosure
                to="https://alpaca.markets/docs/about-us/#who-is-alpaca-for"
                rel="noopener noreferrer"
              >
                Is Alpaca right for me?
              </LinkDisclosure>
            </DisclosureText>
            <DisclosureText>
              This is not an offer, solicitation of an offer, or advice to buy
              or sell securities or cryptocurrencies, or open a brokerage
              account or cryptocurrency account in any jurisdiction where Alpaca
              Securities or Alpaca Crypto respectively, are not registered.
            </DisclosureText>
            <DisclosureText>
              The Paper Trading API is offered by AlpacaDB, Inc. and does not
              require real money or permit a user to transact in real securities
              in the market. Providing use of the Paper Trading API is not an
              not an offer or solicitation to buy or sell securities, securities
              derivative or futures products of any kind, or any type of trading
              or investment advice, recommendation or strategy, given or in any
              manner endorsed by AlpacaDB, Inc. or any AlpacaDB, Inc. affiliate
              information made available through the Paper Trading API is not an
              offer or solicitation of any kind in any jurisdiction where
              AlpacaDB, Inc. or any AlpacaDB, Inc. affiliate is not authorized
              authorized to do business.
            </DisclosureText>
            <DisclosureText>
              You should know that the use or granting of any third party access
              to your account information or place transactions in your account
              at your direction is solely at your risk. Alpaca does not warrant
              not warrant against loss of use or any direct, indirect or
              consequential damages or losses to you caused by your assent,
              expressed or implied, to a third party accessing your account or
              information, including access provided through any other third
              party apps, systems, or sites.
            </DisclosureText>
            <DisclosureText>
              Market prices, data and other information available through Alpaca
              are not warranted as to completeness or accuracy and are subject
              to change without notice.
              <em>
                &nbsp;System response and account access times may vary due to a
                variety of factors, including trading volumes, market
                conditions, system performance, and other factors. &nbsp;
              </em>
              A more complete description of the impact these factors may have
              can be found in our
              <LinkDisclosure to="https://alpaca.markets/learn/risks-of-automated-trading-systems/">
                risks of automated trading systems section
              </LinkDisclosure>
              .
            </DisclosureText>
            <DisclosureText>
              All investments involve risk and the past performance of a
              security, or financial product does not guarantee future results
              or returns. Keep in mind that while diversification may help
              spread risk it does not assure a profit, or protect against loss,
              in a down market. There is always the potential of losing money
              when you invest in securities, or other financial products.
              Investors should consider their investment objectives and risks
              carefully before investing.
            </DisclosureText>
            <DisclosureText>
              There are risks unique to automated trading algorithms that you
              should know about and plan for. You should setup a method or
              system of continuous monitoring or alerting to let you know if
              there is a mechanical failure, such as connectivity issues, power
              loss, a computer crash, or system quirk. You should also monitor
              for instances where your automated trading system experiences
              anomalies that could result in errant, missing, or duplicated
              orders. A more complete description of these and other risks can
              be found in our
              <LinkDisclosure to="https://alpaca.markets/learn/risks-of-automated-trading-systems/">
                FAQ section
              </LinkDisclosure>
              .
            </DisclosureText>
            <DisclosureText>
              Conditional orders may have increased risk as a result of their
              reliance on trigger processing, market data, and other internal
              and external systems. Such orders are not sent to the market until
              specified conditions are met. During that time, issues such as
              system outages with downstream technologies or third parties may
              occur. Conditional orders triggering near the market close may
              fail to execute that day. Furthermore, our executing partner may
              impose controls on conditional orders to limit erroneous trades
              triggering downstream orders. Alpaca Securities may not always be
              made aware of such changes to external controls immediately, which
              may lead to some conditional orders not being executed. As such,
              it is important to monitor conditional orders for reasonability.
              Conditional orders are “Not Held” orders whose execution
              instructions are on a best efforts basis upon being triggered.
              Furthermore, conditional orders may be subject to the increased
              risks of stop orders and market orders outlined above. Given the
              increased potential risk of using conditional orders, the client
              agrees that Alpaca Securities cannot be held responsible for
              losses, damages, or missed opportunity costs associated with
              market data problems, systems issues, and user error, among other
              factors. By using conditional orders the client understands and
              accepts the risks outlined above. Alpaca Securities encourages
              leveraging the use of Paper accounts to become more comfortable
              with the intricacies associated with these orders.
            </DisclosureText>
            <DisclosureText>
              ETFs can entail risks similar to direct stock ownership, including
              market, sector, or industry risks. Some ETFs may involve
              international risk, currency risk, commodity risk, and interest
              rate risk. Trading prices may not reflect the net asset value of
              the underlying securities.
            </DisclosureText>
            <DisclosureText>
              All accounts are opened as margin accounts. You should know that
              margin trading involves interest charges and risks, including the
              potential to lose more than deposited or the need to deposit
              additional collateral in a falling market. Before using margin,
              customers must determine whether this type of trading strategy is
              right for them given their specific investment objectives,
              experience, risk tolerance, and financial situation. For more
              information please see
              <LinkDisclosure to="https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf">
                Alpaca’s Margin Disclosure Statement&nbsp;
              </LinkDisclosure>
              and
              <LinkDisclosure to="https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf">
                Margin Agreement
              </LinkDisclosure>
              . These disclosures contain information on Alpaca’s lending
              policies, interest charges, and the risks associated with margin
              accounts.
            </DisclosureText>
            <DisclosureText>
              Commission-Free trading means that there are no commission charges
              for Alpaca self-directed individual cash brokerage accounts that
              trade U.S. listed securities through an API.
              <LinkDisclosure to="https://alpaca.markets/learn/regulatory-fees/">
                Relevant SEC and FINRA fees may apply.
              </LinkDisclosure>
            </DisclosureText>
            <DisclosureText>
              &copy; {currentYear} Alpaca Securities LLC. All rights reserved.
            </DisclosureText>
            <DisclosureText>
              &copy; {currentYear} AlpacaDB. All rights reserved.
            </DisclosureText>
          </DisclosureText>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DisclosureModal;
