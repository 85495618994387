import React from "react";

import { Session } from "../session";
import { Playground } from "../../../components/general/Playground";
import { Box, Button, Text } from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../../globals/amplitude";

const MilestoneThree = ({
  data,
  next,
  setLocalData,
}: Session): React.ReactElement => {
  const request = JSON.stringify({
    account_owner_name: `${data.account?.identity.given_name} ${data.account?.identity.family_name}`,
    bank_account_type: "CHECKING",
    bank_account_number: "32131231abc",
    bank_routing_number: "123103716",
    nickname: "Bank of America Checking",
  });

  const handleResponse = (response: { id: string }) => {
    if (response.id) {
      setLocalData({
        ...data,
        response: JSON.stringify(response),
        relationshipId: response.id,
        canMoveOn: true,
      });
    }
  };

  return (
    <Box maxW="1000px">
      <Text fontSize="xl" fontWeight="medium">
        Creating an ACH Relationship
      </Text>
      <Text mt=".5rem">
        In order to virtually fund an account via ACH we must first establish
        the ACH Relationship with the account.
      </Text>
      <Text mt=".5rem">
        We will be using the following endpoint{" "}
        <code>POST /v1/accounts/&#123;account_id&#125;/ach_relationships</code>
        &nbsp; replacing the <code>account_id</code> with <br />
        <code>{data.account?.id}</code>.
      </Text>
      <Text mt=".5rem" mb="2rem">
        Initially you will receive a <code>QUEUED</code> status. However, if you
        make a&nbsp;
        <code>
          GET/v1/accounts/&#123;account_id&#125;/ach_relationships
        </code>{" "}
        call after ~1 minute you should see an <code>APPROVED</code> status.
      </Text>
      <Playground
        key="milestone-three"
        method="POST"
        instant={true}
        readOnly={true}
        request={request}
        response={data.response}
        disabled={data.canMoveOn}
        onResponse={handleResponse}
        endpoint={`/v1/accounts/${data.account?.id}/ach_relationships`}
        step="step-3"
      />
      <Box textAlign="right" my="2rem">
        <Button
          disabled={!data.canMoveOn}
          onClick={() => {
            next();
            getAmplitude().track({
              event_type: EventType.QUICKSTART_STEP4,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MilestoneThree;
