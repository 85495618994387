import moment from "moment";
import UUID from "../general/UUID";

import React, { useEffect, useState } from "react";

import {
  dateRangeToPill,
  FilterButton,
  FilterDateSelect,
  FilterDrawer,
  FilterSelect,
  RangeType,
} from "../filter";

import { Column } from "react-table";
import { DownloadIcon } from "@chakra-ui/icons";
import { getUploadedDocuments } from "../../api/api";
import { QueryKey, useInfiniteQuery } from "react-query";
import { Table } from "@alpacahq/alpaca-component-library";
import { DocumentsParams, UploadedDocument } from "../../api/types";
import { Box, Flex, Spacer, Text, useToast } from "@chakra-ui/react";

const documentTypes = [
  "identity_verification",
  "address_verification",
  "date_of_birth_verification",
  "tax_id_verification",
  "account_approval_letter",
  "limited_trading_authorization",
  "w8ben",
];

const PAGE_SIZE = 25;

interface UploadedDocumentsTableProps {
  accountID: string;
}

const UploadedDocumentsTable = (
  props: UploadedDocumentsTableProps
): JSX.Element => {
  const { accountID } = props;

  const toast = useToast();
  const [filterOpen, setFilterOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState<string>("all");
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [applyFilter, setApplyFilter] = useState<boolean>(false);
  const [filterKey, setFilterKey] = useState<QueryKey>([
    "uploaded-documents",
    accountID,
  ]);

  const downloadDocument = async (url: string) => {
    if (url !== "") {
      window.open(url, "_blank");
    }
  };

  const removeFilter = (filterID: string) => {
    if (filterID === "type") setTypeFilter("all");
    if (filterID === "dates") setSelectedDates([null, null]);
  };

  const fetchDocuments = ({ pageParam = 0 }) => {
    const params: DocumentsParams = {
      type: typeFilter,
      limit: PAGE_SIZE,
      offset: pageParam * PAGE_SIZE,
    };

    if (selectedDates[0] && selectedDates[1]) {
      params.start = selectedDates[0].startOf("day").toISOString();
      params.end = selectedDates[1].endOf("day").toISOString();
    }

    return getUploadedDocuments(accountID, params);
  };

  const documentsQuery = useInfiniteQuery(filterKey, fetchDocuments, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === PAGE_SIZE) {
        return pages.length;
      }
    },
  });

  if (documentsQuery.isError) {
    toast({
      title: "An error occurred fetching documents",
      description: (documentsQuery.error as Error).message,
      status: "error",
    });
  }

  const documents = documentsQuery.data?.pages.flat() ?? [];

  const filterPills = {
    type: typeFilter,
    dates: dateRangeToPill(selectedDates),
  };

  const keys = ["uploaded-documents", typeFilter, selectedDates, accountID];

  const columns: Column<UploadedDocument>[] = [
    {
      Header: "ID",
      accessor: ({ id }) => <UUID value={id} />,
    },
    {
      Header: "Type",
      accessor: ({ doc_type }) => doc_type,
    },
    {
      Header: "Subtype",
      accessor: ({ doc_sub_type }) => doc_sub_type,
    },
    {
      Header: "Requested At",
      accessor: ({ requested_at }) => {
        if (!requested_at) return null;
        const date = moment(requested_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Uploaded At",
      accessor: ({ uploaded_at }) => {
        const date = moment(uploaded_at);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("hh:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: "Requested by Admin",
      accessor: ({ requested_by_admin }) => requested_by_admin?.name,
    },
    {
      Header: "Uploaded by Admin",
      accessor: ({ uploaded_by_admin }) => uploaded_by_admin?.name,
    },
    {
      Header: " ",
      accessor: ({ object_url }) => {
        const disabled = object_url === "";
        return (
          <DownloadIcon
            cursor="pointer"
            _hover={{ color: "blue.300" }}
            opacity={disabled ? "0.5" : "1"}
            pointerEvents={disabled ? "none" : "auto"}
            onClick={() => downloadDocument(object_url)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (applyFilter) setFilterKey(keys);
  }, [applyFilter, ...keys]);

  return (
    <Box>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Document Type"
          options={documentTypes}
          onSelect={setTypeFilter}
          selected={typeFilter}
        />
        <FilterDateSelect
          header="Date"
          onDateSelect={(startDate, endDate) =>
            setSelectedDates([startDate, endDate])
          }
        />
      </FilterDrawer>
      <Flex>
        <Spacer />
        <Box mt="-6.5rem">
          <FilterButton
            filterPills={applyFilter ? filterPills : {}}
            removeFilter={removeFilter}
            openFilter={() => {
              setFilterOpen(true);
              setApplyFilter(false);
            }}
          />
        </Box>
      </Flex>
      <Table
        columns={columns}
        data={documents}
        defaultPerPage={25}
        pagination
        paginationPosition="top"
      />
    </Box>
  );
};

export default UploadedDocumentsTable;
