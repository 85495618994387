import { useColorModeValue, Tab as ChakraTab, Box } from "@chakra-ui/react";
import React from "react";

interface TabProps {
  text: string;
  isSelected: boolean;
}

const Tab = (props: TabProps): React.ReactElement => {
  const textColor = useColorModeValue("black", "white");

  return (
    <ChakraTab _focus={{ boxShadow: "none" }}>
      <Box fontWeight={props.isSelected ? 600 : 500}>
        {props.text}
        <Box
          width={props.isSelected ? "50%" : "0%"}
          transition="width 0.15s"
          h="4px"
          bg={textColor}
          margin="auto"
          borderRadius="2px"
          mt="0.75rem"
        />
      </Box>
    </ChakraTab>
  );
};

export default Tab;
