import moment from "moment";
import NewUserForm from "../../components/user/NewUser";
import CopyButton from "../../components/general/CopyButton";

import React, { useState } from "react";

import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import { useQuery } from "react-query";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { Table } from "@alpacahq/alpaca-component-library";
import { EventType, getAmplitude } from "../../globals/amplitude";
import { Role } from "../../api/types";
import { getSelf, getTeamMembers } from "../../api/api";

import type { AxiosError } from "axios";
import type { TeamMember } from "../../api/types";
import type { Cell, Column } from "react-table";

interface OnFetchDataProps {
  pageIndex: number;
  pageSize: number;
}

const Members = (): React.ReactElement => {
  const toast = useToast();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

  const { data: authUser } = useQuery("self", () => getSelf());
  const { data: teamMembers = [], refetch: refetchTeamMembers } = useQuery<
    TeamMember[],
    AxiosError,
    TeamMember[]
  >("users", () => getTeamMembers(), {
    onError: (err) => {
      toast({
        title: "Unable to fetch team members",
        description: err.message,
        status: "error",
      });
    },
  });

  const isSuperUser = authUser?.role === Role.Superuser;

  const canEditRole = (role: string = Role.Operations) => {
    if (!authUser || !authUser.role) return false;
    return (
      authUser.role === Role.Superuser ||
      (authUser.role === Role.Developer && role !== Role.Superuser)
    );
  };

  const onFetchData = ({ pageIndex, pageSize }: OnFetchDataProps) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
  };

  const onCloseSidebar = (addAnother = false) => {
    setSidebarOpen(addAnother);
    setSelectedMember(null);
    refetchTeamMembers();
  };

  const onOpenSidebar = () => {
    if (isSuperUser) {
      setSidebarOpen(true);
      getAmplitude().track({
        event_type: EventType.ADD_TEAM_MEMBER_CLICKED,
      });
      getAmplitude().track({
        event_type: EventType.ADD_TEAM_MEMBER_STARTED,
        event_properties: {
          url: "https://broker-app.alpaca.markets/team-settings",
        },
      });
    }
  };

  const selectTeamMember = (member: TeamMember) => {
    if (!authUser || authUser.role === Role.Operations) {
      return;
    }

    if (member.id !== authUser.id) {
      setSidebarOpen(true);
      setSelectedMember(member);

      getAmplitude().track({
        event_type: EventType.VIEW_USER_PROFILE,
      });

      getAmplitude().track({
        event_type: EventType.EDIT_TEAM_MEMBER_STARTED,
        event_properties: {
          url: "https://broker-app.alpaca.markets/team-settings",
        },
      });
    }
  };

  const columns: Column<TeamMember>[] = [
    { Header: "Name", accessor: "name" },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => (
        <CopyButton lightColor="brand" name="Email" value={value} />
      ),
    },
    { Header: "Role", accessor: "role" },
    {
      Header: "Added At",
      accessor: "created_at",
      Cell: ({ value }: Cell<TeamMember>) => {
        const date = moment(value);
        return (
          <Flex>
            <Text>{date.format("YYYY-MM-DD")}</Text>
            <Text opacity={0.5}>&nbsp;{date.format("HH:mm a")}</Text>
          </Flex>
        );
      },
    },
    {
      Header: " ",
      Cell: ({ row }: Cell<TeamMember>) => (
        <EditIcon
          cursor="pointer"
          _hover={{ color: "blue.300" }}
          onClick={() => selectTeamMember(row.original)}
          visibility={canEditRole(row.original.role) ? "visible" : "hidden"}
        />
      ),
    },
  ];

  return (
    <Box>
      <NewUserForm
        isOpen={sidebarOpen}
        onClose={onCloseSidebar}
        selectedMember={selectedMember}
      />

      {isSuperUser && (
        <Flex flexDirection="row-reverse">
          <Button
            leftIcon={<AddIcon />}
            onClick={onOpenSidebar}
            variant="solid"
          >
            Add Team Member
          </Button>
        </Flex>
      )}

      <Table
        currentPage={page}
        columns={columns}
        data={teamMembers}
        defaultPerPage={pageSize}
        onFetchData={onFetchData}
        pagination
        paginationPosition="top"
      />
    </Box>
  );
};

export default Members;
