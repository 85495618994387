import React, { useEffect } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Spacer,
  Box,
} from "@chakra-ui/react";
import Table, { TableValue } from "../general/Table";
import { useState } from "react";
import Tab from "../general/Tab";
import {
  FilterDrawer,
  FilterDateSelect,
  FilterNumber,
  FilterSelect,
  FilterButton,
  rangeToPill,
  RangeType,
  NumRange,
  QueryKey,
  dateRangeToPill,
} from "../filter";
import ExportToCsv from "./ExportButton";
import { useInfiniteQuery } from "react-query";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import TransferDetails from "../page/TransferDetails";
import { getTransfers } from "../../api/api";
import { Transfer, TransfersParams } from "../../api/types";
import { txsToTableRows } from "../../globals/utils";
import { APILinks } from "../../globals/consts";
import { isEqual } from "lodash";

// Transfers per query
const QUERY_LIMIT = 100;
const BASE_CURRENCY = "USD";

const defaultParams: TransfersParams = { limit: 100, offset: 0 };

const TransactionsTable = (props: {
  accountID?: string;
}): React.ReactElement => {
  const [selectedTransfer, setSelectedTransfer] = useState<
    Transfer | undefined
  >();
  const aID = props.accountID;

  // Filters
  const [filterOpen, setFilterOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState("all");
  const [dirFilter, setDirFilter] = useState("all");
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [amountFilter, setAmountFilter] = useState<NumRange>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(["transfers", aID]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<TransfersParams>(
    defaultParams
  );
  const [allTransfers, setAllTransfers] = useState<
    (string | moment.Moment)[][]
  >([]);

  // Paginated query
  const fetchTransfers = ({ pageParam = 0 }) => {
    const tfParams: TransfersParams = {
      limit: QUERY_LIMIT,
      offset: pageParam * QUERY_LIMIT,
      transfer_type: typeFilter,
      direction: dirFilter,
    };

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      tfParams.updated_after = selectedDates[0].startOf("day").toISOString();
      tfParams.updated_before = selectedDates[1].endOf("day").toISOString();
    }

    // amount filter
    if (amountFilter) {
      if (amountFilter[0]) tfParams.amount_above = amountFilter[0];
      if (amountFilter[1]) tfParams.amount_below = amountFilter[1];
    }

    setAppliedFilters(tfParams);

    return getTransfers(tfParams, aID);
  };

  useEffect(() => {
    if (applyFilter) {
      setFilterKey([
        "transfers",
        typeFilter,
        dirFilter,
        selectedDates,
        amountFilter,
        aID,
      ]);
    }
  }, [typeFilter, dirFilter, selectedDates, amountFilter, applyFilter]);

  const transfersQuery = useInfiniteQuery(filterKey, fetchTransfers, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === QUERY_LIMIT) {
        return pages.length;
      }
    },
    onSuccess: (data) => {
      if (isEqual(appliedFilters, defaultParams)) {
        const transfers = data?.pages.flat() || [];
        const res = txsToTableRows(transfers, !aID, BASE_CURRENCY);
        setAllTransfers(res);
      }
    },
  });

  const transfers = transfersQuery.data?.pages.flat() || [];
  const txRows = txsToTableRows(transfers, !aID, BASE_CURRENCY);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  const removeFilter = (filterID: string) => {
    if (filterID === "type") setTypeFilter("all");
    if (filterID === "direction") setDirFilter("all");
    if (filterID === "amount") setAmountFilter([null, null]);
    if (filterID === "dates") setSelectedDates([null, null]);
  };

  const filterPills = {
    type: typeFilter,
    direction: dirFilter,
    dates: dateRangeToPill(selectedDates),
    amount: rangeToPill("amount", amountFilter),
  };

  let tableHeaders = [
    "Transaction ID",
    "Direction",
    "Type",
    "Amount",
    "Status",
    "Updated at",
  ];
  if (!aID) tableHeaders = ["Account ID", ...tableHeaders];

  const getTablePanel = (rows: TableValue[][]) => (
    <Table
      isLoading={transfersQuery.isLoading}
      headers={tableHeaders}
      copyIndexes={aID ? [0] : [0, 1]}
      rightJustifyIndexes={aID ? [3] : [4]}
      rows={rows}
      rowsPerPage={10}
      loadMoreData={transfersQuery.fetchNextPage}
      onRowClick={(idx) => setSelectedTransfer(transfers[idx])}
      onValueClick={(rowIdx, colIdx) => {
        if (colIdx === 0 && !aID) navigate(`/accounts/${rows[rowIdx][0]}`);
      }}
      noContentTitle="No transfers available"
      noContentLinkText="Learn more about Transactions"
      noContentLinkDestination={APILinks.transactions}
    />
  );

  const openFilter = () => {
    setFilterOpen(true);
    setApplyFilter(false);
  };

  const filterButton = aID ? (
    <Box mt="-6.5rem" float="right">
      <FilterButton
        filterPills={filterPills}
        removeFilter={removeFilter}
        openFilter={openFilter}
      />
    </Box>
  ) : (
    <Flex>
      <Spacer />
      <Box mb="1rem">
        <FilterButton
          filterPills={filterPills}
          removeFilter={removeFilter}
          openFilter={openFilter}
        />
      </Box>
    </Flex>
  );

  return (
    <>
      <TransferDetails
        isOpen={!!selectedTransfer}
        onClose={() => setSelectedTransfer(undefined)}
        transfer={selectedTransfer}
      />
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Transfer Type"
          options={["all", "ach", "wire"]}
          onSelect={setTypeFilter}
          selected={typeFilter}
        />
        <FilterSelect
          header="Direction"
          options={["all", "INCOMING", "OUTGOING"]}
          onSelect={setDirFilter}
          selected={dirFilter}
        />
        <FilterNumber
          header="Amount"
          minRange={0}
          maxRange={100000}
          step={100}
          onChange={setAmountFilter}
        />
        <FilterDateSelect
          header="Updated At"
          onDateSelect={(st, ed) => setSelectedDates([st, ed])}
        />
      </FilterDrawer>
      {(isMobile || aID) && filterButton}
      {aID ? (
        getTablePanel(txRows)
      ) : (
        <Tabs onChange={(idx) => setSelectedTab(idx)}>
          <Flex>
            <TabList>
              <Tab text="Recent" isSelected={selectedTab === 0} />
              <Tab text="Deposit" isSelected={selectedTab === 1} />
              <Tab text="Withdrawals" isSelected={selectedTab === 2} />
            </TabList>
            <Spacer />
            <Box mb="1rem" alignSelf="center">
              <ExportToCsv
                data={txRows}
                allData={allTransfers}
                givenHeaders={tableHeaders}
              />
            </Box>
            {!isMobile && filterButton}
          </Flex>
          <TabPanels>
            <TabPanel>{getTablePanel(txRows)}</TabPanel>
            <TabPanel>
              {getTablePanel(txRows.filter((tx) => tx[2] === "Incoming"))}
            </TabPanel>
            <TabPanel>
              {getTablePanel(txRows.filter((tx) => tx[2] === "Outgoing"))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default TransactionsTable;
