import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
  BoxProps,
  Flex,
} from "@chakra-ui/react";
import CopyButton from "../general/CopyButton";
import QRCode from "react-qr-code";
import { Navigate } from "react-router-dom";

import { enableMfa, verifyMfa } from "../../auth/paciam";
import { EventType, getAmplitude } from "../../globals/amplitude";

type TOTPContainerProps = {
  qrCode: string;
  submitMFA: (code: string) => Promise<void>;
};

type RecoveryCodeContainerProps = {
  cognitoId: string;
  setRedirect: (value: React.SetStateAction<boolean>) => void;
};

const MFA = (props: BoxProps): React.ReactElement => {
  const toast = useToast();

  const [setupTOTP, setSetupTOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [qrCode, setQrCode] = useState("");
  const showRecoveryCode = false;

  const generateTotpQrCode = async () => {
    enableMfa()
      .then((resp) => {
        setQrCode(resp.url || "");
        setSetupTOTP(true);
      })
      .catch((e) => {
        toast({
          title: "An error occured",
          description: e.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitCode = async (mfaCode: string) => {
    setIsLoading(true);

    verifyMfa(mfaCode)
      .then(() => {
        toast({
          title: "MFA Enabled",
          status: "success",
        });
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: "An error occured",
          description: e.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (redirect) {
    getAmplitude().track({
      event_type: EventType.SIGNUP_COMPLETED,
    });

    return <Navigate to="/dashboard" />;
  }

  return (
    <Box {...props}>
      <Center>
        {!setupTOTP && (
          <Box>
            <Text fontSize="4xl" mb="2rem">
              Enable Time-based, One-Time Password MFA? (Recommended)
            </Text>
            <Button
              size="lg"
              width="full"
              mt="4rem"
              onClick={() => {
                generateTotpQrCode();
              }}
              isLoading={isLoading}
            >
              Yes
            </Button>
            <Button
              size="md"
              variant="ghost"
              width="full"
              mt="4rem"
              onClick={() => setRedirect(true)}
            >
              Skip
            </Button>
          </Box>
        )}
        {setupTOTP && !showRecoveryCode && (
          <TOTPContainer qrCode={qrCode} submitMFA={submitCode} />
        )}
        {showRecoveryCode && (
          <RecoveryCodeContainer cognitoId="" setRedirect={setRedirect} />
        )}
      </Center>
    </Box>
  );
};

export const TOTPContainer = (props: TOTPContainerProps): JSX.Element => {
  const { qrCode, submitMFA } = props;

  const [code, setCode] = useState("");

  return (
    <Flex flexDir="column" alignItems="center" maxW="650px" textAlign="center">
      <Text fontSize="2xl" mb="2rem">
        Scan the QR code below using your preferred authenticator app and then
        enter the provided one-time code below
      </Text>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          submitMFA(code);
        }}
      >
        <Flex flexDir="column" alignItems="center">
          <FormControl isRequired mt={6}>
            <Flex
              display="flex"
              flexDir="column"
              alignItems="center"
              maxW="350px"
            >
              <QRCode value={qrCode} />
              <FormLabel mt="2rem">Enter your one-time code:</FormLabel>

              <Input
                variant="filled"
                size="lg"
                onChange={(event) => setCode(event.currentTarget.value)}
              />
            </Flex>
          </FormControl>
          <Button
            size="lg"
            type="submit"
            width="full"
            mt="4rem"
            isLoading={false}
          >
            Next
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

// TODO: need to re-implement recovery code logic into Paciam
export const RecoveryCodeContainer = (
  props: RecoveryCodeContainerProps
): JSX.Element => {
  return (
    <Flex flexDir="column" alignItems="center" maxW="640px" textAlign="center">
      <Text fontSize="2xl" mb="2rem">
        Thank you enabling MFA for your Alpaca account! Please save this
        recovery code somewhere safe in the event you lose access to your TOTP
        device
      </Text>
      <CopyButton isTruncated iconSide="left" value={""} />
      <Button
        size="lg"
        width="full"
        mt="4rem"
        onClick={() => props.setRedirect(true)}
      >
        Go to dashboard
      </Button>
    </Flex>
  );
};

export default MFA;
