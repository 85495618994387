import React from "react";
import Header from "../../components/layout/Header";
import colors from "../../components/theme/colors";

import Welcome from "./steps/Welcome";
import MilestoneOne from "./steps/MilestoneOne";
import MilestoneTwo from "./steps/MilestoneTwo";
import MilestoneThree from "./steps/MilestoneThree";
import MilestoneFour from "./steps/MilestoneFour";
import MilestoneFive from "./steps/MilestoneFive";
import Completed from "./steps/Completed";

import { CheckIcon } from "@chakra-ui/icons";
import { useSession } from "./session";
import {
  Box,
  Link,
  Center,
  Flex,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

const Icon = (active: number, render: number) => {
  // if the step being rendered is less than the active step, render a check
  const completed = render < active;

  // if the step being rendered is the active step, render a number
  const pending = render === active;

  // if the step is completed or pending highlight it yellow
  const highlight = completed || pending;

  const color = useColorModeValue("black", "white");
  const baseBg = useColorModeValue(colors.iconGrayBg, colors.iconDarkGrayBg);

  // styling conditionals
  const bg = highlight ? colors.iconYellowBg : baseBg;
  const opacity = highlight ? "1" : "0.6";
  const iconColor = highlight ? "black" : color;
  const iconLabelColor = highlight ? color : "inherit";

  return (
    <Flex alignItems="center" flexDir="column">
      <Center w="28px" h="28px" borderRadius="50%" bg={bg}>
        <Text opacity={opacity} color={iconColor}>
          {completed ? <CheckIcon width=".8em" height="1.5em" /> : render}
        </Text>
      </Center>
      <Text mt=".25rem" opacity={opacity} color={iconLabelColor}>
        Step {render}
      </Text>
    </Flex>
  );
};

const QuickStart = (): React.ReactElement => {
  // get the persistent quick-start session
  const session = useSession();

  // an array of the steps to render
  const steps = [
    Welcome,
    MilestoneOne,
    MilestoneTwo,
    MilestoneThree,
    MilestoneFour,
    MilestoneFive,
    Completed,
  ];

  // an array of icons to render
  const icons = steps
    .slice(0, -2)
    .map((_, index) => Icon(session.data.step, index + 1));

  // the current step to render
  const Step = steps[session.data.step];

  const handleReset = () => session.reset();

  return (
    <Box>
      <Header title="Welcome Back!" />
      <Text fontSize="xl" fontWeight="medium" maxWidth="1000px">
        Quick Start
        <Link
          ml="1rem"
          fontSize="md"
          textDecor="underline"
          href="https://alpaca.markets/docs/broker/get-started/"
          target="_blank"
        >
          Learn more
        </Link>
        <Button onClick={handleReset} variant="ghost" float="right">
          Reset
        </Button>
      </Text>
      <Box>
        <Flex justifyContent="space-between" my="2rem" maxW="500px">
          {icons}
        </Flex>
        {Step(session)}
      </Box>
    </Box>
  );
};

export default QuickStart;
