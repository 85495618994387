import React, { useEffect, useState } from "react";
import { ExternalLink, CreateTaskList } from "./constants";
import {
  Box,
  Text,
  Collapse,
  VStack,
  HStack,
  Button,
  Select,
  List,
  ListItem,
  Checkbox,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../globals/amplitude";

type LiveProps = {
  step: number;
  handleGuideComplete: () => void;
  readOnly: boolean;
  corr: string;
};

const PartnershipTypes: string[] = [
  "RIA (U.S)",
  "Investment Advisor (non - U.S)",
  "Broker-dealer",
  "Technology Partner",
];

const Live = ({
  step,
  handleGuideComplete,
  readOnly,
  corr,
}: LiveProps): React.ReactElement => {
  const storageKeyTasks = `launch-guide-live-tasks-${corr}`;
  const storageKeyPartnerType = `launch-guide-live-partner-type-${corr}`;

  const [partnerType, setPartnerType] = useState("RIA (U.S)");

  const [checkedValues, setCheckedValues] = useState<Array<number>>([]);
  const currList = CreateTaskList(partnerType);
  const toast = useToast();

  const handlePartnerTypeChange = (partnerType: string) => {
    setPartnerType(partnerType);
    setCheckedValues([]);
    localStorage.removeItem(storageKeyTasks);
    localStorage.removeItem(storageKeyPartnerType);
  };

  const trackPartnerTypeChange = (partnerType: string) => {
    const types = {
      [PartnershipTypes[0]]: EventType.LIVE_RIA,
      [PartnershipTypes[1]]: EventType.LIVE_INVESTMENT_ADVISOR,
      [PartnershipTypes[2]]: EventType.LIVE_BROKER_DEALER,
      [PartnershipTypes[3]]: EventType.LIVE_TECHNOLOGY_PARTNER,
    };

    getAmplitude().track({ event_type: types[partnerType] });
  };

  const handleLaunchGuideSave = () => {
    localStorage.setItem(storageKeyPartnerType, partnerType);
    localStorage.setItem(storageKeyTasks, JSON.stringify(checkedValues));
    getAmplitude().track({ event_type: EventType.LIVE_SAVE });
    toast({
      title: "Progress saved!",
      status: "success",
    });
  };

  const pageComplete = currList.length === checkedValues.length;

  useEffect(() => {
    if (pageComplete) {
      localStorage.setItem(storageKeyPartnerType, partnerType);
    }
  }, [pageComplete]);

  useEffect(() => {
    trackPartnerTypeChange(partnerType);
  }, [partnerType]);

  useEffect(() => {
    setCheckedValues(JSON.parse(localStorage.getItem(storageKeyTasks) || "[]"));
    const pTypeValue = localStorage.getItem(storageKeyPartnerType);
    if (pTypeValue) {
      setPartnerType(pTypeValue);
    }
  }, [storageKeyPartnerType]);

  return (
    <Box>
      <HStack spacing={5}>
        <Text fontSize="xl">Live Requirements</Text>
        {checkedValues.length > 0 && !readOnly && (
          <Text
            fontSize="lg"
            color="grey"
          >{`(${checkedValues.length} / ${currList.length} tasks completed)`}</Text>
        )}
      </HStack>
      <Collapse in={step === 3 || readOnly}>
        <VStack alignItems="start" pl="4px" spacing={8}>
          <VStack spacing={5} mt="1rem" alignItems="start">
            <Text>Your app is almost ready for launch!</Text>
            <Text>
              The Live environment is the final step and you are considered
              fully launched once you arrive here.
            </Text>
          </VStack>
          <Box mt="2rem" mb="0.5rem">
            <Text ml="1rem" color="grey">
              Type of Partnership
            </Text>
            <Select
              variant="filled"
              onChange={(event) => {
                handlePartnerTypeChange(event.currentTarget.value);
              }}
              value={partnerType}
              w="344px"
              disabled={readOnly}
            >
              {PartnershipTypes.map((val, idx) => (
                <option key={idx} value={val}>
                  {val}
                </option>
              ))}
            </Select>
            <Text ml="1rem" color="grey">
              For more information on types of partnerships read more{" "}
              <ExternalLink
                href="https://alpaca.markets/broker#use-cases"
                target="_blank"
              >
                here
              </ExternalLink>
              .
            </Text>
          </Box>
          <List spacing={3}>
            {currList.map((val, idx) => (
              <ListItem key={idx}>
                <Flex alignItems="start">
                  <Checkbox
                    isChecked={readOnly || checkedValues.includes(idx)}
                    onChange={() => {
                      if (readOnly) {
                        return;
                      }

                      setCheckedValues((existingItems) => {
                        if (existingItems.includes(idx)) {
                          getAmplitude().track({
                            event_type: EventType.LIVE_CHECKBOX_UNCHECKED,
                          });
                          return existingItems.filter((e) => e !== idx);
                        }

                        getAmplitude().track({
                          event_type: EventType.LIVE_CHECKBOX_CHECKED,
                        });
                        return [idx, ...existingItems];
                      });
                    }}
                    sx={{ "[data-checked]": { borderRadius: "100px" } }}
                    mt="3px"
                  />
                  <Box ml="1rem">
                    <Text fontSize="16px" mb="0.25rem">
                      {val.title}
                    </Text>
                    <Text color="#ffffff85">{val.subTitle}</Text>
                  </Box>
                </Flex>
              </ListItem>
            ))}
          </List>
          {!readOnly && (
            <Button
              onClick={
                pageComplete ? handleGuideComplete : handleLaunchGuideSave
              }
              mb="1rem"
            >
              {pageComplete ? "Complete" : "Save Progress"}
            </Button>
          )}
          <Text>
            If you do not fall into one of these types of partnerships or are
            not a fully-disclosed partner or there are multiple entities
            involved e.g. technology provider partnering with a broker dealer,
            reach out to{" "}
            <ExternalLink href="mailto:sales@alpaca.markets">
              {" "}
              sales@alpaca.markets{" "}
            </ExternalLink>
          </Text>
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Live;
