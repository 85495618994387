import React from "react";
import colors from "../../../components/theme/colors";

import { Session } from "../session";
import { getAmplitude, EventType } from "../../../globals/amplitude";

import {
  Alert,
  AlertIcon,
  Button,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

const Welcome = ({ next }: Session): React.ReactElement => {
  const bg = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );
  const iconBg = useColorModeValue(colors.iconDarkGrayBg, colors.iconGrayBg);

  return (
    <VStack spacing="2rem" align="start" maxWidth="1000px">
      <Alert status="error" bg={bg} opacity="0.75">
        <AlertIcon color={iconBg} />
        This guide is going to help you get setup on your sandbox environment
        and give you a quick walk through around using our Testing section.
      </Alert>
      <Text fontSize="md">
        The sandbox environment acts as a parallel environment where you can
        test our APIs safely without sending any real trades to the market. All
        prices, and execution times (i.e. market hours) hold true in sandbox and
        production. We&apos;ve added in the API calls and the request body to
        allow you to easily navigate through the guide.
      </Text>
      <Button
        onClick={() => {
          next();
          getAmplitude().track({ event_type: EventType.QUICKSTART_STEP1 });
        }}
      >
        Get Started
      </Button>
    </VStack>
  );
};

export default Welcome;
