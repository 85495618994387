import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Input,
  Alert,
  AlertIcon,
  Text,
  Link,
} from "@chakra-ui/react";
import { getSelf } from "../../api/api";
import {
  forgotPassword,
  resetSecret,
  verifyResetCode,
} from "../../auth/paciam";

const ERROR_MESSAGES = {
  "VerificationRequest.Code": "Validation for code failed.",
};
type ErrorKey = keyof typeof ERROR_MESSAGES;

const MFA_TYPES = ["totp"];

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal = (
  props: ChangePasswordProps
): React.ReactElement => {
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toast = useToast();
  const { data: self } = useQuery("self", () => getSelf());

  const changePassword = async () => {
    setLoading(true);
    verifyResetCode(email, code)
      .then((resp) => {
        resetSecret(resp.token, newPassword);
        props.onClose();
      })
      .catch((error) => {
        const raw = error?.response?.data?.message || "";
        const message =
          Object.keys(ERROR_MESSAGES)
            .filter((key) => raw.includes(key))
            .map((key) => ERROR_MESSAGES[key as ErrorKey])
            .join(" ") || error.message; // join or return the default message

        setError(message);
      });

    setLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      changePassword();
    }
  };

  const isMFAEnabled = MFA_TYPES.includes(self?.mfa_type ?? "");

  const handleRequestCode = () => {
    // Send code to email if user has MFA enabled
    if (self && isMFAEnabled) {
      setEmail(self.email);
      forgotPassword(self.email)
        .then(() => {
          toast({
            title:
              "Verification code send successfully. Please check your email.",
            status: "success",
          });
        })
        .catch(() => {
          toast({
            title: "Failed to send reset code",
            status: "error",
          });
        });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isMFAEnabled ? (
            <>
              <Text>
                Please <Link onClick={handleRequestCode}>click me</Link> to
                request verification code.
              </Text>
            </>
          ) : (
            <Text color="red">
              To update your password you must enable multi-factor
              authentication.
            </Text>
          )}
          <Input
            mt="1rem"
            variant="filled"
            placeholder="Code"
            size="lg"
            onChange={(event) => setCode(event.currentTarget.value)}
            autoComplete="new-code"
            required
            disabled={!isMFAEnabled}
          />
          <Input
            mt="1rem"
            variant="filled"
            type="password"
            placeholder="New password"
            size="lg"
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            autoComplete="new-password"
            required
            disabled={!isMFAEnabled}
          />
          {error && (
            <Alert status="error" mt="1rem">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outlined"
            mr={3}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={loading}
            onClick={changePassword}
            disabled={!isMFAEnabled}
          >
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
