import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";
import {
  Box,
  Text,
  Collapse,
  VStack,
  HStack,
  Flex,
  Checkbox,
  Button,
  Input,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";

import { LimitedLiveTasks } from "./constants";
import { getEntityDetails } from "../../api/api";
import { useForm, Controller } from "react-hook-form";
import { EventType, getAmplitude } from "../../globals/amplitude";
import useLocalStorage from "../hooks/useLocalStorage";

type LimitedLiveProps = {
  step: number;
  handleCompleteStatus: (index: number) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  readOnly: boolean;
  corr: string;
  isLoading: boolean;
};

type OptionalValues = {
  target_investor?: string;
  has_waitlist?: string;
  waitlist?: string;
};

type CheckboxItemProps = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  text: React.ReactNode;
};

// Tasks were saved based on their index in the LimitedLiveTasks array
// To ensure backwards compatibility, use "initialIndex" to save/retrieve
// tasks from localStorage

const DUE_DILIGENCE_TASKS = [
  {
    ...LimitedLiveTasks[1],
    initialIndex: 1,
  },
];

const TECH_SIGN_OFF_TASKS = [
  {
    ...LimitedLiveTasks[0],
    initialIndex: 0,
  },
  {
    ...LimitedLiveTasks[2],
    initialIndex: 2,
  },
  {
    ...LimitedLiveTasks[3],
    initialIndex: 3,
  },
];

const ONBOARDING_FLOW_TASKS = [
  {
    ...LimitedLiveTasks[4],
    initialIndex: 4,
  },
];

const CheckboxItem = ({
  isChecked,
  isDisabled,
  onChange,
  text,
}: CheckboxItemProps) => {
  return (
    <Flex alignItems="start">
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChange}
        mt="3px"
      />
      <Box ml="1rem">
        <Text mb="0.25rem">{text}</Text>
      </Box>
    </Flex>
  );
};

const LimitedLive = ({
  step,
  handleCompleteStatus,
  setStep,
  readOnly,
  corr,
  isLoading,
}: LimitedLiveProps): React.ReactElement => {
  const LAUNCH_GUIDE_LIMITED_LIVE_KEY = `launch-guide-limited-live-tasks-${corr}`;
  const LAUNCH_GUIDE_LIMITED_LIVE_OPTIONAL_KEY = `launch-guide-optional-${corr}`;

  const [goLiveComplete, setGoLiveComplete] = useState(false);
  const [checkedValues, setCheckedValues] = useLocalStorage<Array<number>>(
    LAUNCH_GUIDE_LIMITED_LIVE_KEY,
    [],
    isLoading
  );
  const [optionalValues, setOptionalValues] = useLocalStorage<OptionalValues>(
    LAUNCH_GUIDE_LIMITED_LIVE_OPTIONAL_KEY,
    {},
    isLoading
  );

  const { register, control, setValue, reset, watch } = useForm<OptionalValues>(
    {
      mode: "onChange",
    }
  );
  const values = watch();
  const hasWaitlist = watch("has_waitlist") === "yes";

  const handleLimitedLiveNext = () => {
    handleCompleteStatus(2);
    getAmplitude().track({ event_type: EventType.LIMITEDLIVE_COMPLETE });
    setStep(3);
  };

  useQuery(["entityDetails", goLiveComplete], () => getEntityDetails(), {
    enabled: !goLiveComplete,
    onSuccess: (data) => {
      if (data?.control_person.first_name) {
        setGoLiveComplete(true);
      }
    },
  });

  const pageComplete =
    LimitedLiveTasks.length === checkedValues.length && goLiveComplete;

  useEffect(() => {
    if (
      !isLoading &&
      goLiveComplete &&
      !checkedValues.includes(DUE_DILIGENCE_TASKS[0].initialIndex)
    ) {
      setCheckedValues((existingValues) => [1, ...existingValues]);
    }
  }, [checkedValues, goLiveComplete]);

  useEffect(() => {
    const optionalVals = JSON.parse(
      localStorage.getItem(LAUNCH_GUIDE_LIMITED_LIVE_OPTIONAL_KEY) || "{}"
    );
    reset({
      ...optionalVals,
    });
  }, [LAUNCH_GUIDE_LIMITED_LIVE_OPTIONAL_KEY, isLoading]);

  useEffect(() => {
    if (
      (Object.keys(values) as Array<keyof typeof values>).some(
        (key) => values[key] !== optionalValues[key]
      )
    ) {
      setOptionalValues(values);
    }
  }, [values, optionalValues]);

  const handleCheckboxChange = (index: number) => {
    if (readOnly) {
      return;
    }

    setCheckedValues((existingItems) => {
      if (existingItems.includes(index)) {
        getAmplitude().track({
          event_type: EventType.LIMITEDLIVE_CHECKBOX_UNCHECKED,
        });
        return existingItems.filter((e) => e !== index);
      }

      getAmplitude().track({
        event_type: EventType.LIMITEDLIVE_CHECKBOX_CHECKED,
      });
      return [index, ...existingItems];
    });
  };

  return (
    <Box>
      <HStack spacing={5}>
        <Text fontSize="xl">Limited Live Requirements</Text>
        {checkedValues.length > 0 && !readOnly && (
          <Text
            fontSize="lg"
            color="grey"
          >{`(${checkedValues.length} / ${LimitedLiveTasks.length} tasks completed)`}</Text>
        )}
      </HStack>
      <Collapse in={step === 2 || readOnly}>
        <VStack alignItems="start" spacing={8} pl="4px">
          <VStack spacing={5} mt="1rem" alignItems="start">
            <Text>
              Limited Live is close to feature parity with our live environment
              but there are some items that you can only really test once live,
              which is why we created Limited Live. In the Limited Live
              environment, you will be granted a production key to begin testing
              your app with real money and accounts. In this stage, you will use
              real money to test fund flows, execute buys and sells in a
              non-simulated environment, fix bugs, and prepare for launch!
            </Text>
            <Text>Limited Live allows for the following:</Text>
            <Text>• You will be able to open up to 35 internal accounts</Text>
            <Text>
              • Each with a maximum of USD 5000 buying power and a withdrawal
              limit of USD 2000
            </Text>
            <Text>
              • Alpaca conducts KYC and no shorting or extending of margin is
              permitted.
            </Text>
            <br />
            <Text>
              In this stage you will also go through the following important
              steps:
            </Text>
            <Text>
              1. Alpaca will conduct basic due diligence on the control person
              and legal entity.
            </Text>
            {DUE_DILIGENCE_TASKS.map(({ title, initialIndex }) => (
              <CheckboxItem
                key={initialIndex}
                isChecked={readOnly || checkedValues.includes(initialIndex)}
                isDisabled={!goLiveComplete}
                onChange={() => handleCheckboxChange(initialIndex)}
                text={title}
              />
            ))}
            <Text>
              2. There is an initial tech sign-off to ensure your app can hit
              all the key endpoints and perform all key functions. We&apos;ll
              check that the delivery of trade confirmations and monthly
              statements is setup.
            </Text>
            {TECH_SIGN_OFF_TASKS.map(({ title, initialIndex }) => (
              <CheckboxItem
                key={initialIndex}
                isChecked={readOnly || checkedValues.includes(initialIndex)}
                onChange={() => handleCheckboxChange(initialIndex)}
                text={title}
              />
            ))}
            <Text>
              3. Your onboarding flow and user experience will need to be
              approved by Alpaca.
            </Text>
            {ONBOARDING_FLOW_TASKS.map(({ title, initialIndex }) => (
              <CheckboxItem
                key={initialIndex}
                isChecked={readOnly || checkedValues.includes(initialIndex)}
                onChange={() => handleCheckboxChange(initialIndex)}
                text={title}
              />
            ))}
          </VStack>
          <VStack w="100%" alignItems="start">
            <Text>What type of investor are you targeting? (optional)</Text>
            <Input
              disabled={readOnly}
              variant="filled"
              {...register("target_investor")}
            />
          </VStack>
          <VStack w="100%" alignItems="start">
            <Text>
              Do you currently have a waitlist for your app? (optional)
            </Text>
            <Controller
              control={control}
              name="has_waitlist"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  value={value}
                  onChange={(value: string) => {
                    if (value === "no") {
                      setValue("waitlist", "");
                    }
                    onChange(value);
                  }}
                >
                  <HStack>
                    <Radio value="yes" isDisabled={readOnly}>
                      <Text fontWeight="medium" fontSize="15px">
                        Yes
                      </Text>
                    </Radio>
                    <Radio
                      value="no"
                      ml="20px !important"
                      isDisabled={readOnly}
                    >
                      <Text fontWeight="medium" fontSize="15px">
                        No
                      </Text>
                    </Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
            <Box w="100%" display={hasWaitlist ? "block" : "none"}>
              <Input
                mt="12px"
                disabled={readOnly}
                variant="filled"
                placeholder="eg: 10, 100, 1000"
                {...register("waitlist")}
              />
            </Box>
          </VStack>
          {!readOnly && (
            <Button
              disabled={!pageComplete}
              onClick={handleLimitedLiveNext}
              mb="1rem"
            >
              Next
            </Button>
          )}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default LimitedLive;
