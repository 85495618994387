import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { NavLink, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Zendesk from "react-zendesk";

import colors from "../theme/colors";

import useWindowDimensions from "../../globals/windowdim";

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { AiOutlineRight } from "react-icons/ai";

import { AppContext } from "../../globals/appcontext";
import { getCorrespondent } from "../../api/api";
import { EventType, getAmplitude } from "../../globals/amplitude";

import { Scrollbox } from "../general/ScrollBox";
import MenuElement from "./MenuItem";
import { FooterSubMenu } from "./FooterSubMenu";
import { FOOTER_MENU_ITEMS, leftMenuItems } from "./LeftMenuItems";

const ZENDESK_KEY = "d1129a4a-e01a-41e4-a34c-0a596cc59cad";
const zendeskSettings = {
  color: {
    theme: "#000",
  },
  offset: { vertical: "25px" },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [{ id: "description", prefill: { "*": "" } }],
  },
};

const MenuContainer = styled(Box)`
  padding: 1em;
  width: ${(props) => (props.$expanded ? 288 : 96)}px;
  height: 100vh;
  transition: width 0.2s;
  display: flex;
  flex-direction: column;
`;

const MenuItems = styled(Scrollbox)`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  flex-direction: column;
`;

const TeamBadge = styled(Box)`
  height: 28px;
  min-width: 28px;
  text-align: center;
  background: ${colors.yellow};
  border-radius: 50%;
  color: black;
  font-weight: bold;
  font-size: 18px;
`;

const Header = styled(Text)`
  font-weight: 500;
  font-size: 22px;
  max-width: 200px;
  visibility: ${(props) => (props.$hide ? "hidden" : "visible")};
  display: block;

  & > span {
    display: block;
    font-size: 18px;
    color: ${colors.gray};
  }
`;

const pulseAnimation = keyframes`
  0% { -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4); }
  70% { -webkit-box-shadow: 0 0 0 7px rgba(0, 255, 0, 0); }
  100% { -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 0, 0); }
`;

const EnvBadge = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${colors.lightGreen};

  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  border-color: black;
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const OpenButton = styled(Icon)<{ $flip: boolean }>`
  position: absolute;
  bottom: 60px;
  color: black;
  transform: rotate(${(props) => (props.$flip ? 180 : 0)}deg);
  padding: 5px;
  border-radius: 50%;
  font-size: 32px;
  transition-duration: 0.2s;

  &:hover {
    cursor: pointer;
    background: rgba(255, 215, 0, 0.48);
  }
`;

const MenuButton = styled(HamburgerIcon)`
  font-size: 2rem;
  position: absolute;
  margin: 25px;
  z-index: 10;
`;

interface MenuDrawerProps {
  children: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const DrawerMenu = (props: MenuDrawerProps): React.ReactElement => {
  const winSize = useWindowDimensions();

  if (winSize === "mobile") {
    return (
      <Drawer placement="left" onClose={props.onClose} isOpen={props.isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">{props.title}</DrawerHeader>
            <DrawerBody>{props.children}</DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }

  return props.children;
};

const LeftMenu = (): React.ReactElement | null => {
  const location = useLocation();

  const [expanded, setExpanded] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const corrQuery = useQuery("correspondent", getCorrespondent);
  const appContext = useContext(AppContext);

  const teamName = corrQuery.data?.name || "";
  const correspondentCode = corrQuery.data?.correspondent || "";

  const color = useColorModeValue("black", "white");
  const colorMode = useColorModeValue("light", "dark");
  const winSize = useWindowDimensions();

  const isLive = appContext.correspondent?.Env === "live";
  const isOmnibus = appContext.correspondent.Setup === "omnibus";
  const isSandbox = appContext.correspondent.Env === "sandbox";

  const menuItems = useMemo(() => leftMenuItems({ isSandbox, isOmnibus }), [
    isOmnibus,
    isSandbox,
  ]);

  // Go Live flow has a custom sidebar
  if (location.pathname === "/go-live") {
    return null;
  }

  const Link = (props: { to: string; children: React.ReactElement }) => {
    if (props.to.startsWith("http"))
      return (
        <a href={props.to} target="noopener noreferrer">
          {props.children}
        </a>
      );

    const handleClick = () => {
      switch (props.to) {
        case "/team-settings":
          getAmplitude().track({
            event_type: EventType.TEAM_SETTINGS_PAGE_VISITED,
            event_properties: {
              url: "https://broker-app.alpaca.markets/team-settings",
            },
          });
          break;
      }
    };

    return (
      <NavLink to={props.to} onClick={handleClick}>
        {props.children}
      </NavLink>
    );
  };

  const Menu = (
    <MenuItems colormode={colorMode}>
      <Box>
        {menuItems.map((item, idx) => (
          <MenuElement key={idx} {...item} expanded={expanded} />
        ))}
      </Box>
      <Spacer />
      <Box mb="3rem">
        {expanded &&
          FOOTER_MENU_ITEMS.map((item, idx) =>
            item?.submenu ? (
              <FooterSubMenu key={idx} {...item} />
            ) : (
              <Link key={item.path} to={item?.path ?? ""}>
                <Text
                  pt="1rem"
                  pl="2rem"
                  _hover={{ fontWeight: "600" }}
                  fontWeight={
                    location.pathname === item.path ? "bold" : "normal"
                  }
                >
                  {item.name}
                </Text>
              </Link>
            )
          )}
      </Box>
    </MenuItems>
  );

  if (winSize === "mobile") {
    return (
      <>
        <MenuButton onClick={() => setDrawerOpen(true)} />
        <DrawerMenu
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          title={teamName}
        >
          {Menu}
        </DrawerMenu>
      </>
    );
  }

  return (
    <MenuContainer $expanded={expanded}>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...zendeskSettings} />
      <HStack alignItems="baseline" mb="3em" mt="1.5em" ml="1em">
        <TeamBadge>{teamName.charAt(0)}</TeamBadge>
        <Header isTruncated $hide={!expanded}>
          {teamName} <span>{correspondentCode}</span>
        </Header>
        {isLive && expanded && <EnvBadge />}
      </HStack>

      {Menu}
      <OpenButton
        $flip={expanded}
        as={AiOutlineRight}
        onClick={() => setExpanded(!expanded)}
        color={color}
      />
    </MenuContainer>
  );
};

export default LeftMenu;
