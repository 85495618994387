import React from "react";

import { Session } from "../session";
import { Playground } from "../../../components/general/Playground";
import { Box, Button, Text } from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../../globals/amplitude";

const MilestoneFive = ({
  data,
  next,
  setLocalData,
}: Session): React.ReactElement => {
  const request = JSON.stringify({
    symbol: "AAPL",
    qty: 1,
    side: "buy",
    type: "market",
    time_in_force: "day",
  });

  const onResponse = (response: { id: string }) =>
    setLocalData({
      ...data,
      response: JSON.stringify(response),
      canMoveOn: true,
    });

  return (
    <Box maxW="1000px">
      <Text fontSize="xl" fontWeight="medium">
        Passing an Order
      </Text>
      <Text mt=".5rem" mb="2rem">
        The most common use case of Alpaca Broker API is to allow your end users
        to trade on the stock market. To do so simply pass in to <br />
        <code>POST /v1/trading/accounts/&#123;account_id&#125;/orders</code> and
        again replacing the&nbsp;
        <code>account_id</code> with&nbsp; <code>{data.account?.id}</code>.
      </Text>
      <Playground
        key="milestone-five"
        method="POST"
        readOnly={true}
        request={request}
        response={data.response}
        onResponse={onResponse}
        disabled={data.canMoveOn}
        endpoint={`/v1/trading/accounts/${data.account?.id}/orders`}
        step="step-5"
      />
      <Box textAlign="right" my="2rem">
        <Button
          disabled={!data.canMoveOn}
          onClick={() => {
            next();
            getAmplitude().track({
              event_type: EventType.QUICKSTART_COMPLETED,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MilestoneFive;
