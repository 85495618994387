import React from "react";
import Header from "../components/layout/Header";
import Playground from "../components/general/Playground";

import { Box } from "@chakra-ui/react";

const LiveTesting = (): React.ReactElement => (
  <Box>
    <Header title="API/Devs > Testing" />
    <Box maxW="500px" my="2rem" opacity="0.6">
      We have opened up access to your sandbox environment straight from the
      dashboard. Now you can pass real requests in the box below and it will
      reflect everywhere outside this dashboard.
    </Box>
    <Playground method="GET" request="{}" endpoint="/v1/assets" />
  </Box>
);

export default LiveTesting;
