import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

import { GoLivePill } from "./GoLive";
import {
  foreignEntityCopy,
  formHalfWidth,
  formThirdWidth,
  dateRegex,
  phoneNumberRegex,
  websiteRegex,
} from "./constants";
import { GoLiveFormLabel, GoLiveFormError } from "./GoLive";
import {
  CreateEntityDetailsRequest,
  GetEntityAndControlPersonResponse,
} from "../../api/types";

import {
  Box,
  HStack,
  VStack,
  FormControl,
  Text,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";

type BusinessDetailsProps = {
  onSubmit: (values: CreateEntityDetailsRequest) => void;
  isReadOnly: boolean;
  entityDetails?: GetEntityAndControlPersonResponse;
};

const BusinessDetails = ({
  onSubmit,
  isReadOnly,
  entityDetails,
}: BusinessDetailsProps): React.ReactElement => {
  const [registeredAdvisor, setRegisteredAdvisor] = useState<boolean | null>(
    null
  );
  const [foreignEntity, setForeignEntity] = useState<boolean | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CreateEntityDetailsRequest>({
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...entityDetails?.entity_details });
  }, [entityDetails]);

  return (
    <Box w="100%" mb="44px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack justifyContent="space-between">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Legal Entity Name</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: Beeple Ltd."
              disabled={isReadOnly}
              {...register("entity_legal_name", { required: true })}
            />
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Legal Entity Type</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: Corp, LLC, LTC, etc."
              disabled={isReadOnly}
              {...register("entity_type", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Date of Incorporation/Formation</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: 2002-01-01"
              disabled={isReadOnly}
              {...register("incorporation_date", {
                required: true,
                pattern: {
                  value: dateRegex,
                  message: "Date must be in YYYY-MM-DD format",
                },
              })}
            />
            {errors.incorporation_date && (
              <GoLiveFormError>
                {errors.incorporation_date.message}
              </GoLiveFormError>
            )}
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>
              Country of Incorporation/Formation
            </GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: United States"
              disabled={isReadOnly}
              {...register("country_of_registration", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl>
            <GoLiveFormLabel>
              Legal Street Address and Apartment/Unit #
            </GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: 123 Main St. Unit #101"
              disabled={isReadOnly}
              {...register("address", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>City</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: San Jose"
              disabled={isReadOnly}
              {...register("city", { required: true })}
            />
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>State/Province</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: California"
              disabled={isReadOnly}
              {...register("state", { required: true })}
            />
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Postal Code</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: 94401"
              disabled={isReadOnly}
              {...register("postal_code", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Business Phone Number</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="+1 123 4567"
              disabled={isReadOnly}
              {...register("phone_number", {
                required: true,
                pattern: {
                  value: phoneNumberRegex,
                  message: "Invalid phone number format",
                },
              })}
            />
            {errors.phone_number && (
              <GoLiveFormError>{errors.phone_number.message}</GoLiveFormError>
            )}
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Tax ID</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: xx-xxxxxxx"
              disabled={isReadOnly}
              {...register("tax_id", { required: true })}
            />
          </FormControl>
          <FormControl w={formThirdWidth}>
            <GoLiveFormLabel>Tax ID Type</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="eg: USA - EIN"
              disabled={isReadOnly}
              {...register("tax_id_type", { required: true })}
            />
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Website</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="https://example.com"
              disabled={isReadOnly}
              {...register("bd_data.url", {
                required: true,
                pattern: {
                  value: websiteRegex,
                  message: "Must begin with http:// or https://",
                },
              })}
            />
            {errors.bd_data?.url && (
              <GoLiveFormError>{errors.bd_data?.url.message}</GoLiveFormError>
            )}
          </FormControl>
          <FormControl w={formHalfWidth}>
            <GoLiveFormLabel>Social Media Links</GoLiveFormLabel>
            <Input
              variant="filled"
              placeholder="Twitter, LinkedIn, etc"
              disabled={isReadOnly}
              {...register("social_media_links")}
            />
          </FormControl>
        </HStack>
        <VStack mt="1.5rem" alignItems="start">
          <Text fontSize="16px">
            Are you registered or licensed as a financial services provider in
            your local jurisdiction? (optional)
          </Text>
          <HStack mb="24px">
            <GoLivePill
              onClick={() => setRegisteredAdvisor(false)}
              active={registeredAdvisor === false}
              disabled={isReadOnly}
            >
              <Text fontWeight="medium" fontSize="15px">
                No
              </Text>
            </GoLivePill>
            <GoLivePill
              ml="20px"
              onClick={() => setRegisteredAdvisor(true)}
              active={registeredAdvisor}
              disabled={isReadOnly}
            >
              <Text fontWeight="medium" fontSize="15px">
                Yes
              </Text>
            </GoLivePill>
          </HStack>
          <Box
            mt="24px"
            display={registeredAdvisor || isReadOnly ? "block" : "none"}
          >
            <HStack justifyContent="space-between" mt="1rem">
              <FormControl w={formHalfWidth}>
                <GoLiveFormLabel>Type of Registration</GoLiveFormLabel>
                <Input
                  variant="filled"
                  disabled={isReadOnly}
                  {...register("fsp_registration_type")}
                />
              </FormControl>
              <FormControl w={formHalfWidth}>
                <GoLiveFormLabel>
                  Country or Jurisdiction of Registration
                </GoLiveFormLabel>
                <Input
                  variant="filled"
                  disabled={isReadOnly}
                  {...register("fsp_country_of_registration")}
                />
              </FormControl>
            </HStack>
            <HStack
              justifyContent="space-between"
              mt="1.5rem"
              alignItems="normal"
            >
              <FormControl w={formHalfWidth}>
                <GoLiveFormLabel>
                  Registration Effective Date (if applicable)
                </GoLiveFormLabel>
                <Input
                  variant="filled"
                  placeholder="eg: 2002-01-01"
                  disabled={isReadOnly}
                  {...register("fsp_registration_effective_date", {
                    pattern: {
                      value: dateRegex,
                      message: "Date must be in YYYY-MM-DD format",
                    },
                  })}
                />
                {errors.fsp_registration_effective_date && (
                  <GoLiveFormError>
                    {errors.fsp_registration_effective_date.message}
                  </GoLiveFormError>
                )}
              </FormControl>
              <FormControl w={formHalfWidth}>
                <GoLiveFormLabel>Registration Number</GoLiveFormLabel>
                <Input
                  variant="filled"
                  disabled={isReadOnly}
                  {...register("fsp_registration_number")}
                />
              </FormControl>
            </HStack>
            <HStack justifyContent="space-between" mt="1.5rem">
              <FormControl w="100%">
                <GoLiveFormLabel>
                  If you’re in the process of becoming registered / licensed,
                  please provide the information here and include the
                  anticipated registration date.
                </GoLiveFormLabel>
                <Textarea
                  variant="filled"
                  disabled={isReadOnly}
                  {...register("fsp_additional_info")}
                />
              </FormControl>
            </HStack>
          </Box>
        </VStack>
        <VStack mt="1.5rem" alignItems="start">
          <Text fontSize="16px">
            Doing Business As, Alias, “Foreign Entity” (if applicable)
          </Text>
          <Text fontSize="13px" color="grey">
            {foreignEntityCopy}
          </Text>
          <HStack mb="24px">
            <GoLivePill
              onClick={() => setForeignEntity(false)}
              active={foreignEntity === false}
              disabled={isReadOnly}
            >
              <Text fontWeight="medium" fontSize="15px">
                No
              </Text>
            </GoLivePill>
            <GoLivePill
              ml="20px"
              onClick={() => setForeignEntity(true)}
              active={foreignEntity}
              disabled={isReadOnly}
            >
              <Text fontWeight="medium" fontSize="15px">
                Yes
              </Text>
            </GoLivePill>
          </HStack>
        </VStack>
        <Box mt="24px" display={foreignEntity || isReadOnly ? "block" : "none"}>
          <HStack mt="1.5rem">
            <FormControl>
              <GoLiveFormLabel>Relationship</GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="eg: Operating address, DBA, alias, foreign entity in California, etc."
                disabled={isReadOnly}
                {...register("dba_relationship")}
              />
            </FormControl>
          </HStack>
          <HStack
            justifyContent="space-between"
            mt="1.5rem"
            alignItems="normal"
          >
            <FormControl w={formHalfWidth}>
              <GoLiveFormLabel>Legal Entity Name</GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="eg: Beeple Ltd."
                disabled={isReadOnly}
                {...register("dba_legal_entity_name")}
              />
            </FormControl>
            <FormControl w={formHalfWidth}>
              <GoLiveFormLabel>Legal Entity Type</GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="eg: Corp, LLC, LTC, etc."
                disabled={isReadOnly}
                {...register("dba_entity_type")}
              />
            </FormControl>
          </HStack>
          <HStack justifyContent="space-between" mt="1.5rem">
            <FormControl w={formHalfWidth}>
              <GoLiveFormLabel>Date of Incorporation/Formation</GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="eg: 2002-01-01"
                disabled={isReadOnly}
                {...register("dba_incoporation_date", {
                  pattern: {
                    value: dateRegex,
                    message: "Date must be in YYYY-MM-DD format",
                  },
                })}
              />
            </FormControl>
            <FormControl w={formHalfWidth}>
              <GoLiveFormLabel>
                Country of Incorporation/Formation
              </GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="eg: United States"
                disabled={isReadOnly}
                {...register("dba_country_of_registration")}
              />
            </FormControl>
          </HStack>
          <HStack mt="1.5rem">
            <FormControl>
              <GoLiveFormLabel>Full Address</GoLiveFormLabel>
              <Input
                variant="filled"
                placeholder="Address Line"
                disabled={isReadOnly}
                {...register("dba_address")}
              />
            </FormControl>
          </HStack>
          <HStack mt="1.5rem">
            <FormControl>
              <GoLiveFormLabel>Additional Information</GoLiveFormLabel>
              <Textarea
                variant="filled"
                disabled={isReadOnly}
                {...register("dba_additional_info")}
              />
            </FormControl>
          </HStack>
        </Box>
        <HStack w="100%" mt="4rem" justifyContent="center">
          <Button w="98px" h="36px" ml="auto" type="submit" disabled={!isValid}>
            Next
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default BusinessDetails;
