import React, { useCallback } from "react";
import { motion } from "framer-motion";

interface ProgressBarProps {
  steps: { key: string; label: string; onClick?: () => void }[];
  current: number;
}

const ProgressBar = ({
  steps,
  current,
}: ProgressBarProps): React.ReactElement => {
  const isComplete = useCallback(
    (currentIndex: number) => currentIndex < current,
    [steps, current]
  );

  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex h-fit">
        {steps.map(({ label, key, onClick }, stepIdx) => (
          <li
            key={key}
            className={`flex-1 relative flex flex-col items-center h-full ${
              onClick ? "cursor-pointer" : ""
            }`}
            onClick={onClick}
          >
            {/* Line between the steps */}
            {stepIdx !== steps.length - 1 && (
              <div className="absolute inset-0" aria-hidden="true">
                <div
                  /*
                    left: 50% to center of circle, spacing.2 (radius) to move outside
                          -1px because border-radius makes it slightly disconnected
                    width: 100% - spacing.4 to radius of left and right circles
                           -2px to account for border of left and right circles
                  */
                  className={`h-0.5 relative left-[calc(50%+theme(spacing.2)-1px)] w-[calc(100%-theme(spacing.4)+2px)] top-2 bg-gray-500`}
                >
                  {isComplete(stepIdx) && (
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: "100%" }}
                      transition={{ bounce: 0 }}
                      className={`h-0.5 w-full bg-emerald-400`}
                    />
                  )}
                </div>
              </div>
            )}
            {/* Steps */}
            <div
              className={`flex h-4 w-4 items-center justify-center z-0 rounded-full ${
                stepIdx === current
                  ? "border-2 border-emerald-400 bg-transparent"
                  : isComplete(stepIdx)
                  ? "bg-emerald-400"
                  : "bg-gray-500"
              }`}
            />
            <div
              className={`flex-1 text-center mt-2 ${
                isComplete(stepIdx) ? "text-emerald-400" : ""
              }`}
            >
              {label}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressBar;
