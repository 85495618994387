import React from "react";

import {
  Center,
  Flex,
  Link,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

type UUIDProps = {
  onClick?: () => void;
  value: string;
};

const UUID = ({ onClick, value }: UUIDProps): JSX.Element => {
  const { onCopy } = useClipboard(value);
  const toast = useToast();

  const copyToClipboard = () => {
    onCopy();
    toast({
      description: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Flex>
      <Center color="brand.500">
        <Link onClick={onClick} color="brand.500" mr=".25rem" width="308px">
          {value}
        </Link>
        <Tooltip label="Copy to clipboard">
          <CopyIcon cursor="pointer" onClick={copyToClipboard} />
        </Tooltip>
      </Center>
    </Flex>
  );
};

export default UUID;
