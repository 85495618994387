import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
  BoxProps,
} from "@chakra-ui/react";
import { Navigate, NavLink } from "react-router-dom";
import { forgotPassword } from "../../auth/paciam";

const ResetPassword = (props: BoxProps): React.ReactElement => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    forgotPassword(email)
      .then(() => {
        toast({
          title: "Reset code sent to your email",
          status: "success",
        });
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: "An error occured resetting your password",
          description: e.message,
          status: "error",
        });
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to="/set-password" state={{ email }} />;
  }

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Text fontSize="4xl" mb="2rem">
            Reset your password
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <Button
              size="lg"
              type="submit"
              width="full"
              mt="4rem"
              isLoading={isLoading}
            >
              Get reset code
            </Button>
            <Center mt="3rem">
              <NavLink to="/login">
                <Text fontSize="xl">Cancel</Text>
              </NavLink>
            </Center>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default ResetPassword;
