import React, { useState, useContext } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  useToast,
  Alert,
  HStack,
  BoxProps,
  Select,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useIntercom } from "react-use-intercom";
import { CloseIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate, NavLink } from "react-router-dom";
import colors from "../theme/colors";
import { AppContext } from "../../globals/appcontext";
import { isMobile } from "react-device-detect";
import { register } from "../../auth/paciam";
import { getAmplitude, EventType, Tokens } from "../../globals/amplitude";

const SIGN_UP_ROLE_OPTIONS = [
  "Developer",
  "Executive/Founder",
  "Product",
  "Freelancer",
  "Other",
];

const HidePasswordButton = styled(Box)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const LoginLink = styled(NavLink)`
  color: ${colors.yellow};
  margin-left: 10px;
  font-weight: 500;

  &:hover {
    border-bottom: solid 2px ${colors.yellow};
  }
`;

const Signup = (props: BoxProps): React.ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpRole, setSignUpRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [validationErrors, setValErrors] = useState<string[]>([]);

  // Hide Intercom on signup page
  const { hide } = useIntercom();
  hide();

  const updatePassword = (value: string) => {
    setPassword(value);
    const validationErrors: string[] = [];

    if (value.length < 12) {
      validationErrors.push("Password must be at least 12 characters");
    }

    if (value.toLowerCase() === value) {
      validationErrors.push("Password must include uppercase characters");
    }

    if (value.toUpperCase() === value) {
      validationErrors.push("Password must include lowercase characters");
    }

    if (!/\d/.test(value)) {
      validationErrors.push("Password must contain a number");
    }

    setValErrors(validationErrors);
  };

  const updateName = (value: string) => {
    setName(value);
    const validationErrors: string[] = [];

    if (!/^[a-zA-Z]{2,} [a-zA-Z]{2,}$/.test(value)) {
      validationErrors.push(
        "Please enter a valid full name of at least 2 characters in both first and last name"
      );
    }

    setValErrors(validationErrors);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!signUpRole) {
      toast({
        title: "Please select your role",
        status: "error",
      });
      setIsLoading(false);
      return;
    }

    register({ email, password, name, signUpRole })
      .then(() => {
        toast({
          title: "Sign up successful",
          status: "success",
        });

        getAmplitude(Tokens.BROKERDASH, Tokens.ALGODASH).map((instance) => {
          instance.identify({
            user_properties: {
              $set: {
                referrer: document?.referrer ?? "",
              },
              $setOnce: {
                email,
              },
            },
          });
        });

        appContext.clear();
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: "An error occurred during sign up",
          description: e.message,
          status: "error",
        });
        setIsLoading(false);
      });
  };

  if (redirect) {
    navigate("/verification", { state: { name, email, password } });
  }

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const headerFontSize = isMobile ? "3xl" : "4xl";

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Center fontSize={headerFontSize} mb="2rem">
            Create your Broker API account
          </Center>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Full Name</FormLabel>
              <Input
                variant="filled"
                type="text"
                size="lg"
                onChange={(event) => updateName(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  size="lg"
                  onChange={(event) =>
                    updatePassword(event.currentTarget.value)
                  }
                />
                <InputRightElement width="3rem">
                  <HidePasswordButton onClick={handlePasswordVisibility}>
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </HidePasswordButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>What is your role?</FormLabel>
              <Select
                variant="filled"
                size="lg"
                onChange={(event) => setSignUpRole(event.target.value)}
              >
                <option value="" selected disabled hidden>
                  Select your role
                </option>
                {SIGN_UP_ROLE_OPTIONS.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Select>
            </FormControl>
            {validationErrors.length > 0 && (
              <Alert status="error" mt="2rem">
                <Box>
                  {validationErrors.map((err) => (
                    <HStack key={err}>
                      <CloseIcon color="error" mr="5px" />
                      <Text color="error">{err}</Text>
                    </HStack>
                  ))}
                </Box>
              </Alert>
            )}
            <Button
              size="lg"
              width="full"
              mt="4rem"
              isLoading={isLoading}
              disabled={validationErrors.length > 0}
              onClick={(event) => {
                handleSubmit(event);
                getAmplitude(Tokens.BROKERDASH, Tokens.ALGODASH).map(
                  (instance) =>
                    instance.track({
                      event_type: EventType.SIGNUP_CLICKED,
                      event_properties: {
                        url: "https://broker-app.alpaca.markets/sign-up",
                        referrer: document?.referrer ?? "",
                      },
                    })
                );
              }}
            >
              Sign up
            </Button>
            <Center mt="3rem" fontSize="lg">
              <Text color="grey" mr="5px">
                Already have a Broker API account?
                <LoginLink to="/login">Log in</LoginLink>
              </Text>
            </Center>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default Signup;
