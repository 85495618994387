import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const AlpacaSVG = createIcon({
  displayName: "Alpaca",
  viewBox: "0 0 102 218",
  path: [
    <path
      key="1"
      style={{
        opacity: "5.000000e-02",
        fill: "#36383D",
      }}
      d="M4.6,46.2c2.8-5.3,6.9-9.8,11.9-13c-1.5-2.5-2.4-5.4-2.4-8.4c-0.1-3,0.7-5.9,2.1-8.5c1.4-2.6,3.5-4.7,6.1-6.2
	c2.5-1.5,5.4-2.3,8.4-2.3v13.9h0.2c0.7-3.9,2.7-7.4,5.6-10c3-2.5,6.8-3.9,10.7-3.9v19.5h5.5v0c8.2,0.4,15.9,3.9,21.6,9.8
	c5.7,6,8.8,13.9,8.8,22.1v0.2l18.6,12.1c-1.2,7.8-5.2,15-11.2,20.1c-6,5.1-12.5,8-20.4,8h-0.9L80.4,218l-79.6,0l-0.6-28.7V63.4
	C0.2,57.4,1.7,51.5,4.6,46.2z"
    />,
    <path
      key="2"
      style={{ fill: "#D8D8D8" }}
      d="M65.9,69h-7.6c-2,0-3.9-0.8-5.4-2.2c-1.4-1.4-2.2-3.4-2.2-5.4h11.1c1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.7,1.1,2.7
	L65.9,69z"
    />,
  ],
});

export const AlpacaDarkSVG = createIcon({
  displayName: "Alpaca",
  viewBox: "0 0 102 218",
  path: [
    <path
      key="1"
      style={{
        opacity: "0.15",
        fill: "#FFFFFF",
      }}
      d="M4.7,45.8c2.8-5.3,6.9-9.8,11.9-13c-1.5-2.5-2.4-5.4-2.4-8.4c-0.1-3,0.7-5.9,2.1-8.5c1.4-2.6,3.5-4.7,6.1-6.2
	c2.5-1.5,5.4-2.3,8.4-2.3v13.9h0.2c0.7-3.9,2.7-7.4,5.6-10c3-2.5,6.8-3.9,10.7-3.9v19.5h5.5v0c8.2,0.4,15.9,3.9,21.6,9.8
	c5.7,6,8.8,13.9,8.8,22.1v0.2L101.9,71c-1.2,7.8-5.2,15-11.2,20.1c-6,5.1-12.5,8-20.4,8h-0.9l11.2,118.5l-79.6,0l-0.6-28.7V63
	C0.4,57,1.9,51.1,4.7,45.8z"
    />,
    <path
      key="2"
      style={{ fill: "#171719" }}
      d="M66.1,68.5h-7.6c-2,0-3.9-0.8-5.4-2.2c-1.4-1.4-2.2-3.4-2.2-5.4h11.1c1,0,2,0.4,2.7,1.1
	c0.7,0.7,1.1,1.7,1.1,2.7L66.1,68.5z"
    />,
  ],
});

export const AlpacaIconFilled = createIcon({
  displayName: "IconFilled",
  viewBox: "0 0 54 54",
  path: (
    <>
      <path
        d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z"
        fill="#FCD72B"
      />
      <mask
        id="mask0_801_26366"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="54"
        height="54"
      >
        <path
          d="M27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_801_26366)">
        <path
          d="M37.4241 51.0914C37.0263 51.0914 36.6448 50.9334 36.3635 50.6521C36.0822 50.3708 35.9241 49.9892 35.9241 49.5914V22.4414C35.9246 21.1465 35.6023 19.872 34.9863 18.733C34.3703 17.594 33.4801 16.6266 32.3961 15.9182C32.7295 15.3725 32.9115 14.7478 32.9235 14.1084C32.9354 13.469 32.7767 12.838 32.4639 12.2803C32.151 11.7225 31.6953 11.2582 31.1434 10.935C30.5916 10.6118 29.9636 10.4414 29.3241 10.4414V13.4414H29.2737C29.132 12.6026 28.6977 11.841 28.0479 11.2919C27.3981 10.7427 26.5749 10.4414 25.7241 10.4414V14.6414H24.5241V14.6486C22.7478 14.7256 21.0698 15.4855 19.8401 16.7697C18.6104 18.054 17.924 19.7634 17.9241 21.5414C17.9241 21.5522 17.9241 21.563 17.9241 21.5738L13.8885 24.1826C14.1599 25.8729 15.0246 27.4112 16.3276 28.5215C17.6306 29.6319 19.0466 30.2416 20.7585 30.2414C20.8245 30.2414 20.8881 30.2414 20.9529 30.2414L18.5241 55.8014L35.7741 57.0014L36.6741 54.7514L53.9157 54.1514V51.0914H37.4241Z"
          fill="white"
        />
        <path
          d="M21.7494 22.8164C21.7497 22.5978 21.8367 22.3882 21.9912 22.2337C22.1458 22.0791 22.3553 21.9921 22.5738 21.9918H24.9738C24.9738 22.4295 24.8 22.8493 24.4906 23.1588C24.1811 23.4683 23.7614 23.6422 23.3238 23.6422H21.6738L21.7494 22.8164Z"
          fill="#FCD72B"
        />
      </g>
    </>
  ),
});
