import { CorporateAction, RawCorporateAction } from "./types";

export const consolidateData = (
  rawData: RawCorporateAction[]
): Record<string, CorporateAction[]> => {
  const consolidated: Record<string, CorporateAction[]> = {
    stockDividend: [],
    cashDividend: [],
    stockMerger: [],
    cashMerger: [],
    stockAndCashMerger: [],
    forwardSplit: [],
    reverseSplit: [],
    unitSplit: [],
    spinOff: [],
  };

  for (const item of transformData(rawData)) {
    consolidated[item.type]?.push(item);
  }

  return consolidated;
};

export const transformData = (
  rawData: RawCorporateAction[]
): CorporateAction[] => {
  return rawData.map((item) => {
    const type = Object.keys(item).filter((key) => key !== "base")[0];
    return Object.values(item).reduce(
      (prev, curr) => ({
        ...prev,
        ...curr,
      }),
      { type }
    );
  });
};
