import Amplitude from "amplitude";
import { getC } from "./appcontext";

import { StaticMap } from "./types";

export enum EventType {
  PAGE_VISITED = "brokerdash_page_visited",
  NAVIGATED_API_DEVS = "bk_navigated_api_devs",
  NAVIGATED_DASHBOARD = "bk_navigated_dashboard",
  GENERATE_API_KEY_BUTTON_CLICKED = "bk_generate_api_key_button_clicked",
  COPY_API_KEY_BUTTON_CLICKED = "bk_copy_api_key_button_clicked",
  COPY_API_SECRET_BUTTON_CLICKED = "bk_copy_api_secret_button_clicked",
  POPULATE_SANDBOX_BUTTON_CLICKED = "bk_populate_sandbox_button_clicked",
  RESET_SANDBOX_BUTTON_CLICKED = "bk_reset_sandbox_button_clicked",
  WELCOME_COMPLETE = "welcome_complete",
  WELCOME_SAVE = "welcome_save",
  SANDBOX_CHECKBOX_CHECKED = "sandbox_checkbox_checked",
  SANDBOX_CHECKBOX_UNCHECKED = "sandbox_checkbox_unchecked",
  SANDBOX_COMPLETE = "sandbox_complete",
  LIMITEDLIVE_CHECKBOX_CHECKED = "limitedlive_checkbox_checked",
  LIMITEDLIVE_CHECKBOX_UNCHECKED = "limitedlive_checkbox_unchecked",
  LIMITEDLIVE_SAVE = "limitedlive_save",
  LIMITEDLIVE_COMPLETE = "limitedlive_complete”",
  LIVE_RIA = "live_ria",
  LIVE_INVESTMENT_ADVISOR = "live_investment_advisor",
  LIVE_BROKER_DEALER = "live_broker_dealer",
  LIVE_TECHNOLOGY_PARTNER = "live_technology_partner",
  LIVE_CHECKBOX_CHECKED = "live_checkbox_checked",
  LIVE_CHECKBOX_UNCHECKED = "live_checkbox_unchecked",
  LIVE_SAVE = "live_save",
  LIVE_COMPLETE = "live_complete",
  QUICKSTART_PAGE_VISITED = "quickstart_page_visited",
  QUICKSTART_STEP1 = "quickstart_step1",
  QUICKSTART_STEP1_CLICK_SEND = "quickstart_step1_click_send",
  QUICKSTART_STEP2 = "quickstart_step2",
  QUICKSTART_STEP2_CLICK_SEND = "quickstart_step2_click_send",
  QUICKSTART_STEP3 = "quickstart_step3",
  QUICKSTART_STEP3_CLICK_SEND = "quickstart_step3_click_send",
  QUICKSTART_ACCOUNTPAGE = "quickstart_accountpage",
  QUICKSTART_STEP4 = "quickstart_step4",
  QUICKSTART_STEP4_CLICK_SEND = "quickstart_step4_click_send",
  QUICKSTART_TRANSACTION = "quickstart_transaction",
  QUICKSTART_STEP5 = "quickstart_step5",
  QUICKSTART_STEP5_CLICK_SEND = "quickstart_step5_click_send",
  QUICKSTART_COMPLETED = "quickstart_completed",
  QUICKSTART_RESTART = "quickstart_restart",
  LOGIN_CLICKED = "broker_dash_login_button_clicked",
  SIGNUP_CLICKED = "broker_dash_signup_button_clicked",
  SIGNUP_COMPLETED = "broker_dash_signup_confirmed",
  TEAM_SETTINGS_PAGE_VISITED = "bk_team_settings_page_visited",
  EDIT_TEAM_NAME = "bk_edit_team_name",
  ADD_TEAM_MEMBER_CLICKED = "bk_add_team_member_clicked",
  ADD_TEAM_MEMBER_STARTED = "bk_add_team_member_started",
  ADD_TEAM_MEMBER_CANCELLED = "bk_add_team_member_cancelled",
  ADD_TEAM_MEMBER_COMPLETED = "bk_add_team_member_completed",
  EDIT_TEAM_MEMBER_STARTED = "bk_edit_team_member_started",
  EDIT_TEAM_MEMBER_CANCELLED = "bk_edit_team_member_cancelled",
  EDIT_TEAM_MEMBER_COMPLETED = "bk_edit_team_member_completed",
  ADD_TEAM_MEMBER_SEND_REQUEST = "add_team_member_send_request",
  ADD_ANOTHER_USER_CHECKED = "add_another_user_checked",
  VIEW_USER_PROFILE = "bk_view_user_profile",
  EDIT_USER_PROFILE = "bk_edit_user_profile",
  ALGODASH_LOGIN_CLICKED = "broker_dash_algodash_login_clicked",
  ACCOUNTS_PAGE_VISITED = "accounts_page_visited",
  ACCOUNTS_PAGE_ACTIVE_USERS = "accounts_page_active_users",
  ACCOUNTS_PAGE_INACTIVE_USERS = "accounts_page_inactive_users",
  ACCOUNTS_PAGE_EXPORT = "accounts_page_export",
  ACCOUNTS_PAGE_FILTER = "accounts_page_filter",
  ACCOUNTS_PAGE_STATUS_FILTER = "accounts_page_status_filter",
  ACCOUNTS_PAGE_CREATED_AT_FILTER = "accounts_page_created_at_filter",
  ACCOUNTS_PAGE_CANCEL_FILTER = "accounts_page_cancel_filter",
  COLOR_MODE_BUTTON_CLICKED = "bk_color_mode_button_clicked",
  CRYPTO_ENABLED_CLICKED = "cryptotrading_toggle",
  JNLC_DAILY_LIMIT_CLICKED = "jnlc_daily_limit_clicked",
  JNLC_TRANSACTION_LIMIT_CLICKED = "jnlc_transaction_limit_clicked",
  FIRM_TRANSFER_LIMIT_CLICKED = "firm_transfer_limit_clicked",
  TEAM_SETTINGS_UPDATED = "team_settings_updated",
  CA_TYPE_CLICKED = "bk_ca_type_clicked",
  CA_SYMBOL_SEARCH = "bk_ca_symbol_search",
  CA_DATE_FILTER = "bk_ca_date_filter",
  LAUNCH_TEAM_NAME_CHANGE = "bk_launch_team_name",
  LAUNCH_COUNTRY_SELECTED = "bk_launch_country_selected",
  LAUNCH_PRODUCT_DESCRIPTION = "bk_launch_product_description",
  LAUNCH_WEBSITE_ADDED = "bk_launch_website_added",
  LAUNCH_WELECOME_NEXT_CLICKED = "bk_launch_welcome_next",
}

type Token = string;
type TokenMap = Record<string, Token>;

// Check whether to use staging or production token
const BrokerdashEnvToken = window.location.href.includes(
  "broker-app.alpaca.markets"
)
  ? process.env.REACT_APP_BROKERDASH_PROD_AMPLITUDE_TOKEN
  : process.env.REACT_APP_BROKERDASH_STAGING_AMPLITUDE_TOKEN;

// tokens are used to specify the desired project
export const Tokens: TokenMap = {
  BROKERDASH: BrokerdashEnvToken || "no-token",
  ALGODASH: process.env.REACT_APP_ALGODASH_AMPLITUDE_TOKEN || "no-token",
};

// note: by default no-user-id is passed to Amplitude
// note: it is a rare occurrence and *should* be overridden by the time the page is loaded
// note: Tokens.ALGODASH needs to use deviceId in order to sync with the Amplitude project 'AlpacaMarkets'
const options = {
  [Tokens.ALGODASH]: { deviceId: "no-device-id" },
  [Tokens.BROKERDASH]: { userId: "no-user-id" },
};

// amplitude instances will be populated as they are called
export const instances: StaticMap<Token, Amplitude> = new Map();

// implementation signatures
export function getAmplitude(): Amplitude;
export function getAmplitude(...t: Token[]): Amplitude[];

// conditional return of the instance(s)
export function getAmplitude<U extends keyof TokenMap>(
  ...keys: U[]
): Amplitude | Amplitude[] {
  return !keys.length
    ? // no tokens passed
      getOrCreateInstance(Tokens.BROKERDASH)
    : // multiple tokens passed
      keys.map((token) => getOrCreateInstance(token));
}

const getOrCreateInstance = (token: Token) => {
  // get or create the instance
  const instance =
    instances.get(token) ||
    instances.set(token, new Amplitude(token, options[token])).get(token);

  if (options[token].userId) {
    // @ts-expected-error: userId is not a public property
    instance.userId = localStorage.getItem("userId") || options[token].userId;

    // track which correspondent the user is managing
    const corr = getC();
    instance.identify({
      user_properties: {
        correspondent: corr.ID || "",
        role: localStorage.getItem("user-role"),
      },
    });
  } else if (options[token].deviceId) {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const deviceId = urlParams.get("amp_device_id");
    // @ts-expected-error: deviceId is not a public property
    instance.deviceId = deviceId || options[token].deviceId;
  }

  return instance;
};

// returns the default instance
export default getAmplitude();
