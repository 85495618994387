import countries from "../../globals/countries";

import React, { useEffect } from "react";
import Select, { StylesConfig } from "react-select";

import {
  createSandboxCorrespondent,
  getCorrespondent,
  updateCorrespondent,
} from "../../api/api";

import {
  Box,
  Button,
  Collapse,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select as ChakraSelect,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { theme } from "../theme/Theme";
import { AppContext } from "../../globals/appcontext";
import { websiteRegex } from "../../pages/go-live/constants";
import { businessTypes } from "./constants";
import { Controller, useForm } from "react-hook-form";
import { EventType, getAmplitude } from "../../globals/amplitude";
import { GoLiveFormError as ErrorMessage } from "../../pages/go-live/GoLive";
import colors from "../../components/theme/colors";

const multiSelectDarkStyles: StylesConfig = {
  control: (base, state) => ({
    ...base,
    background: "#202022",
    borderWidth: "2px",
    borderColor: "#202022",
    borderRadius: "4px",

    "&:hover": {
      borderColor: state.isFocused ? "#63b3ed;" : "#202022",
      borderWidth: state.isFocused ? "2px" : "none",
      backgroundColor: "rgba(255, 255, 255, 0.06)",
    },
  }),
  menu: (base) => ({
    ...base,
    background: "#202022",
  }),
};

const multiSelectStyles: StylesConfig = {
  control: (base, state) => ({
    ...base,
    borderWidth: "2px",
    borderRadius: "4px",
    background: colors.cardBackgroundLight,
    borderColor: colors.cardBackgroundLight,

    "&:hover": {
      borderColor: state.isFocused ? "#63b3ed;" : "#202022",
      borderWidth: state.isFocused ? "2px" : "none",
      backgroundColor: "rgba(255, 255, 255, 0.06)",
    },
  }),
  menu: (base) => ({
    ...base,
    background: colors.cardBackgroundLight,
  }),
};

type WelcomeProps = {
  step: number;
  handleCompleteStatus: (index: number, key?: string) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  readOnly: boolean;
  corr: string;
};

type WelcomeValues = {
  name: string;
  business_type: string;
  user_countries: { label: string; value: string }[];
  has_url: string;
  url: string;
};

const Welcome = ({
  step,
  handleCompleteStatus,
  setStep,
  readOnly,
  corr,
}: WelcomeProps): React.ReactElement => {
  const ctx = React.useContext(AppContext);
  const toast = useToast();
  const storageKey = `launch-guide-welcome-${corr}`;

  const { colorMode } = useColorMode();

  const showError = (
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    err: any,
    title = "There was a problem with the request"
  ) => toast({ title, description: err.message, status: "error" });

  const waitForAuth = async () => {
    for (let tries = 1; tries <= 5; tries++) {
      try {
        // Slightly hacky but need to give db trigger invalidation time to propogate to all auth instances.
        await getCorrespondent().then(({ correspondent: ID, name: Name }) =>
          ctx.updateCorrespondent({ ID, Env: "sandbox", Name })
        );

        break;
      } catch (err) {
        await new Promise((r) => setTimeout(r, 10 * tries));
        console.debug(err);
      }
    }
  };

  const handleWelcomeNext = async () => {
    const { has_url, url, name, ...more } = getValues();
    const values = { ...more, has_url, name, url: has_url === "no" ? "" : url };

    try {
      if (ctx.correspondent.ID) {
        // if it already exists, just update the name
        await updateCorrespondent({ ...ctx.correspondent, Name: name }).then(
          () => {
            ctx.updateCorrespondent({ Name: name });
            localStorage.setItem(storageKey, JSON.stringify(values));
            setStep(1);
            handleCompleteStatus(0);
          }
        );

        return;
      }

      const { correspondent } = await createSandboxCorrespondent(name);

      ctx.updateCorrespondent({ ID: correspondent, Env: "sandbox" });
      localStorage.setItem(
        `launch-guide-welcome-${correspondent}`,
        JSON.stringify(values)
      );

      setStep(1);
      handleCompleteStatus(0, `launch-guide-step-status-${correspondent}`);

      await waitForAuth().then(() => {
        toast({ title: "Progress saved!", status: "success" });
        getAmplitude().track({
          event_type: EventType.LAUNCH_WELECOME_NEXT_CLICKED,
        });
      });
    } catch (e) {
      showError(e);
    }
  };

  const {
    register,
    control,
    getValues,
    reset,
    watch,
    trigger,
    formState: { isValid, errors },
  } = useForm<WelcomeValues>({ mode: "onChange" });

  const hasUrl = watch("has_url") === "yes";

  useEffect(() => {
    const values = JSON.parse(localStorage.getItem(storageKey) || "{}");
    reset({
      ...values,
      url: values.has_url === "no" ? undefined : values.url,
    });
    trigger();
  }, [storageKey]);

  // need to trigger validation on radio change
  useEffect(() => {
    trigger();
  }, [hasUrl]);

  const onBlurTrackEvent = (eventType: EventType) => () =>
    getAmplitude().track({
      event_type: eventType,
    });

  return (
    <Box>
      <Text fontSize="xl" mb={1}>
        Welcome
      </Text>
      <Collapse in={step === 0 || readOnly}>
        <VStack mt="1rem" alignItems="start" w="100%" spacing={8}>
          <VStack w="100%" alignItems="start">
            <Text fontSize="lg">Choose Team Name</Text>
            <Text opacity="70%">You can change it any time</Text>
            <Input
              {...register("name", {
                validate: (value) => value.length > 0,
              })}
              onBlur={onBlurTrackEvent(EventType.LAUNCH_TEAM_NAME_CHANGE)}
              disabled={readOnly}
              variant="filled"
              placeholder="Team Name"
            />
          </VStack>
          <VStack w="100%" alignItems="start">
            <Text fontSize="lg">
              What country/countries are you interested in operating?
            </Text>
            <Box w="100%">
              <Controller
                control={control}
                name="user_countries"
                rules={{
                  validate: (value) => Array.isArray(value) && value.length > 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Select all countries"
                    options={countries}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlurTrackEvent(EventType.LAUNCH_COUNTRY_SELECTED)}
                    maxMenuHeight={130}
                    styles={
                      colorMode === "light"
                        ? multiSelectStyles
                        : multiSelectDarkStyles
                    }
                    // @ts-expect-error: Type 'Dict<any>' is not assignable to type 'ThemeConfig | undefined'.
                    theme={theme}
                    isMulti
                    isClearable
                    openMenuOnClick
                    isSearchable
                    isDisabled={readOnly}
                  />
                )}
              />
            </Box>
          </VStack>
          <VStack w="100%" alignItems="start">
            <Text fontSize="lg">What best describes your product?</Text>
            <Text opacity="70%">
              Depending on the type of product we will be able to optimize your
              experience with Alpaca
            </Text>
            <Box w="100%">
              <Controller
                name="business_type"
                control={control}
                rules={{ validate: (value) => !!value }}
                render={({ field }) => (
                  <ChakraSelect
                    {...field}
                    placeholder="Select option"
                    variant="filled"
                    disabled={readOnly}
                    onBlur={onBlurTrackEvent(
                      EventType.LAUNCH_PRODUCT_DESCRIPTION
                    )}
                  >
                    {businessTypes.map(({ label, value }, i) => (
                      <option key={i} value={value}>
                        {label}
                      </option>
                    ))}
                  </ChakraSelect>
                )}
              />
            </Box>
          </VStack>
          <VStack w="100%" alignItems="start">
            <Text fontSize="lg">Do you currently have a website?</Text>
            <Controller
              control={control}
              name="has_url"
              rules={{ validate: (value) => !!value }}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  value={value}
                  onChange={(value: string) => onChange(value)}
                >
                  <HStack>
                    <Radio value="yes" isDisabled={readOnly}>
                      <Text fontWeight="medium" fontSize="15px">
                        Yes
                      </Text>
                    </Radio>
                    <Radio
                      value="no"
                      ml="20px !important"
                      isDisabled={readOnly}
                    >
                      <Text fontWeight="medium" fontSize="15px">
                        No
                      </Text>
                    </Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
            <Box w="100%" display={hasUrl ? "block" : "none"}>
              <Input
                mt="12px"
                disabled={readOnly}
                variant="filled"
                placeholder="eg: https://website.com"
                {...register("url", {
                  validate: (value) => {
                    if (hasUrl) {
                      return value.length > 0;
                    } else {
                      return true;
                    }
                  },
                  required: {
                    value: hasUrl,
                    message: "Please provide your website",
                  },
                  pattern: {
                    value: websiteRegex,
                    message: "Must begin with http:// or https://",
                  },
                })}
                onBlur={onBlurTrackEvent(EventType.LAUNCH_WEBSITE_ADDED)}
              />
              {errors.url && <ErrorMessage>{errors.url.message}</ErrorMessage>}
            </Box>
          </VStack>
          {!readOnly && (
            <Button onClick={handleWelcomeNext} mb="1rem" isDisabled={!isValid}>
              Next
            </Button>
          )}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Welcome;
