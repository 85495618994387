import React, { useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Select,
  MenuList,
  MenuItem,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
} from "@chakra-ui/react";
import { capitalize } from "../../globals/utils";
import RangeSlider from "../general/RangeSlider";
import { NumRange } from ".";
import FilterHeader from "./FormHeader";

interface FilterNumberProps {
  header: string;
  onChange: (value: NumRange) => void;
  minRange: number;
  maxRange: number;
  step?: number;
  prefix?: string;
}

const FilterNumber = (props: FilterNumberProps): React.ReactElement => {
  const [filterType, setFilterType] = useState("between");
  const [leftBound, setLeftBound] = useState(props.minRange);
  const [rightBound, setRightBound] = useState(props.maxRange);
  const prefix = props.prefix || "";

  let rangeValues = [leftBound];
  if (filterType === "between") {
    rangeValues = [leftBound, rightBound];
  }

  const setValues = (values: number[]) => {
    setLeftBound(values[0]);
    setRightBound(values[1]);

    if (filterType === "less_than") {
      props.onChange([null, values[0]]);
    } else if (filterType === "equal") {
      props.onChange([values[0], values[0] + 1]);
    } else {
      props.onChange([values[0], values[1]]);
    }
  };

  return (
    <>
      <FilterHeader value={props.header} />
      <Menu>
        <MenuButton
          as={Select}
          variant="filled"
          rightIcon={<ChevronDownIcon />}
          width="100%"
          placeholder={capitalize(filterType)}
        />
        <MenuList>
          <MenuItem onClick={() => setFilterType("greater_than")}>
            Greater Than
          </MenuItem>
          <MenuItem onClick={() => setFilterType("less_than")}>
            Less Than
          </MenuItem>
          <MenuItem onClick={() => setFilterType("equal")}>Equal</MenuItem>
          <MenuItem
            onClick={() => {
              setFilterType("between");
              setRightBound(props.maxRange);
            }}
          >
            Between
          </MenuItem>
        </MenuList>
      </Menu>
      <HStack mt="20px">
        <NumberInput
          min={props.minRange}
          max={props.maxRange}
          step={props.step || 1}
          onChange={(_, val) => setValues([val, rightBound])}
          value={prefix + leftBound}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Spacer />
        {filterType === "between" && (
          <NumberInput
            min={props.minRange}
            max={props.maxRange}
            step={props.step || 1}
            onChange={(_, val) => setValues([leftBound, val])}
            value={prefix + rightBound}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
      </HStack>
      <RangeSlider
        values={rangeValues}
        min={props.minRange}
        max={props.maxRange}
        invertTrack={filterType === "less_than"}
        removeTrackColor={filterType === "equal"}
        step={props.step || 1}
        onChange={setValues}
      />
    </>
  );
};

export default FilterNumber;
