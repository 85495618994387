import { useState, useContext } from "react";
import { AppContext } from "../../globals/appcontext";

export interface GoLiveSession {
  prev: () => void;
  next: () => void;
  getLocalData: () => GoLiveSessionData;
  setLocalData: (data: GoLiveSessionData) => void;
  data: GoLiveSessionData;
}

export interface GoLiveSessionData {
  step: number;
}

export const useGoLiveSession = (): GoLiveSession => {
  const appContext = useContext(AppContext);
  const corrId = appContext.correspondent.ID;
  const storageKey = `go-live-${corrId}`;

  const getLocalData = () => {
    const item = localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : { step: 0 };
  };

  const setLocalData = (data: GoLiveSessionData) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
    setData(data);
  };
  const [data, setData] = useState<GoLiveSessionData>(getLocalData());

  const prev = () =>
    setLocalData({
      ...data,
      step: Math.max(data.step - 1, 0),
    });

  const next = () => {
    setLocalData({
      ...data,
      step: data.step + 1,
    });
    if (data.step === 2) {
      localStorage.setItem("go-live-submitted", "true");
    }
  };

  return {
    data,
    prev,
    next,
    getLocalData,
    setLocalData,
  };
};
