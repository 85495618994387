import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
  BoxProps,
} from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";
import { confirmSignUp } from "../../auth/paciam";

interface LocationState {
  name?: string;
  email?: string;
  password?: string;
  unverified?: boolean;
}

const Verification = (props: BoxProps): React.ReactElement => {
  const { state } = useLocation();
  const locationState: LocationState = state as LocationState;

  const toast = useToast();
  const [email, setEmail] = useState(locationState?.email || "");
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const unverified = locationState?.unverified;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    confirmSignUp(
      email,
      code,
      locationState?.name || "",
      locationState?.password || ""
    )
      .then(() => {
        toast({
          title:
            "Account Verified" +
            (unverified ? ". You can now reset your password." : ""),
          status: "success",
        });
        if (unverified) {
          localStorage.setItem("unverified-account", "true");
        }
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: "An error occured",
          description: e.message,
          status: "error",
        });
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to="/mfa" />;
  }

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Text fontSize="4xl" mb="2rem">
            Enter verification code
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                placeholder="Email"
                size="lg"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Code</FormLabel>
              <Input
                variant="filled"
                type="text"
                placeholder="Code"
                size="lg"
                onChange={(event) => setCode(event.currentTarget.value)}
              />
            </FormControl>
            <Button
              size="lg"
              type="submit"
              width="full"
              mt="4rem"
              isLoading={isLoading}
            >
              Ok
            </Button>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default Verification;
