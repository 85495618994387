import React, { useMemo, useState } from "react";

import moment from "moment";

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";

import { AppContext } from "../../globals/appcontext";
import { TOTPContainer } from "./MFA";
import { enableMfa, verifyMfa } from "../../auth/paciam";

const MFA_TYPE = "totp";

// Any user who is after this date will be force to enable MFA
// for security reasons
const MFA_ENFORCED_DATE = "2023-10-02";

export const MFAGuard: React.FC = ({ children }) => {
  const appContext = React.useContext(AppContext);
  const [wasClosed, setWasClosed] = useState(false);

  const onCloseModal = () => setWasClosed(true);

  const isMFARequired = useMemo(
    () =>
      Boolean(
        appContext.self &&
          appContext.self.mfa_type !== MFA_TYPE &&
          moment(appContext.self.created_at).isAfter(MFA_ENFORCED_DATE, "day")
      ),
    [appContext.self]
  );

  return (
    <>
      <Modal
        isOpen={isMFARequired && !wasClosed}
        onClose={onCloseModal}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding="3rem 2rem">
            <MFAModal />
          </ModalBody>
        </ModalContent>
      </Modal>
      {children}
    </>
  );
};

const MFAModal = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const handleOpenModal = async () => {
    enableMfa()
      .then(({ url }) => {
        if (!url) {
          throw new Error("QR Code could not be generated");
        }
        setQrCode(url);
      })
      .catch((e) => {
        toast({
          title: "An error occured",
          description: e.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitCode = async (mfaCode: string) => {
    setIsLoading(true);

    verifyMfa(mfaCode)
      .then(() => {
        toast({
          title: "MFA Enabled",
          status: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 400);
      })
      .catch((e) => {
        toast({
          title: "An error occured",
          description: e.message,
          status: "error",
        });
        setIsLoading(false);
      });
  };

  if (qrCode) {
    return <TOTPContainer qrCode={qrCode} submitMFA={onSubmitCode} />;
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Text fontSize="2xl" textAlign="center">
        Enable Time-based, One-Time Password Multi-Factor Authentication
      </Text>
      <Button
        size="lg"
        width="300px"
        mt="2rem"
        onClick={handleOpenModal}
        isLoading={isLoading}
      >
        Enable
      </Button>
    </Flex>
  );
};
