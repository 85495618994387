import React, { useState } from "react";

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useMutation } from "react-query";
import { DeleteIcon } from "@chakra-ui/icons";
import { removeTeamMember } from "../../api/api";

import type { AxiosError } from "axios";
import type { TeamMember } from "../../api/types";

interface DeleteTeamMemberProps {
  onSidebarClose: () => void;
  selectedMember: TeamMember | null;
}

const DeleteTeamMember = (props: DeleteTeamMemberProps): JSX.Element => {
  const { onSidebarClose, selectedMember } = props;

  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const mutation = useMutation<void, AxiosError, string>(
    (selectedID: string) => removeTeamMember(selectedID),
    {
      onSuccess: () => {
        onSidebarClose();
        toast({
          title: "Team member removed",
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: "Unable to remove team member",
          description: err.message,
          status: "error",
        });
      },
    }
  );

  const onConfirm = () => {
    if (selectedMember) {
      mutation.mutate(selectedMember.id);
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button
        color="red.500"
        colorScheme="red"
        leftIcon={<DeleteIcon />}
        mt="1.5rem"
        onClick={onOpen}
        variant="ghost"
        width="100%"
      >
        Delete this Team Member
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are You Sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg">
              Are you sure you want to delete this team member?
            </Text>
            {selectedMember && (
              <Box my="15px">
                <Text opacity={0.8}>Name</Text>
                <Text fontSize="lg" mb="15px" whiteSpace="pre">
                  {selectedMember.name ?? " "}
                </Text>
                <Text opacity={0.8}>Email</Text>
                <Text fontSize="lg" mb="15px" whiteSpace="pre">
                  {selectedMember.email ?? " "}
                </Text>
                <Text opacity={0.8}>Role</Text>
                <Text fontSize="lg" whiteSpace="pre">
                  {selectedMember.role ?? ""}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="flex-start" columnGap={3}>
            <Button flex={1} color="light" variant="filled" onClick={onClose}>
              Cancel
            </Button>
            <Button
              flex={1}
              isDisabled={!selectedMember || mutation.isLoading}
              isLoading={mutation.isLoading}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteTeamMember;
