// external links
export const BrokerAPIDocSrc =
  "https://alpaca.markets/docs/api-references/broker-api/";
export const BrokerAPIIntegrationDocs = "https://alpaca.markets/docs/broker/";
export const StatusPage = "https://status.alpaca.markets/";
export const SupportPage = "https://alpaca.markets/support";
export const TradingAPISrc = "https://app.alpaca.markets/login";

export const APILinks = {
  accounts: BrokerAPIDocSrc + "accounts/accounts",
  funding: BrokerAPIDocSrc + "funding/transfers",
  orders: BrokerAPIDocSrc + "trading/orders",
  firm: BrokerAPIIntegrationDocs + "integration/#firm-accounts",
  transactions: BrokerAPIDocSrc + "funding/transfers",
  journals: BrokerAPIDocSrc + "journals",
  documents: BrokerAPIDocSrc + "documents",
  positions: BrokerAPIDocSrc + "trading/positions",
  activities: BrokerAPIDocSrc + "accounts/account-activities",
};

// emails
export const SupportEmail = "brokersupport@alpaca.markets";
export const ADMIN_SUPPORT_EMAIL = "support@alpaca.markets";
