const colors = {
  bgLight: "rgb(244,246,251)",
  bgDark: "#171719",
  cardBackgroundDark: "#24262C",
  cardBackgroundLight: "#E6E9ED",
  gray: "gray",
  nestedBGDark: "#35383D",
  nestedBGLight: "#F1F2F5",
  iconYellowBg: "#F6D000",
  iconGrayBg: "#E6E9ED",
  iconDarkGrayBg: "#24262C",
  yellow: "rgba(239, 202, 0, 1)",
  yellow100: "rgba(239, 202, 0, 0.1)",
  lightGreen: "rgba(0, 223, 89, 0.8)",
  lightBlue: "rgba(0, 108, 203, 0.7)",
  lightOrange: "#E18905",
  pieChart: ["#1473C6", "#CCE2F5", "#D600C0", "#885FFE", "#21CFCF"],
  gold: "#f6D000",
};

export default colors;
