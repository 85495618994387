import React, { useMemo, useState } from "react";
import { CountryRiskRating, CountryRiskType } from "../../api/types";
import useCountryRisk from "../hooks/useCountryRisk";
import { Table } from "@alpacahq/alpaca-component-library";
import { Column } from "react-table";
import {
  Box,
  Flex,
  Input,
  InputLeftElement,
  InputGroup,
  useColorModeValue,
  Spacer,
  Badge,
  ThemingProps,
} from "@chakra-ui/react";

import { FilterDrawer, FilterSelect, FilterButton } from "../filter";
import { Search2Icon } from "@chakra-ui/icons";

interface FilterType {
  risk_rating: CountryRiskRatingInputType | string;
}

type CountryRiskRatingInputType = CountryRiskRating | "all" | null;

const DEFAULT_PAGE_ITEM = 25;

const RISK_RATING_OPTIONS = [
  "all",
  "low",
  "medium",
  "high",
  "restricted",
  "prohibited",
];

const RISK_RATING_BADGE_COLOR: Record<
  typeof RISK_RATING_OPTIONS[number],
  ThemingProps<"Badge">["colorScheme"]
> = {
  all: "gray",
  low: "green",
  medium: "yellow",
  high: "orange",
  restricted: "red",
  prohibited: "red",
};

const DEFAULT_FILTER_INPUTS: FilterType = {
  risk_rating: null,
};

const CountryRiskTable = (): React.ReactElement => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<FilterType>(DEFAULT_FILTER_INPUTS);
  const [inputs, setInputs] = useState<FilterType>(DEFAULT_FILTER_INPUTS);

  const { data, isLoading } = useCountryRisk();

  const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  const handleOnSelect = (value: string): void => {
    setInputs({ risk_rating: value });
  };

  const onApplyFilters = () => {
    setFilters(inputs);
    handleOnCloseFilter();
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(true);
  };

  const handleOnCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleRemoveFilter = (key: string) => {
    setFilters({ ...inputs, [key]: null });
  };

  const countryRiskWithISOCode = useMemo(() => {
    if (data) {
      return Object.keys(data).map((key) => ({
        iso_code: key,
        ...data[key],
      }));
    }

    return [];
  }, [data]);

  const columns: Column<CountryRiskType>[] = [
    {
      id: "iso_code",
      Header: "Code",
      accessor: "iso_code",
      width: 100,
    },
    {
      id: "full_name",
      Header: "Country Name",
      accessor: "full_name",
    },
    {
      accessor: "risk_rating",
      Header: "Equities Risk Rating",
      Cell: ({ value }) => (
        <Badge
          colorScheme={RISK_RATING_BADGE_COLOR[value]}
          textTransform="capitalize"
        >
          {value}
        </Badge>
      ),
    },
    {
      accessor: "crypto_risk_rating",
      Header: "Crypto Risk Rating",
      Cell: ({ value }) => (
        <Badge
          colorScheme={RISK_RATING_BADGE_COLOR[value]}
          textTransform="capitalize"
        >
          {value}
        </Badge>
      ),
    },
  ];

  return (
    <Box>
      <Flex alignItems="center">
        <Spacer />
        <InputGroup maxWidth="250px" size="md">
          <InputLeftElement pointerEvents="none" fontSize="1.2em">
            <Search2Icon color={useColorModeValue("dark.900", "light.900")} />
          </InputLeftElement>

          <Input
            bg="gray.200"
            name="search"
            placeholder="Search"
            onChange={handleOnSearch}
            variant="filled"
          />
        </InputGroup>

        <FilterButton
          filterPills={filters ?? {}}
          openFilter={handleOpenFilter}
          removeFilter={handleRemoveFilter}
        />
      </Flex>

      <FilterDrawer
        isOpen={isFilterOpen}
        onClose={handleOnCloseFilter}
        onApply={onApplyFilters}
      >
        <FilterSelect
          header="Risk"
          options={RISK_RATING_OPTIONS}
          onSelect={handleOnSelect}
          selected={filters?.risk_rating ?? "all"}
        />
      </FilterDrawer>

      <Table
        columns={columns}
        filterByColumnValues={filters}
        data={countryRiskWithISOCode}
        defaultPerPage={DEFAULT_PAGE_ITEM}
        filterGlobalValue={search}
        filterGlobalColumns={["iso_code", "full_name"]}
        pagination
        isLoading={isLoading}
        paginationPosition="top"
      />
    </Box>
  );
};

export default CountryRiskTable;
