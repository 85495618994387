import AppContextProvider from "./globals/appcontext";
import InitSentry from "./globals/sentry";
import Layout from "./components/layout/Layout";
import Balance from "./pages/Balance";
import Dashboard from "./pages/Dashboard";
import Devs from "./pages/Devs";
import Documents from "./pages/Documents";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import AuthPage from "./pages/Auth";
import awsConfig from "./auth/awsConfig";
import User from "./pages/User";
import TeamSettingsPage from "./pages/teamSettings";
import Journals from "./pages/Journals";
import Orders from "./pages/Orders";
import Assets from "./pages/Assets";
import NotFound from "./pages/NotFound";
import LiveTesting from "./pages/LiveTesting";
import FirmDetailsPage from "./pages/FirmDetails";
import GoLive from "./pages/go-live/GoLive";
import CountryRisk from "./pages/CountryRisk";
import QuickStart from "./pages/quick-start/QuickStart";
import HealthCheck from "./globals/healthcheck";
import Launch from "./pages/Launch";
import Reports from "./pages/reports";

import React, { ReactElement } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Auth } from "aws-amplify";
import { theme } from "./components/theme/Theme";
import { ChakraProvider } from "@chakra-ui/react";
import { IntercomProvider } from "react-use-intercom";
import { QueryClient, QueryClientProvider } from "react-query";
import CorporateActions from "./pages/corporateActions";
import { useLogPageView } from "./components/hooks/useLogPageVisit";
import { MFAGuard } from "./components/auth/MFAGuard";

// Cognito ID
Auth.configure(awsConfig);

InitSentry();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 30000,
    },
  },
});

export const App: React.FC = () => {
  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID || ""}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AppContextProvider>
            <Router>
              <RoutesList />
            </Router>
          </AppContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </IntercomProvider>
  );
};

export const RoutesList: React.FC = () => {
  const nav = (child: ReactElement) => <Layout>{child}</Layout>;

  return (
    <>
      {useLogPageView()}
      <Routes>
        <Route path="/launch-guide" element={nav(<Launch />)} />
        <Route path="/go-live" element={nav(<GoLive />)} />

        <Route
          path="/dashboard"
          element={nav(
            <MFAGuard>
              <Dashboard />
            </MFAGuard>
          )}
        />

        <Route path="/balance" element={nav(<Balance />)} />
        <Route path="/balance/:accountID" element={nav(<FirmDetailsPage />)} />

        <Route path="/accounts" element={nav(<Users />)} />
        <Route path="/accounts/:accountID" element={nav(<User />)} />

        <Route
          path="/transactions"
          element={nav(<Navigate to="/transactions/transfers" replace />)}
        />
        <Route path="/transactions/transfers" element={nav(<Transactions />)} />
        <Route path="/transactions/orders" element={nav(<Orders />)} />
        <Route path="/transactions/assets" element={nav(<Assets />)} />
        <Route path="/transactions/journals" element={nav(<Journals />)} />

        <Route path="/reports" element={nav(<Reports />)} />

        <Route path="/country-risk" element={nav(<CountryRisk />)} />

        <Route path="/documents" element={nav(<Documents />)} />

        <Route path="/dev" element={nav(<Devs />)} />
        <Route path="/dev/quick-start" element={nav(<QuickStart />)} />
        <Route path="/dev/live-testing" element={nav(<LiveTesting />)} />

        <Route path="/corporate-actions" element={nav(<CorporateActions />)} />

        <Route path="/team-settings" element={nav(<TeamSettingsPage />)} />
        <Route path="/login" element={<AuthPage type="login" />} />
        <Route path="/sign-up" element={<AuthPage type="signup" />} />
        <Route
          path="/verification"
          element={<AuthPage type="verification" />}
        />
        <Route
          path="/forgot-password"
          element={<AuthPage type="forgot-password" />}
        />
        <Route
          path="/set-password"
          element={<AuthPage type="set-password" />}
        />
        <Route path="/mfa" element={<AuthPage type="mfa" />} />
        <Route path="/healthz" element={<HealthCheck />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
};
