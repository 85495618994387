import React, { useEffect } from "react";
import { VStack, Text, Button, Box, Image } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import greenCheckHex from "../../assets/images/greenCheckHex.svg";

const GuideComplete = (): React.ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    // sends them back to the guide in the now completed "locked" state
    setInterval(() => {
      window.location.reload();
    }, 5000);
  }, []);

  return (
    <Box mt="3rem" maxWidth="90%">
      <VStack spacing={10}>
        <Text fontSize="24px" fontWeight="bold">
          Congratulations you have completed the Launch Guide! 🎉
        </Text>
        <Image src={greenCheckHex} width="400" />
        <Button
          onClick={() => navigate("/dashboard")}
          rightIcon={<ArrowForwardIcon />}
        >
          Return to Dashboard
        </Button>
      </VStack>
    </Box>
  );
};

export default GuideComplete;
