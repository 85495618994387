import React from "react";
import Header from "../components/layout/Header";
import CountryRiskTable from "../components/tables/CountryRiskTable";

const CountryRisk = (): React.ReactElement => {
  return (
    <>
      <Header title="Country Risk" />
      <CountryRiskTable />
    </>
  );
};

export default CountryRisk;
