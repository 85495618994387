import React, { useMemo } from "react";

import {
  Box,
  Text,
  Button,
  Collapse,
  VStack,
  useToast,
  List,
  ListItem,
  Flex,
  Checkbox,
  HStack,
} from "@chakra-ui/react";

import { SANDBOX_TASKS } from "./constants";
import { EventType, getAmplitude } from "../../globals/amplitude";
import useLocalStorage from "../hooks/useLocalStorage";

type SandboxProps = {
  step: number;
  handleCompleteStatus: (index: number) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  readOnly: boolean;
  corr?: string;
  isLoading: boolean;
};

const DEFAULT_SANDBOX_TASK_VALUE = Array(SANDBOX_TASKS.length).fill(false);

const Sandbox = ({
  step,
  handleCompleteStatus,
  setStep,
  readOnly,
  corr,
  isLoading,
}: SandboxProps): React.ReactElement => {
  const LAUNCH_GUIDE_SANDBOX_KEY = `launch-guide-sandbox-tasks-${corr}`;

  const [checkedValues, setCheckedValues] = useLocalStorage<boolean[]>(
    LAUNCH_GUIDE_SANDBOX_KEY,
    DEFAULT_SANDBOX_TASK_VALUE,
    isLoading
  );

  const toast = useToast();
  const onClick = () => {
    handleCompleteStatus(1);
    setStep(2);
    toast({ title: "Progress saved!", status: "success" });
    getAmplitude().track({ event_type: EventType.SANDBOX_COMPLETE });
  };

  const onCheckboxClick = (idx: number) => {
    setCheckedValues((existingItems) => {
      const updatedItems = [...existingItems];

      updatedItems[idx] = !updatedItems[idx];

      getAmplitude().track({
        event_type: updatedItems[idx]
          ? EventType.SANDBOX_CHECKBOX_CHECKED
          : EventType.SANDBOX_CHECKBOX_UNCHECKED,
      });
      return updatedItems;
    });
  };

  const tasksCompleted = useMemo(() => {
    const numTasksCompleted = checkedValues.filter((value) => value).length;
    return `${numTasksCompleted} / ${SANDBOX_TASKS.length} tasks completed`;
  }, [checkedValues]);

  return (
    <Box>
      <HStack spacing={5} mb={1}>
        <Text fontSize="xl">Sandbox</Text>
        {checkedValues.some((v) => v) && !readOnly && (
          <Text fontSize="lg" color="grey">
            {tasksCompleted}
          </Text>
        )}
      </HStack>
      <Collapse in={step === 1 || readOnly}>
        <VStack alignItems="start" spacing={3}>
          <Text>
            In the sandbox environment, you have an unlimited testing
            environment to build, test and launch your app. You have the ability
            to safely experiment with our set of Broker APIs, while being
            compliant with applicable rules and regulations.
          </Text>
          <Text>
            The first step to building your app and starting the integration is
            to generate a sandbox key.
          </Text>
          <List spacing={3} pl="4">
            {SANDBOX_TASKS.map((val, idx) => (
              <ListItem key={idx}>
                <Flex alignItems={"start"}>
                  <Checkbox
                    isChecked={checkedValues[idx]}
                    onChange={() => onCheckboxClick(idx)}
                  />
                  <Box ml="1rem">
                    <Text mb="0.25rem">{val.title}</Text>
                  </Box>
                </Flex>
              </ListItem>
            ))}
          </List>
          <Box>
            {!readOnly && (
              <Button
                disabled={checkedValues.some((v) => !v)}
                onClick={onClick}
              >
                Next
              </Button>
            )}
          </Box>
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Sandbox;
