import React from "react";
import styled from "styled-components";
import CopyButton from "../general/CopyButton";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getMarginAccount } from "../../api/api";
import { fmtLCTMoney } from "../../globals/utils";
import { Flex, Grid, GridItem, Stack, Text } from "@chakra-ui/react";

const Header = styled(Text)`
  font-weight: 500;
  font-size: 1.1rem;
  opacity: 90%;
`;

const FieldName = styled(Text)`
  font-size: 1.125rem;
  opacity: 70%;
  white-space: nowrap;
`;

const FieldValue = styled(Text)`
  flex: 1.5;
  margin-left: 0.5rem;
  text-align: right;
  white-space: nowrap;
`;

const AccountMargin = (): JSX.Element => {
  const { accountID } = useParams();
  const { data: account } = useQuery(["margin-account", accountID], () =>
    getMarginAccount(accountID)
  );
  const currency = account?.currency;

  return (
    <Flex>
      <Grid gap={8} flex="0.5">
        <GridItem as={Stack} gap="10px">
          <Header>Account (Last Close)</Header>
          <Flex>
            <FieldName>Cash</FieldName>
            <FieldValue>{fmtLCTMoney(account?.last_cash, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Equity</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.last_equity, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Multiplier</FieldName>
            <FieldValue>{account?.multiplier}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Maintenance Margin</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.last_maintenance_margin, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Day Trade Count</FieldName>
            <FieldValue>{account?.last_daytrade_count}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Balance As Of</FieldName>
            <FieldValue>{account?.balance_asof}</FieldValue>
          </Flex>
        </GridItem>
        <GridItem as={Stack} gap="10px">
          <Header>Margin (Real Time)</Header>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="calc(100% + 16px)"
          >
            <FieldName>Account ID</FieldName>
            <CopyButton
              name="Account ID"
              value={account?.id || ""}
              lightColor="brand"
              isTruncated
              showTooltip
            />
          </Flex>
          <Flex>
            <FieldName>Trade Cash</FieldName>
            <FieldValue>{fmtLCTMoney(account?.cash, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Long Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.long_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Short Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.short_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Position Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.position_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Equity</FieldName>
            <FieldValue>{fmtLCTMoney(account?.equity, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Initial Margin</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.initial_margin, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Maintenance Margin</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.maintenance_margin, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Regt Buying Power</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.regt_buying_power, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Dtbp Buying Power</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.daytrading_buying_power, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Bod Dtbp</FieldName>
            <FieldValue>{fmtLCTMoney(account?.bod_dtbp, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Effective Buying Power</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.effective_buying_power, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Non-Marginable Buying Power</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.non_marginable_buying_power, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Sma</FieldName>
            <FieldValue>{fmtLCTMoney(account?.sma, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>JIT Cash</FieldName>
            <FieldValue>{fmtLCTMoney(account?.jit_cash, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Memoposts</FieldName>
            <FieldValue>{fmtLCTMoney(account?.memoposts, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Intraday Adjustments</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.intraday_adjustments, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Crypto Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.crypto_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Long Securities Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.long_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Short Securities Market Value</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.short_market_value, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Net Unsettled Prev</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.net_unsettled_prev, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Net Unsettled Now</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.net_unsettled_now, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Unsettled Payable</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.unsettled_payable, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Unsettled Receivable</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.unsettled_receivable, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Proj Settled Cash</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.proj_settled_cash, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Available Funds</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.available_funds, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Cash Withdrawable Margin</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.cash_withdrawable_margin, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Equity Securities</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.equity_securities, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Marginable Mv</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.marginable_mv, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Non-Marginable Mv</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.non_marginable_mv, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Pending Maintenance Margin</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.pending_maintenance_margin, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Adj Mr</FieldName>
            <FieldValue>{fmtLCTMoney(account?.adj_mr, currency)}</FieldValue>
          </Flex>
          <Flex>
            <FieldName>Pending Cash Transfers</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.pending_transfer_in, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Cash Withdrawable</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.cash_withdrawable, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Cash Transferable</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.cash_transferable, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Pending Transfer Out</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.pending_transfer_out, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>JIT Cash Adjustment</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.jit_cash_adjustment, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Day Transaction Debit</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.day_transaction_debit, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Day Transaction Credit</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.day_transaction_credit, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Settled Cash</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.settled_cash, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Settled Cash Adjusted</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.settled_cash_adjusted, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Excess Equity</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.excess_equity, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Intraday Trade Cashflow</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.intraday_trade_cashflow, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Recent Cash Transfers</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.recent_cash_transfers, currency)}
            </FieldValue>
          </Flex>
          <Flex>
            <FieldName>Cost Basis</FieldName>
            <FieldValue>
              {fmtLCTMoney(account?.cost_basis, currency)}
            </FieldValue>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default AccountMargin;
