import numeral from "numeral";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { QueryKey, useQuery } from "react-query";
import { getActivities } from "../../api/api";
import Table from "../general/Table";
import { Box } from "@chakra-ui/react";
import {
  RangeType,
  FilterButton,
  FilterDateSelect,
  FilterDrawer,
  FilterSelect,
  dateRangeToPill,
} from "../filter";
import { activityTypes, ListActivitiesParams } from "../../api/types";
import { APILinks } from "../../globals/consts";
import { fmtLCTMoney } from "../../globals/utils";

interface ActivitiesTableProps {
  accountID: string;
  nonTradeOnly: boolean;
  currency?: string;
}

const ActivitesTable = (props: ActivitiesTableProps): React.ReactElement => {
  const aID = props.accountID;

  // Filter
  const [filterOpen, setFilterOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState("all");
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(["activities", aID]);
  const [applyFilter, setApplyFilter] = useState(false);

  let headers = [
    "Activity Type",
    "Activity ID",
    "Date",
    "Amount",
    "Description",
  ];
  if (!props.nonTradeOnly) {
    headers = headers.concat(["Symbol", "Qty", "$/Share"]);
  }

  const activitiesQuery = useQuery(filterKey, () => {
    const params: ListActivitiesParams = {
      account_id: aID,
    };

    if (selectedDates[0] && selectedDates[1]) {
      // Supposed we want to retrieve activities from 2022-09-09 (inclusive) to 2022-09-10 (inclusive),
      // we must set the query parameters `after` to 2022-09-09 00:00:00 and `until` to 2022-09-11 00:00:00

      params.after = moment
        .tz(selectedDates[0].format("YYYY-MM-DD HH:mm:ss"), "America/New_York")
        .startOf("day")
        .format();

      params.until = moment
        .tz(selectedDates[1].format("YYYY-MM-DD HH:mm:ss"), "America/New_York")
        .startOf("day")
        .add(1, "day")
        .format();
    }

    return getActivities(params, typeFilter !== "all" ? typeFilter : undefined);
  });

  const queryKeys = ["activities", typeFilter, selectedDates, aID];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const removeFilter = (filterID: string) => {
    if (filterID === "type") setTypeFilter("all");
    if (filterID === "dates") setSelectedDates([null, null]);
  };

  const filterPills = {
    type: typeFilter,
    dates: dateRangeToPill(selectedDates),
  };

  const displayActID = (id: string) => id.replace(/^[^:]+::/g, "");
  const activityRows = (activitiesQuery.data || []).map((a) => {
    const isSell = (a.side || "").toLowerCase() === "sell";
    const netAmt =
      a.activity_type === "MEM"
        ? a.qty
        : a.net_amount || (isSell ? -1 : 1) * (a.qty || 0) * (a.price || 0);
    const rows = [
      a.activity_type || "",
      displayActID(a.id || "-"),
      a.transaction_time || a.date || "",
      fmtLCTMoney(netAmt, props.currency),
      a.description || "",
    ];
    if (props.nonTradeOnly) return rows;
    return rows.concat([
      a.symbol || "-",
      a.qty ? numeral(isSell ? -a.qty : a.qty).format("0,0.[00]") : "-",
      a.price ? fmtLCTMoney(a.price, props.currency) : "-",
    ]);
  });

  return (
    <>
      <Box mt="-6.5rem" float="right">
        <FilterButton
          filterPills={applyFilter ? filterPills : {}}
          openFilter={() => {
            setFilterOpen(true);
            setApplyFilter(false);
          }}
          removeFilter={removeFilter}
        />
      </Box>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Type"
          options={["all", ...activityTypes]}
          noOptionFmt
          onSelect={setTypeFilter}
          selected={typeFilter}
        />
        <FilterDateSelect
          header="Updated At"
          onDateSelect={(st, ed) => setSelectedDates([st, ed])}
        />
      </FilterDrawer>
      <Table
        isLoading={activitiesQuery.isLoading}
        headers={headers}
        copyIndexes={[1]}
        rows={activityRows}
        columnMaxWidths={[null, null, null, null, 50]}
        noContentTitle="No activities for this account"
        noContentLinkText="Learn more about Activities"
        noContentLinkDestination={APILinks.activities}
      />
    </>
  );
};

export default ActivitesTable;
