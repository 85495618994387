import React from "react";
import Header from "../components/layout/Header";
import AccountDocumentsTable from "../components/tables/AccountDocumentsTable";

const Documents = (): React.ReactElement => {
  return (
    <>
      <Header title="Documents" />
      <AccountDocumentsTable />
    </>
  );
};

export default Documents;
