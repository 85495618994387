import Live from "../components/launch/Live";
import Welcome from "../components/launch/Welcome";
import Sandbox from "../components/launch/Sandbox";
import LimitedLive from "../components/launch/LimitedLive";
import GuideComplete from "../components/launch/GuideComplete";

import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "react-query";
import { StepStatus } from "../globals/types";
import { Text } from "@chakra-ui/react";
import { getCorrespondent, updateCorrespondent } from "../api/api";
import { EventType, getAmplitude } from "../globals/amplitude";
import useLocalStorage from "../components/hooks/useLocalStorage";
import ProgressBar from "../components/general/ProgressBar";
import { AnimatePresence, motion } from "framer-motion";

const PROGRESS_STEPS = [
  {
    label: "Welcome",
    key: "welcome",
  },
  {
    label: "Sandbox",
    key: "sandbox",
  },
  { label: "Limited Live Requirements", key: "limited_live" },
  { label: "Live Requirements", key: "live" },
];

const DEFAULT_STEP_STATUS = [
  StepStatus.Started,
  StepStatus.Incomplete,
  StepStatus.Incomplete,
  StepStatus.Incomplete,
];

const Launch = (): React.ReactElement => {
  const { data, isLoading, status } = useQuery(
    "correspondent",
    getCorrespondent
  );

  const isReadOnly = Boolean(data?.bd_data?.launch_guide_partner_type);
  const STATUS_KEY = `launch-guide-step-status-${data?.correspondent}`;

  const [step, setStep] = useState(0);
  const [guideComplete, setGuideComplete] = useState(false);
  const [stepStatus, setStepStatus] = useLocalStorage(
    STATUS_KEY,
    DEFAULT_STEP_STATUS,
    isLoading || status === "error"
  );

  const handleCompleteStatus = (index: number, key?: string) => {
    const status = [...stepStatus];
    status[index] = StepStatus.Completed;

    // check if next step wasn't already complete
    if (status[index + 1] !== StepStatus.Completed) {
      status[index + 1] = StepStatus.Started;
    }

    // correspondent query gives an error until after welcome step is completed
    // Welcome step passes in the local storage key with the correspondent
    if (key) {
      localStorage.setItem(key, JSON.stringify(status));
    }
    setStepStatus(status);
  };

  const handleStepChange = (index: number) => {
    if (stepStatus[index] > StepStatus.Incomplete) {
      setStep(index);
    }
  };

  const handleGuideComplete = async () => {
    const getItem = (key: string) =>
      JSON.parse(localStorage.getItem(`launch-guide-${key}`) || "{}");

    const { correspondent, bd_data, ...fields } = data || {};

    const { name, business_type, user_countries, url } = getItem(
      `welcome-${correspondent}`
    );

    const { target_investor, waitlist, has_waitlist } = getItem(
      `optional-${correspondent}`
    );

    try {
      await updateCorrespondent({
        ...fields,
        BdData: {
          ...bd_data,
          url,
          waitlist:
            has_waitlist === "yes"
              ? waitlist
              : has_waitlist === "no"
              ? "0"
              : undefined,
          user_countries: user_countries.map((c: { value: string }) => c.value),
          target_investor: target_investor.length ? target_investor : undefined,
          launch_guide_partner_type:
            localStorage.getItem("launch-guide-live-partner-type") ||
            "RIA (U.S)",
        },
        Name: name,
        BusinessType: business_type,
      }).then(() => {
        getAmplitude().track({ event_type: EventType.LIVE_COMPLETE });
        setGuideComplete(true);
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const cache = localStorage.getItem(STATUS_KEY);
    if (cache) {
      const parsed: StepStatus[] = JSON.parse(cache);
      setStepStatus(parsed);
      setStep(parsed.indexOf(StepStatus.Started));
    }
  }, [STATUS_KEY]);

  const steps: (JSX.Element | undefined)[] = [
    <Welcome
      key="0"
      step={step}
      handleCompleteStatus={handleCompleteStatus}
      setStep={setStep}
      readOnly={isReadOnly}
      corr={data?.correspondent || ""}
    />,
    <Sandbox
      key="1"
      step={step}
      handleCompleteStatus={handleCompleteStatus}
      setStep={setStep}
      readOnly={isReadOnly}
      corr={data?.correspondent || ""}
      isLoading={isLoading}
    />,
    <LimitedLive
      key="2"
      step={step}
      handleCompleteStatus={handleCompleteStatus}
      setStep={setStep}
      readOnly={isReadOnly}
      corr={data?.correspondent || ""}
      isLoading={isLoading}
    />,
    <Live
      key="3"
      step={step}
      handleGuideComplete={handleGuideComplete}
      readOnly={isReadOnly}
      corr={data?.correspondent || ""}
    />,
  ];

  const stepInProgress = useMemo(() => {
    return stepStatus.indexOf(StepStatus.Started);
  }, [stepStatus]);

  const progressBarSteps = useMemo(
    () =>
      PROGRESS_STEPS.map((steps, index) => ({
        ...steps,
        onClick:
          stepStatus[index] > StepStatus.Incomplete
            ? () => handleStepChange(index)
            : undefined,
      })),
    [stepStatus]
  );

  return (
    <div className="p-16">
      <Text className="text-4xl font-bold">Launch Guide</Text>

      {guideComplete ? (
        <GuideComplete />
      ) : (
        <div className="mt-16">
          <ProgressBar steps={progressBarSteps} current={stepInProgress} />
          {steps.map((s, idx) =>
            idx === step ? (
              <AnimatePresence key={s?.key}>
                <motion.div
                  className="mt-16"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {s}
                </motion.div>
              </AnimatePresence>
            ) : undefined
          )}
        </div>
      )}
    </div>
  );
};

export default Launch;
