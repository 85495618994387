import React from "react";
import Playground from "../../../components/general/Playground";

import { Session } from "../session";
import { Box, Button, Text } from "@chakra-ui/react";
import { EventType, getAmplitude } from "../../../globals/amplitude";

const MilestoneOne = ({
  data,
  next,
  setLocalData,
}: Session): React.ReactElement => {
  const onResponse = (response) => {
    // we only want to cache the first 100 assets, otherwise the session will be too big
    setLocalData({
      ...data,
      response: JSON.stringify(response.slice(0, 100)),
      canMoveOn: true,
    });
  };

  return (
    <Box maxW="1000px">
      <Text fontSize="xl" fontWeight="medium">
        API Keys
      </Text>
      <Text mt=".5rem">
        When you sign up for an account at Alpaca you will receive an{" "}
        <code>API_KEY</code> and <code>API_SECRET</code>, please make sure you
        store those somewhere safe.
      </Text>
      <Text mt=".5rem">
        Broker API must authenticate using HTTP Basic authentication. Use your
        correspondent <code>API_KEY</code> and <code>API_SECRET</code> as the
        username and password. The format is <code>key:secret</code>. Encode the
        string with base-64 encoding, and you can pass it as an authentication
        header.
      </Text>
      <Text fontSize="xl" fontWeight="medium" mt="1rem">
        Making Your First Request
      </Text>
      <Text mt=".5rem" mb="2rem">
        At this point we can assume that you haven&apos;t created any accounts
        yet, but one of the first API calls you can make is{" "}
        <code>GET /v1/assets</code>, which doesn&apos;t require a request body
        and will give you all the assets available at Alpaca. A request body is
        not needed to make this call. Click send to get started.
      </Text>
      <Playground
        key="milestone-one"
        method="GET"
        endpoint="/v1/assets"
        readOnly={true}
        response={data.response}
        disabled={data.canMoveOn}
        onResponse={onResponse}
        step="step-1"
      />
      <Box textAlign="right" my="2rem">
        <Button
          disabled={!data.canMoveOn}
          onClick={() => {
            next();
            getAmplitude().track({
              event_type: EventType.QUICKSTART_STEP2,
            });
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default MilestoneOne;
