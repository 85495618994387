import { Account } from "../../api/types";
import { getFakeAccount } from "../../api/api";
import { useEffect, useState } from "react";

export interface Session {
  next: () => void;
  prev: () => void;
  reset: () => void;
  moveOn: () => void;
  getLocalData: () => SessionData;
  setLocalData: (data: SessionData) => void;
  setPolling: (polling: boolean) => void;
  polling: boolean;
  data: SessionData;
}

export interface SessionData {
  step: number;
  timeout?: NodeJS.Timeout;
  canMoveOn?: boolean;
  account?: Account;
  response?: string;
  relationshipId?: string;
}

export const useSession = (): Session => {
  const storageKey = `quick-start-session`;

  const getLocalData = () => {
    const item = localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : { step: 0 };
  };

  const setLocalData = (data: SessionData) => {
    localStorage.setItem(storageKey, JSON.stringify(data));
    setData(data);
  };

  const [data, setData] = useState<SessionData>(getLocalData());
  const [polling, setPollInProgress] = useState(false);

  const setPolling = (polling: boolean) => {
    setPollInProgress(polling);
  };

  useEffect(() => {
    const generate = async () =>
      setLocalData({ ...data, account: await getFakeAccount() });

    // if account is not set, do so now by fetching a fake account
    if (!data.account) {
      generate();
    }
  }, [data]);

  const moveOn = () => setLocalData({ ...data, canMoveOn: true });

  const next = () =>
    setLocalData({
      ...data,
      response: undefined,
      canMoveOn: false,
      step: data.step + 1,
    });
  const prev = () =>
    setLocalData({
      ...data,
      response: undefined,
      canMoveOn: false,
      step: Math.max(data.step - 1, 0),
    });

  const reset = () => {
    localStorage.removeItem(storageKey);
    setData(getLocalData());
  };

  return {
    data,
    reset,
    next,
    prev,
    moveOn,
    polling,
    getLocalData,
    setLocalData,
    setPolling,
  };
};
