import React from "react";
import greenCheckAlt from "../../assets/images/greenCheckAlt.svg";

import { useNavigate } from "react-router-dom";
import { GoLiveSession } from "./goLiveSession";

import { Text, VStack, Image, Button } from "@chakra-ui/react";

type SummaryProps = {
  session: GoLiveSession;
};

const Summary = ({ session }: SummaryProps): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <VStack w="100%" mb="44px" spacing="2rem" align="center" textAlign="center">
      <Text fontSize="20px" fontWeight="bold">
        We have received your request to move from Sandbox into Production!
      </Text>
      <Image src={greenCheckAlt} width="400" />
      <Text fontSize="16px">
        We will be in touch via email after we process your application.
      </Text>
      <Button
        variant="outline"
        onClick={() => session.setLocalData({ ...session.data, step: 0 })}
      >
        Review Application
      </Button>
      <Button onClick={() => navigate("/launch-guide")}>
        Return to Launch Guide
      </Button>
    </VStack>
  );
};

export default Summary;
