import colors from "../theme/colors";

import Editor, { useMonaco } from "@monaco-editor/react";
import React, { useEffect, useRef, useState } from "react";
import { EventType, getAmplitude } from "../../globals/amplitude";

import {
  Box,
  Button,
  chakra,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Text,
  useClipboard,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import { formatJSON } from "../../globals/utils";
import { brokerAPIRequest } from "../../api/api";
import { Controller, useForm } from "react-hook-form";
import { ArrowForwardIcon, CopyIcon } from "@chakra-ui/icons";

interface PlaygroundProps {
  request?: string;
  response?: string;
  endpoint?: string;
  readOnly?: boolean;
  disabled?: boolean;
  instant?: boolean;
  method?: typeof Methods[number];
  onResponse?: CallableFunction;
  step?: string;
}

type PlaygroundFormProps = {
  method: typeof Methods[number];
  endpoint: string;
  request: string;
};

const Methods = ["GET", "POST", "PUT", "DELETE", "PATCH"] as const;

const light = {
  base: "vs",
  inherit: true,
  rules: [{ background: colors.nestedBGLight }],
  colors: { "editor.background": colors.nestedBGLight },
};

const dark = {
  base: "vs-dark",
  inherit: true,
  rules: [{ background: colors.nestedBGDark }],
  colors: { "editor.background": colors.nestedBGDark },
};

export const Playground = (props: PlaygroundProps): React.ReactElement => {
  const { colorMode } = useColorMode();

  const asJSON = (body?: string) =>
    JSON.stringify(JSON.parse(body ?? "{}"), null, 2);

  // we format the request and response here so we don't have to worry about it in the form(s)
  const [request, setRequest] = useState(asJSON(props.request));
  const [response, setResponse] = useState(asJSON(props.response ?? "{}"));
  const [editorTheme, setEditorTheme] = useState(
    colorMode === "light" ? "light" : "vs-dark"
  );

  const monaco = useMonaco();
  const editorRef = useRef(null);

  const cardBg = useColorModeValue(
    colors.cardBackgroundLight,
    colors.cardBackgroundDark
  );
  const cardNestedBg = useColorModeValue(
    colors.nestedBGLight,
    colors.nestedBGDark
  );
  const inputBg = useColorModeValue("#E6E9ED", "whiteAlpha.50");

  const { onCopy: onCopyRequest } = useClipboard(request);
  const { onCopy: onCopyResponse } = useClipboard(response);
  const { control, handleSubmit, formState } = useForm<PlaygroundFormProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    delayError: undefined,
    defaultValues: {
      method: props.method,
      endpoint: props.endpoint,
      request,
    },
  });

  const options = {
    readOnly: props.readOnly,
    lineNumbers: "on",
    verticalSliderSize: 5,
    verticalScrollbarSize: 10,
    renderIndentGuides: true,
    renderLineHighlight: "none",
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
    padding: {
      top: 25,
      bottom: 25,
    },
  };

  useEffect(() => {
    setEditorTheme(colorMode === "light" ? "custom-light" : "custom-dark");
  }, [colorMode]);

  useEffect(() => {
    if (!monaco) return;
    monaco.editor.defineTheme("custom-light", light);
    monaco.editor.defineTheme("custom-dark", dark);
  }, [monaco]);

  const onMount = (editor) => (editorRef.current = editor);

  const onFormat = () =>
    editorRef.current.getAction("editor.action.formatDocument")?.run();

  const onSubmit = async ({
    method,
    endpoint,
    request,
  }: PlaygroundFormProps) => {
    setResponse("Loading...");

    // inject instant if needed
    if (props.instant) {
      request = JSON.stringify({ ...JSON.parse(request), instant: true });
    }

    const response = await brokerAPIRequest(method, endpoint, request).catch(
      (error) => error.response.data
    );

    setResponse(formatJSON(response));

    if (props.onResponse) {
      props.onResponse(response);
    }
  };

  const handleSend = (step?: string) => {
    switch (step) {
      case "step-1":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_STEP1_CLICK_SEND,
        });
        break;
      case "step-2":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_STEP2_CLICK_SEND,
        });
        break;
      case "step-3":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_STEP3_CLICK_SEND,
        });
        break;
      case "step-4":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_STEP4_CLICK_SEND,
        });
        break;
      case "step-5":
        getAmplitude().track({
          event_type: EventType.QUICKSTART_STEP5_CLICK_SEND,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleSend(props.step);
    }
  }, [formState.isSubmitSuccessful]);

  return (
    <>
      <form onSubmit={({ preventDefault }) => preventDefault()}>
        <Flex mb="2rem">
          <Controller
            name="method"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                bg={inputBg}
                disabled={props.readOnly}
                fontWeight="500"
                variant="filled"
                w="130px"
              >
                {Methods.map((method, index) => (
                  <option key={index} value={method}>
                    {method}
                  </option>
                ))}
              </Select>
            )}
          />
          <Controller
            name="endpoint"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                bg={inputBg}
                mx="1rem"
                mr="1rem"
                fontWeight="500"
                variant="filled"
                disabled={props.readOnly}
                placeholder={props.endpoint}
              />
            )}
          />
          <Button
            disabled={props.disabled}
            onClick={handleSubmit(onSubmit)}
            px="1.25rem"
            py=".01rem"
          >
            Send <ArrowForwardIcon />
          </Button>
        </Flex>
        <SimpleGrid columns={2} spacing={10}>
          <Box bg={cardBg} borderRadius="lg" p="1rem">
            <Text fontSize="xl">Request</Text>
            <Text fontSize="md" opacity="0.6">
              All requests are JSON encoded.
            </Text>
            <Box bg={cardNestedBg} my="1rem" height="300px" borderRadius="lg">
              <Controller
                name="request"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Editor
                    language="json"
                    className="editor"
                    onMount={onMount}
                    options={options}
                    theme={editorTheme}
                    defaultValue={request || value}
                    onChange={(value) => {
                      setRequest(value ?? "{}");
                      onChange(value);
                    }}
                  />
                )}
              />
            </Box>
            <Button
              variant="ghost"
              mr=".5rem"
              onClick={(event) => {
                event.preventDefault();
                onCopyRequest();
              }}
            >
              Copy &nbsp;
              <CopyIcon />
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                onFormat();
              }}
            >
              Format
            </Button>
          </Box>
          <Box bg={cardBg} borderRadius="lg" p="1rem">
            <Text fontSize="xl">Response</Text>
            <Text fontSize="md" opacity="0.6">
              All responses are JSON encoded.
            </Text>
            <Box bg={cardNestedBg} my="1rem" height="300px" borderRadius="lg">
              {monaco && (
                <Editor
                  language="json"
                  className="editor"
                  value={response}
                  theme={editorTheme}
                  options={{ ...options, readOnly: true }}
                />
              )}
            </Box>
            <Button
              variant="ghost"
              onClick={(event) => {
                event.preventDefault();
                onCopyResponse();
              }}
            >
              Copy &nbsp;
              <CopyIcon />
            </Button>
          </Box>
        </SimpleGrid>
      </form>
    </>
  );
};

export default chakra(Playground);
