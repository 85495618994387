import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  useToast,
  BoxProps,
  Icon,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useIntercom } from "react-use-intercom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate, Navigate, NavLink } from "react-router-dom";
import { AlpacaDarkSVG, AlpacaSVG } from "../../icons/Login";
import colors from "../theme/colors";
import { isMobile } from "react-device-detect";
import useWindowSize from "../../globals/windowdim";
import { TradingAPISrc } from "../../globals/consts";
import { useContext } from "react";
import { AppContext } from "../../globals/appcontext";
import { confirmSMSLogin, login } from "../../auth/paciam";
import { EventType, getAmplitude, Tokens } from "../../globals/amplitude";

const HidePasswordButton = styled(Box)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const SignUpLink = styled(NavLink)`
  color: ${colors.yellow};
  margin-left: 10px;
  font-weight: 500;

  &:hover {
    border-bottom: solid 2px ${colors.yellow};
  }
`;

const AlpacaLeft = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <Box
      minWidth="max(25%, 16rem)"
      position="relative"
      bottom="-8rem"
      left="-15rem"
    >
      <Icon as={svg} fontSize="650px" position="absolute" bottom="0" left="0" />
    </Box>
  );
};

const AlpacaRight = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <Box width="25%" position="relative" bottom="-8rem" right="-12rem">
      <Icon
        as={svg}
        fontSize="350px"
        position="absolute"
        bottom="0"
        right="0"
        transform="scaleX(-1)"
      />
    </Box>
  );
};

const Login = (props: BoxProps): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [smsCode, setSMSCode] = useState("");
  const [confirmSMS, setConfirmSMS] = useState(false);

  const [sessId, setSessId] = useState("");
  const [userId, setUserId] = useState("");

  const winSize = useWindowSize();
  const appContext = useContext(AppContext);

  // Hide Intercom on login page
  const { hide } = useIntercom();
  hide();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    login(email, password)
      .then((resp) => {
        if (resp.challenge) {
          setSessId(resp.session);
          setUserId(resp.user_id);
          setIsLoading(false);
          setConfirmSMS(true);
          return;
        }

        toast({
          title: "Login Successful",
          status: "success",
        });
        appContext.setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((e) => {
        if (e.code === "PasswordResetRequiredException") {
          navigate("/set-password", { state: { email } });
          return;
        }

        toast({
          title: "Invalid username or password",
          description: e.response.data.message,
          status: "error",
        });
        setIsLoading(false);
      });
  };

  const handleMFASubmit = async (event: React.FormEvent) => {
    if (!sessId) return;
    event.preventDefault();
    setIsLoading(true);

    confirmSMSLogin(smsCode, sessId, userId)
      .then(() => {
        toast({
          title: "Login Successful",
          status: "success",
        });
        appContext.setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: "Login Error",
          description: e.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const fromSignOut = params.get("signedOut") === "true";
  const headerFontSize = isMobile ? "3xl" : "4xl";
  const largeScreen = ["medium", "large"].includes(winSize);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Flex {...props} justify="center">
      {largeScreen && <AlpacaLeft />}
      <Box w={!largeScreen ? "100%" : "50%"} position="relative">
        <Center fontSize={headerFontSize} mb="2rem" textAlign="center">
          {fromSignOut ? "Sign Back In" : "Log in to your Broker API account"}
        </Center>
        {!confirmSMS && (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl mt={6}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  size="lg"
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
                <InputRightElement width="3rem">
                  <HidePasswordButton onClick={handlePasswordVisibility}>
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </HidePasswordButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box mt="3rem" float="right" fontSize="lg">
              <NavLink to="/forgot-password">
                <Text color="grey" _hover={{ color: "black" }}>
                  Forgot password?
                </Text>
              </NavLink>
            </Box>
            <Button
              size="lg"
              width="full"
              mt="1rem"
              isLoading={isLoading}
              onClick={(event) => {
                handleSubmit(event);
                getAmplitude(Tokens.BROKERDASH, Tokens.ALGODASH).map(
                  (instance) =>
                    instance.track({
                      event_type: EventType.LOGIN_CLICKED,
                      event_properties: {
                        url: "https://broker-app.alpaca.markets/login",
                      },
                    })
                );
              }}
            >
              Log in
            </Button>
            <Center mt="3rem" fontSize="lg">
              <Text color="grey" mr="5px" textAlign="center">
                Don&apos;t have a Broker API account, yet?
                <SignUpLink to="/sign-up">Sign up</SignUpLink>
              </Text>
            </Center>
            {largeScreen && (
              <Center textAlign="center" mt="4rem" mb="-6rem">
                <Box color="grey" fontSize="md" display="inline">
                  For algorithmic trading, quants funds, and trading your own
                  funds with Trading API, visit
                  <a
                    href={TradingAPISrc}
                    onClick={() =>
                      getAmplitude(Tokens.BROKERDASH, Tokens.ALGODASH).map(
                        (instance) =>
                          instance.track({
                            event_type: EventType.ALGODASH_LOGIN_CLICKED,
                            event_properties: {
                              url: "https://broker-app.alpaca.markets/login",
                            },
                          })
                      )
                    }
                  >
                    <Text
                      fontWeight={500}
                      display="inline"
                      fontSize="md"
                      color="brand.500"
                      ml="5px"
                    >
                      Trading API.
                    </Text>
                  </a>
                </Box>
              </Center>
            )}
          </form>
        )}

        {confirmSMS && (
          <form onSubmit={handleMFASubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Code</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type="text"
                  size="lg"
                  value={smsCode}
                  onChange={(event) => setSMSCode(event.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <Button
              size="lg"
              type="submit"
              width="full"
              mt="1rem"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>

      {largeScreen && <AlpacaRight />}
    </Flex>
  );
};

export default Login;
