import React, { useState } from "react";
import {
  Button,
  useColorModeValue,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

interface CopyButtonProps {
  isTruncated?: boolean;
  name?: string;
  value: string;
  iconSide: "right" | "left";
  lightColor?: string;
  darkColor?: string;
  showTooltip?: boolean;
}

const CopyButton = (props: CopyButtonProps): React.ReactElement => {
  const { onCopy } = useClipboard(props.value);
  const [isHovered, setIsHovered] = useState(false);
  const toast = useToast();

  const copyValue = () => {
    onCopy();
    toast({ description: `${props.name || "value"} copied`, duration: 1500 });
  };

  let copyIcon = {};
  if (isHovered) {
    copyIcon =
      props.iconSide === "right"
        ? { rightIcon: <CopyIcon /> }
        : { leftIcon: <CopyIcon /> };
  }

  const copyButton = (
    <Button
      isTruncated
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variant="ghost"
      colorScheme={useColorModeValue(
        props.lightColor ?? "blue",
        props.darkColor ?? "brand"
      )}
      onClick={copyValue}
      {...copyIcon}
    >
      <Text isTruncated={props.isTruncated}>{props.value}</Text>
    </Button>
  );

  if (props.showTooltip) {
    return (
      <Tooltip label={props.value} placement="top" bg="gray.700" color="white">
        {copyButton}
      </Tooltip>
    );
  }

  return copyButton;
};

CopyButton.defaultProps = {
  iconSide: "right",
};

export default CopyButton;
