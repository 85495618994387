import BrokerForm from "./brokerForm";
import React from "react";

import { useQuery } from "react-query";
import { getCorrespondent, getSelf } from "../../../api/api";
import { Role } from "../../../api/types";

const Overview = (): React.ReactElement => {
  const {
    data: correspondent,
    refetch: refetchCorrespondent,
  } = useQuery("correspondent", () => getCorrespondent());
  const { data: authUser } = useQuery("self", () => getSelf());

  const isSuperUser = authUser?.role === Role.Superuser;

  return (
    <BrokerForm
      correspondent={correspondent}
      refetchCorrespondent={refetchCorrespondent}
      isSuperUser={isSuperUser}
    />
  );
};

export default Overview;
