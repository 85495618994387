import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EventType, getAmplitude } from "../../globals/amplitude";

export const useLogPageView = (): void => {
  const location = useLocation();

  useEffect(() => {
    getAmplitude().track({
      event_type: EventType.PAGE_VISITED,
      event_properties: {
        ...location,
        // unset following properties
        state: undefined,
        key: undefined,
      },
    });
  }, [location]);
};
