import React, { useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, Select, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment";
import DateRangePicker from "../general/DatePicker";
import FilterHeader from "./FormHeader";

interface FilterDateSelectProps {
  header: string;
  onDateSelect: (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => void;
  disabled?: boolean;
  allowNull?: boolean;
  defaultSelect?: string;
  filterDate?: (date: Date) => boolean;
  hiddenOptions?: typeof DATE_RANGE_TIMEFRAMES[number][];
}

interface DropdownOption {
  label: string;
  key: typeof DATE_RANGE_TIMEFRAMES[number];
}

const DATE_RANGE_TIMEFRAMES = [
  "24-hours",
  "7-days",
  "14-days",
  "30-days",
  "60-days",
] as const;

const DATE_RANGE_OPTIONS: DropdownOption[] = [
  {
    label: "Last 24 Hours",
    key: "24-hours",
  },
  {
    label: "Last 7 Days",
    key: "7-days",
  },
  {
    label: "Last 14 Days",
    key: "14-days",
  },
  {
    label: "Last 30 Days",
    key: "30-days",
  },
  {
    label: "Last 60 Days",
    key: "60-days",
  },
];

const FilterDateSelect = ({
  header,
  onDateSelect,
  disabled,
  allowNull = true,
  defaultSelect = "Anytime",
  filterDate,
  hiddenOptions = [],
}: FilterDateSelectProps): React.ReactElement => {
  const [showCalInput, setShowCalInput] = useState(false);
  const [selected, setSelected] = useState(defaultSelect);

  const windowSelect = (window: string) => {
    const units = window.split("-");
    onDateSelect(
      moment().subtract(units[0], units[1] as "days" | "hours"),
      moment()
    );
    setSelected(units.join(" "));
    setShowCalInput(false);
  };

  const rangeSelect = (st: Date, ed: Date) => {
    onDateSelect(moment(st), moment(ed));
  };

  return (
    <>
      <FilterHeader value={header} />
      <Menu>
        <MenuButton
          as={Select}
          variant="filled"
          rightIcon={<ChevronDownIcon />}
          width="100%"
          placeholder={selected}
          disabled={!!disabled}
        />
        <MenuList zIndex={2}>
          {allowNull && (
            <MenuItem
              onClick={() => {
                onDateSelect(null, null);
                setSelected("Anytime");
                setShowCalInput(false);
              }}
            >
              Anytime
            </MenuItem>
          )}
          {DATE_RANGE_OPTIONS.filter(
            (option) => !hiddenOptions.includes(option.key)
          ).map((option) => (
            <MenuItem key={option.key} onClick={() => windowSelect(option.key)}>
              {option.label}
            </MenuItem>
          ))}
          <MenuItem
            onClick={() => {
              onDateSelect(null, null);
              setSelected("Range");
              setShowCalInput(true);
            }}
          >
            Range
          </MenuItem>
        </MenuList>
      </Menu>

      {showCalInput && (
        <DateRangePicker onChange={rangeSelect} filterDate={filterDate} />
      )}
    </>
  );
};

export default FilterDateSelect;
