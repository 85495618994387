import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type CloseAccountModalProps = {
  accountID: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const CloseAccountModal: React.FC<CloseAccountModalProps> = ({
  accountID,
  isOpen,
  isLoading,
  onClose,
  onConfirm,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent fontFamily="Carnas">
      <ModalHeader fontSize="30px">Close Account {accountID}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        This operation closes an active account. The underlying records and
        information of the account are not deleted by this operation. Are you
        sure you want to close this account?
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onClose}>
          Back
        </Button>
        <Button onClick={onConfirm} isLoading={isLoading}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
