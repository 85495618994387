import joi from "joi";

import React, { ChangeEvent, useEffect, useRef } from "react";

import {
  Button,
  Image,
  Input,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useToast,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import {
  CorrespondentResponse,
  PatchCorrespondentRequest,
} from "../../../api/types";

import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { joiResolver } from "@hookform/resolvers/joi";

import { patchCorrespondent } from "../../../api/api";
import { EventType, getAmplitude } from "../../../globals/amplitude";
import { getBase64, phoneNumberRegex } from "../../go-live/constants";

const FORM_WIDTH = "350px";

const LOGO_WIDTH = 400;
const LOGO_HEIGHT = 400;
const BANNER_WIDTH = 920;
const BANNER_HEIGHT = 100;

interface BrokerFormProps {
  correspondent: CorrespondentResponse | undefined;
  refetchCorrespondent: () => void;
  isSuperUser: boolean;
}

interface FormData {
  name: string;
  business_name: string;
  business_contact_number: string;
  business_email: string;
  business_address: string;
  operation_contact: string;
  finance_contact_email: string;
  logo: string;
}

const schema = joi.object({
  name: joi.string().required().empty(""),
  logo: joi.string().allow(""),
  business_name: joi.string().required().empty(""),
  business_contact_number: joi
    .string()
    .regex(phoneNumberRegex)
    .empty("")
    .messages({
      "string.pattern.base": 'Invalid "business_contact_number" format',
    }),
  business_email: joi
    .string()
    .required()
    .email({ tlds: { allow: false } })
    .empty(""),
  business_address: joi.string().required().empty(""),
  operation_contact: joi
    .string()
    .required()
    .email({ tlds: { allow: false } })
    .empty(""),
  finance_contact_email: joi
    .string()
    .required()
    .email({ tlds: { allow: false } })
    .empty(""),
});

const BrokerForm = ({
  correspondent,
  refetchCorrespondent,
  isSuperUser,
}: BrokerFormProps): React.ReactElement => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
    resolver: joiResolver(schema),
  });

  const toast = useToast();
  const logo = watch("logo");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const bgColor = useColorModeValue("light.400", "dark.400");

  const mutation = useMutation<void, AxiosError, PatchCorrespondentRequest>(
    (payload) => patchCorrespondent(payload),
    {
      onSuccess: () => {
        refetchCorrespondent();
        toast({
          title: "Team setting updated",
          status: "success",
        });
      },
      onError: () => {
        toast({
          title: "Unable to update team settings",
          status: "error",
        });
      },
    }
  );

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (inputRef.current && selectedFiles.length > 0) {
      try {
        inputRef.current.value = "";
        const fileType = selectedFiles[0].type;
        const base64 = await getBase64(selectedFiles[0]);
        await validateImage(fileType, base64);
        setValue("logo", base64);
      } catch (err) {
        toast({
          title: "Failed to upload image",
          description: (err as Error).message,
          status: "error",
        });
      }
    }
  };

  const isLogo = (width: number, height: number) => {
    return width === LOGO_WIDTH && height === LOGO_HEIGHT;
  };

  const isBanner = (width: number, height: number) => {
    return width === BANNER_WIDTH && height === BANNER_HEIGHT;
  };

  const validateImage = async (
    fileType: string,
    src: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (fileType !== "image/jpeg") {
        reject(new Error("Must be in JPEG file format"));
      }

      const img = document.createElement("img");
      img.src = src;
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (!isLogo(width, height) && !isBanner(width, height)) {
          reject(new Error("Must have image dimensions 920x100 or 400x400"));
        }
        resolve();
      };
    });
  };

  const onSelectImageClick = () => {
    inputRef.current?.click();
  };

  const onRemoveImageClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setValue("logo", "");
    }
  };

  const onSubmit = (formData: FormData) => {
    mutation.mutate({
      name: formData.name,
      bd_data: {
        ...correspondent?.bd_data,
        business_name: formData.business_name,
        business_contact_number: formData.business_contact_number,
        business_email: formData.business_email,
        business_address: formData.business_address,
        operation_contact: formData.operation_contact,
        finance_contact_email: formData.finance_contact_email,
        logo: formData.logo,
      },
    });

    getAmplitude().track({
      event_type: EventType.EDIT_TEAM_NAME,
      event_properties: {
        url: "https://broker-app.alpaca.markets/team-settings",
      },
    });
  };

  useEffect(() => {
    reset({
      name: correspondent?.name,
      business_name: correspondent?.bd_data?.business_name,
      business_contact_number: correspondent?.bd_data?.business_contact_number,
      business_email: correspondent?.bd_data?.business_email,
      business_address: correspondent?.bd_data?.business_address,
      operation_contact: correspondent?.bd_data?.operation_contact,
      finance_contact_email: correspondent?.bd_data?.finance_contact_email,
      logo: correspondent?.bd_data?.logo || "",
    });
  }, [correspondent]);

  const shareProps = {
    disabled: !isSuperUser,
    variant: "filled",
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex width={FORM_WIDTH} flexDirection="column" gap={8}>
        <FormControl isRequired>
          <FormLabel>
            Logo (920x100 or 400x400){" "}
            <Popover trigger="hover">
              <PopoverTrigger>
                <InfoOutlineIcon />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Image Requirements</PopoverHeader>
                <PopoverBody>
                  All logos must adhere to the following image requirements:
                  Must be in JPEG file format and have a resolution of 300 dots
                  per inch (dpi). Its dimensions can be either 920 x 100 pixels
                  or 400 x 400 pixels.
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormLabel>
          <Menu flip={false}>
            <MenuButton
              as={Flex}
              alignItems="center"
              autoFocus={true}
              backgroundColor={bgColor}
              borderRadius="full"
              boxSize="8rem"
              cursor="pointer"
              overflow="hidden"
              userSelect="none"
            >
              {logo ? (
                <Image objectFit="cover" boxSize="8rem" src={logo} />
              ) : (
                <Text textAlign="center">No image</Text>
              )}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onSelectImageClick}>Upload Image</MenuItem>
              {logo && (
                <MenuItem onClick={onRemoveImageClick} color="red.500">
                  Remove
                </MenuItem>
              )}
            </MenuList>
          </Menu>
          <input
            hidden
            type="file"
            accept="image/jpeg"
            ref={inputRef}
            onChange={onFileChange}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.name} isRequired>
          <FormLabel>Team Name</FormLabel>
          <Input
            placeholder="eg: Beeple Ltd."
            {...register("name")}
            {...shareProps}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.business_name} isRequired>
          <FormLabel>Entity Legal Name</FormLabel>
          <Input
            placeholder="eg: Beeple Ltd."
            {...register("business_name")}
            {...shareProps}
          />
          <FormErrorMessage>{errors.business_name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.business_email} isRequired>
          <FormLabel>Business Email</FormLabel>
          <Input
            type="business_email"
            placeholder="example@gmail.com"
            {...register("business_email")}
            {...shareProps}
          />
          <FormErrorMessage>{errors.business_email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.business_contact_number}>
          <FormLabel>Business Phone Number</FormLabel>
          <Input
            placeholder="+1 123 4567"
            {...register("business_contact_number")}
            {...shareProps}
          />
          <FormErrorMessage>
            {errors.business_contact_number?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.business_address} isRequired>
          <FormLabel>Business Address</FormLabel>
          <Input
            placeholder="eg: 123 Main St. Unit #101, San Jose, California, United States, 94401"
            {...register("business_address")}
            {...shareProps}
          />
          <FormErrorMessage>
            {errors.business_address?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.operation_contact} isRequired>
          <FormLabel>Operation Contact Email</FormLabel>
          <Input
            placeholder="operation@gmail.com"
            {...register("operation_contact")}
            {...shareProps}
          />
          <FormErrorMessage>
            {errors.operation_contact?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.finance_contact_email} isRequired>
          <FormLabel>Finance Contact Email</FormLabel>
          <Input
            placeholder="finance@gmail.com"
            {...register("finance_contact_email")}
            {...shareProps}
          />
          <FormErrorMessage>
            {errors.finance_contact_email?.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          mr="auto"
          type="submit"
          isLoading={mutation.isLoading}
          disabled={!isSuperUser}
        >
          Update
        </Button>
      </Flex>
    </form>
  );
};

export default BrokerForm;
